import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LearningService } from 'src/app/services/learning.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import {NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-testing-skills',
  templateUrl: './testing-skills.component.html',
  styleUrls: ['./testing-skills.component.css']
})
export class TestingSkillsComponent implements OnInit {

  learningList:any = [];
  slug:any;
  category_name:any ='Testing Skills';
  cate_slug:any;
  isFreeTrial:boolean = false;
  nextBillingDate=new Date();
  CurrentPlan:any;
  planData:any;
  CurrentPlanID=0;
  closeResult: string;
  modalReference: NgbModalRef;

  constructor(
    private learnService: LearningService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private authService: AuthService, 
    private toastr: ToastrService, 
    private modalService: NgbModal
    ) {
      this.slug = router.url.split('/').pop();
      this.authService.getUserActivePlan().subscribe((res:any)=>{
        if(res.status) {
          if(res.current_active_plan.plan_id == 1 || res.current_active_plan.plan_id == 2){
              this.isFreeTrial = true
          }
        } else {
          this.isFreeTrial = false
        }
        this.getCurrentPlan()
        this.getAllPlanList()
      })
     }

  ngOnInit(): void {
    // this.slug = this.route.snapshot.params['slug'];
    this.cate_slug = this.slug;
    this.learnService.getAllByCategorySlug(this.slug).subscribe((res: any)=>{
      if(res.status){
        this.learningList = res.data;
        if(this.learningList.length>0){
          this.category_name = this.learningList[0].category_name;
        }
      }else{
        this.learningList = []
        this.toastr.error(res.message, "Error");
      }
    },
     err=> {
      this.learningList = []
      this.toastr.error(err, "Error");
    });
    // this.route.params.forEach(params => {
    //       let slug = params["slug"];
    //       this.cate_slug = slug;
    //       this.learnService.getAllByCategorySlug(slug).subscribe((res: any)=>{
    //         if(res.status){
    //           this.learningList = res.data;
    //           if(this.learningList.length>0){
    //             this.category_name = this.learningList[0].category_name;
    //           }
    //         }else{
    //           this.learningList = []
    //           this.toastr.error(res.message, "Error");
    //         }
    //       },
    //        err=> {
    //         this.learningList = []
    //         this.toastr.error(err, "Error");
    //       });
    // })
    
  }

  getCurrentPlan(){
    this.authService.getUserActivePlan().subscribe((res:any)=>{
      if(res.status) {
        console.log(res,'current plan ')
        this.CurrentPlanID=res.current_active_plan.plan_id
          this.CurrentPlan = res.current_active_plan
          this.nextBillingDate = new Date();
            if(this.CurrentPlan.plan.duration_type == 'Days'){
              this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration); 
            }else if(this.CurrentPlan.plan.duration_type == 'Years'){
              this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration); 
            }
          console.log(this.CurrentPlan)
          // this.router.navigateByUrl('dashboard');
      } else {
        this.router.navigateByUrl('OurPlan');
        
        //alert(res.message);
      }

    }, err=> {
      this.router.navigateByUrl('OurPlan');
    })
  }

  getAllPlanList(){
    this.authService.getAllPlanList({}).subscribe((res:any)=>{
      if(res.status) {
        this.planData = res.plans;
        console.log(this.planData)
      }
    })
  }

  OpenPlanPopup(modalId){
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }

}
