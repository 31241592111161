import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import{ GlobalConstants } from '../../common/global-constants';
/* import { Editor, Toolbar } from "ngx-editor";
import jsonDoc from "../../doc"; */
import { AngularEditorConfig } from '@kolkov/angular-editor';
declare var $: any;
import {Location} from '@angular/common';

import {NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/services/message.service';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, OnDestroy{

  editForm: FormGroup;
  countryForm: FormGroup;
  headlineForm: FormGroup;
  summaryForm: FormGroup;
  
  public loading: boolean;
  id: number;
  user: any;
  imageURL:string;
  sameUser:boolean = false;
  industryList: any = [];
  countryList: any = [];
  stateList:any = [];
  SelectedIndustry_id:string;
  Selectedcountry_id:string;
  Selectedstate_id:string;
  summary_title:string;
  industry_name:string;
  country_name:string;
  state_name:string;

  //free trial
  isFreeTrial:boolean = false;
  nextBillingDate=new Date();
  CurrentPlan:any;
  planData:any;
  CurrentPlanID=0;

  monthList:any = [
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10','11','12'
  ];
  yearList:any = [
    '2000', '2001', '2002', '2003','2004', '2005', '2006', '2007', '2008', '2009', '2010','2011','2012','2013','2014','2015','2016','2017','2018','2019','2020','2021'
  ];

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '7rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...', 
      customClasses: [
        {
          name: 'bgcolor',
          class: 'bgcolor',
        },
      ]
  };
  
  
  items: number[] = [1];

  isIndustryShow: boolean = false;
  isCountryShow:boolean = false;
  isHeadlineShow:boolean = false;

  public isSummaryShow = false;
  summary_description='';
  experiences = [];
  educations = [];
  certificates = [];
  achievements = [];
  loginRole:any = null;
  editUserRole:boolean = false;
  profile_percentage = 0;

  roleList:any = [{ 'id': 2, 'name': 'Coach'}, { 'id': 3, 'name': 'User'}];

  modalReference: NgbModalRef;
  closeResult: string;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location, private modalService: NgbModal, private messageService: MessageService) { 
      this.authService.getUserActivePlan().subscribe((res:any)=>{
        if(res.status) {
          if(res.current_active_plan.plan_id == 1){
              this.isFreeTrial = true
          }
          console.log(res,'response --------');
        } else {
          
        }
      })
      this.getCurrentPlan()
      this.getAllPlanList()
    }

  ngOnInit(): void {
    if(!this.authService.isLogedin) {
      this.router.navigateByUrl('login');
    }

    $(".cls-btn").on("click", function () {
      $(this).closest('.card-header').find('.btn').trigger('click');
    })

    $(".crd_rsletiv").on("click", "button", function () {
      if($(this).hasClass('collapsed')){
        $(this).closest('.card-header').find('.pls-btn').addClass('d-none');
        $(this).closest('.card-header').find('.cls-btn').removeClass('d-none');
      }else{
        $(this).closest('.card-header').find('.pls-btn').removeClass('d-none');
        $(this).closest('.card-header').find('.cls-btn').addClass('d-none');
      }
    })

    $(".pls-btn").on("click", function () {
      $(this).closest('.card-header').find('.btn').trigger('click');
    })

    this.id = this.route.snapshot.params['id'];
    if(this.id==this.authService.user.id){
      this.sameUser=true;
    }else{
      this.sameUser=false;
    }

    this.loginRole = this.authService.user.user_role;

    this.authService.getUserById(this.id).subscribe((data: any)=>{
      if(data.status){
        this.user = data.user;
        if(this.user){
          this.SelectedIndustry_id = this.user.industry_id;
          this.summary_title = this.user.summary_title;
          this.profile_percentage = this.user.profile_percentage;
          if(this.user.industry_id && this.user.industry){
            this.industry_name = this.user.industry.title;
          }
          if(this.user.country_id && this.user.state_id && this.user.country && this.user.state){
            this.country_name = this.user.country.name;
            this.state_name = this.user.state.name;
          }

          if(this.user.experiences && this.user.experiences.length>0){
            this.experiences = this.user.experiences;
          }
          if(this.user.educations && this.user.educations.length>0){
            this.educations = this.user.educations;
          }
          if(this.user.certificates && this.user.certificates.length>0){
            this.certificates = this.user.certificates;
          }
          if(this.user.achievements && this.user.achievements.length>0){
            this.achievements = this.user.achievements;
          }
        }
        if(this.user.profile_pic){
          let path =  GlobalConstants.user_profile_path;
          this.imageURL = path+this.user.profile_pic;
        }
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });
    
    if(this.sameUser){
      this.authService.getAllIndustry().subscribe((data: any)=>{
        if(data.status){
          
          this.industryList = data.industries;
          if(this.user){
            if(this.user.industry_id){
              //this.industry_name = this.industryList[this.user.industry_id].title;
              //console.log(this.industry_name);
              //this.industry_name = this.user.industry_id;
            }
          }
        }else{
          this.toastr.error(data.message, "Error");
        }
      });
    }

    this.editForm = this.formBuilder.group({
        industry_id: ['', Validators.required],
    });
    //this.editor = new Editor();
  }

  
  getCurrentPlan(){
    this.authService.getUserActivePlan().subscribe((res:any)=>{
      if(res.status) {
        console.log(res,'current plan ')
        this.CurrentPlanID=res.current_active_plan.plan_id
          this.CurrentPlan = res.current_active_plan
          this.nextBillingDate = new Date();
            if(this.CurrentPlan.plan.duration_type == 'Days'){
              this.nextBillingDate.setDate(this.nextBillingDate.getDate() + this.CurrentPlan.plan.duration); 
            }else if(this.CurrentPlan.plan.duration_type == 'Years'){
              this.nextBillingDate.setFullYear(this.nextBillingDate.getFullYear() + this.CurrentPlan.plan.duration); 
            }
          console.log(this.CurrentPlan)
          // this.router.navigateByUrl('dashboard');
      } else {
        this.router.navigateByUrl('OurPlan');
        
        //alert(res.message);
      }

    }, err=> {
      this.router.navigateByUrl('OurPlan');
    })
  }

  getAllPlanList(){
    this.authService.getAllPlanList({}).subscribe((res:any)=>{
      if(res.status) {
        this.planData = res.plans;
        console.log(this.planData)
      }
    })
  }

  
  OpenPlanPopup(modalId){
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ngOnDestroy(): void {
    //this.editor.destroy();
  }

  get control() {
    return this.editForm.controls;
  }

  get ccontrol() {
    return this.countryForm.controls;
  }

  get hcontrol() {
    return this.headlineForm.controls;
  }

  get scontrol() {
    return this.summaryForm.controls;
  }

  get profilePercentage(){
    return `${this.profile_percentage}%`;
  }

  
  submit() {
    if(this.editForm.invalid) {
      return;
    }
    
    this.loading = true;
    this.authService.addOrUpdateIndustry(this.editForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.SelectedIndustry_id = res.user.industry_id;
        if(res.user){
          this.user = res.user;
        }
        if(res.user.industry){
          this.industry_name = res.user.industry.title;
        }
        this.toastr.success('Industry updated successfully', "Success");
        this.isIndustryShow = false;
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err.error.message, "Error");
    })
  }

  clickEvent(){
      this.isIndustryShow = !this.isIndustryShow;       
  }

  cancelEvent(){
      this.isIndustryShow = false;
  }

  showCountry(){
      this.isIndustryShow = false;
      this.isCountryShow = !this.isCountryShow;
      if(this.isCountryShow){
        this.Selectedcountry_id = this.user.country_id;
        
        this.countryForm = this.formBuilder.group({
            country_id: ['', Validators.required],
            state_id:['',Validators.required]
        });
        this.authService.getAllCountry().subscribe((data: any)=>{
          if(data.status){
            this.countryList = data.countries;
          }else{
            this.toastr.error(data.message, "Error");
          }
        });
        
        if(this.user.country_id!=null){
          this.authService.getStates(this.user.country_id).subscribe(
            (data: any) => {
              if(data.status){
                this.stateList = data.states;
                this.Selectedstate_id = this.user.state_id;
              }else{
                //this.stateList = null;
                this.toastr.error(data.message, "Error");
              }
            }
          );
        }
      }     
  }

  onChangeCountry(countryId: number) {
    if (countryId) {
      this.authService.getStates(countryId).subscribe(
        (data: any) => {
          if(data.status){
            this.stateList = data.states;
          }else{
            this.stateList = null;
            this.toastr.error(data.message, "Error");
          }
        }
      );
    } else {
      this.stateList = null;
    }
  }

  saveCountry() {
    if(this.countryForm.invalid) {
      return;
    }
    
    this.loading = true;
    this.authService.saveCountry(this.countryForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.Selectedcountry_id = res.user.country_id;
        this.Selectedstate_id = res.user.state_id;
        this.toastr.success('Country updated successfully', "Success");
        this.isCountryShow = false;
        if(res.user){
          this.user = res.user;
        }
        if(res.user.country){
          this.country_name = res.user.country.name;
        }
        if(res.user.state){
          this.state_name = res.user.state.name;
        }
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err.error.message, "Error");
    })
  }

  cancelCountry(){
      this.isCountryShow = false;
  }

  showHeadline(){
    this.isHeadlineShow = !this.isHeadlineShow;
    if(this.isHeadlineShow){
      this.headlineForm = this.formBuilder.group({
          summary_title: ['', Validators.required],
      });
      this.summary_title = this.user.summary_title;
    }
  }

  cancelHeadline(){
    this.isHeadlineShow = false;
  }

  saveHeadline() {
    if(this.headlineForm.invalid) {
      return;
    }
    
    this.loading = true;
    this.authService.saveHeadline(this.headlineForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.summary_title = res.user.summary_title;
        this.toastr.success('Summary updated successfully', "Success");
        this.isHeadlineShow = false;
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err.error.message, "Error");
    })
  }
  
  showSummary(){
    this.isSummaryShow = !this.isSummaryShow;
    if(this.isSummaryShow){
      this.summaryForm = this.formBuilder.group({
          summary_description: [''],
      });
      this.summary_description = this.user.summary_description;
    }
  }

  copy() {
     this.items.push(this.items.length + 1)
  }

  
  editUserForm: FormGroup;
  
  editUser(){
    this.editUserRole = true;
    this.editUserForm = this.formBuilder.group({
        id: ['', Validators.required],
        user_role: ['', Validators.required],
    });
    this.editUserForm.patchValue(this.user)
  }

  get eUserControl() {
    return this.editUserForm.controls;
  }

  cancelEditUser(){
    this.editUserRole = false;
  }
  
  updateUserRole() {
    if(this.editUserForm.invalid) {
      return;
    }
    
    this.loading = true;
    this.authService.updateUserRole(this.editUserForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('User role updated successfully', "Success");
        this.editUserRole = false;
        if(res.user){
          this.user = res.user;
        }
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err.error.message, "Error");
    })
  }

  backClicked() {
    this._location.back();
  }

  getMonth(month){
     //1 = January
      var monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December' ];
      return monthNames[month - 1];
  }

  get FormControl() {
    return this.sendMessage.controls;
  }

  sendMessage: FormGroup;
  isSend = false;
  OpenMessageModal(reciver_user_id, modalId){
    this.isSend = true;
    this.sendMessage = this.formBuilder.group({
      message_description: ['', [Validators.required]],
      reciver_user_id: ['', ],
    })
    let messageData = [];
    messageData['reciver_user_id'] = reciver_user_id;
    this.sendMessage.patchValue(messageData);
    
    this.modalReference = this.modalService.open(modalId, { size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  SendMessageStore(){
    if(this.sendMessage.invalid) {
      return;
    }
    //this.CControl.post_id.setValue(postId);
    
    this.messageService.AddMessage(this.sendMessage.value).subscribe((res:any)=>{
      if(res.status) {
        this.sendMessage.reset();
        this.toastr.success('Send message successfully', "Success");
        this.modalReference.close();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }


}
