import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cv-linkdin',
  templateUrl: './cv-linkdin.component.html',
  styleUrls: ['./cv-linkdin.component.css']
})
export class CvLinkdinComponent implements OnInit {

  constructor( private router: Router,private route: ActivatedRoute,) { }

  ngOnInit(): void {
  }

}
