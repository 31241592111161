<app-header></app-header>
<app-sidebar></app-sidebar>
<!--12 may work-->
<!--12 may work-->
<div id="main-content">
   <div class="container-fluid">
      <div class="block-header top-head-vt">
         <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12">
               <ul class="breadcrumb vt-a">
                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                  <li class="breadcrumb-item">Job Search</li>
               </ul>
            </div>
         </div>
      </div>
      <div class="row justify-content-center">
         <div class="col-md-8">
            <div class="modal-body  save-box">
               <form [formGroup]="SearchJobForm">
                  <div class="row">
                     <div class="col-md-6 form-group">
                        <label>What</label>
                        <p>Job title, keywords, or company</p>

                        <input class="form-control" type="text" formControlName="what" placeholder="Type Job title, keywords, or company here"/>
                        <i class="icon-magnifier"></i>
                     </div>
                     <div class="col-md-6 form-group">
                      <label>Where</label>
                      <p>city or state</p>
                        <input class="form-control" type="text" formControlName="where" placeholder="Type city or state here"/>
                        <i class="fa fa-map-marker"></i>
                     </div>
                     <div class="FindsJobs">
                      <button type="button" class="btn Findbtn" (click)="getAllJobs()">FIND JOBS</button>
                   </div>
                  </div>
               </form>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="JobList-card" *ngIf="jobs.length > 0">
               <ul class="clearfix simple-listing-2">
                  <li class="list-2" id="allgroup-list16" *ngFor="let job of jobs">
                     <div class="list-pic">
                        <img src="./assets/assets/images/group.png" alt="job image">
                     </div>
                     <div class="listing-detail">
                        <h5 class="u-name"> {{job.job_name}} </h5>
                        <p class="u-dest"> {{job.job_location}} </p>
                        <div class="Days">
                           <!-- <span> 3 Days ago</span> -->
                           <span>{{ job.published_date | date:'longDate'}}</span>
                           <i aria-hidden="true" class="fa fa-circle"></i>
                           <span>{{job.applydata_count}} Applied</span>
                        </div>
                     </div>
                     <div class="Right-Favourites" style="border:0">
                        <a *ngIf="job.favdata != null" class="join-other-group btn btn-Favourites active" href="javascript:void(0)" (click)="favouriteAddRemove(job.id)">Add this Favourites
                        <i class="icon-heart"></i>
                        </a>
                        <a *ngIf="job.favdata == null" class="join-other-group btn btn-Favourites" href="javascript:void(0)" (click)="favouriteAddRemove(job.id)">Add this Favourites
                           <i class="icon-heart"></i>
                           </a>
                        <p><a class="join-other-group" [routerLink]="['/grow/job-details/', job.id]">VIEW DETAILS</a></p>
                     </div>
                  </li>
               </ul>
            </div>
            <div class="JobList-card" *ngIf="jobs.length == 0">
               <p class="list-2 text-center">No jobs were found</p>
            </div>
         </div>
         <!--./col-lg-6 col-md-12 col-sm-12-->
      </div>
   </div>
</div>
