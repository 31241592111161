import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-varify',
  templateUrl: './email-varify.component.html',
  styleUrls: ['./email-varify.component.css']
})
export class EmailVarifyComponent implements OnInit {

  editForm: FormGroup;
  public loading: boolean;
  id: number;
  token: any;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.token = this.route.snapshot.params['token'];

    if(this.id && this.token){
        this.authService.checkEmailVarification(this.id, this.token).subscribe((data: any)=>{
          if(data.status){
            this.toastr.success(data.message, "Success");
            this.router.navigateByUrl('login');
          }else{
            this.toastr.error(data.message, "Error");
            this.router.navigateByUrl('login');
          }
        }, err => {
        this.toastr.error("Oops! Something went wrong", "Error");
        this.router.navigateByUrl('login');
      });
    }else{
      this.toastr.error("Oops! Something went wrong", "Error");
      this.router.navigateByUrl('login');
    }
  }

}
