<div class="vertical-align-wrap">
    <div class="vertical-align-middle auth-main">
        <div class="auth-box container">
            <div class="row row align-items-center">
                <div class="col-md-6">
                    <div class="top">
                        <!-- <img src="./assets/assets/images/vttouch.png" alt="Lucid"> -->
                    </div>
                    <div class="card">
                        <div class="top-part text-center">
                            <div class="tp-img">
                                <img src="./assets/assets/images/vttouchlg1.png" alt="Lucid">
                            </div>
                            <!--<div class="reg-btn">
                                <button type="button" class="btn pbtn-lite"> Register</button>
                                 <button type="button" class="btn sgn-up-nw" [routerLink]="['/login']"> Sign In</button>
                            </div>-->
                        </div>
                        <div class="header">
                            <p class="lead">Please Complete details to Create your account</p>
                        </div>
                        <div class="body">
                            <!-- <h2>Registration</h2> -->
                            <form [formGroup]="registerForm">
                                <div class="row">
                                    <div class="col-md-6 sm-input-c">
                                        <div class="form-group">
                                            <input class="form-control" type="input" formControlName="first_name" placeholder="First Name" />
                                            <span class="text-danger" *ngIf="control.first_name.dirty && control.first_name.hasError('required')">
                                                First Name Required.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 sm-input-c">
                                        <div class="form-group">
                                            <input class="form-control" type="input" formControlName="last_name" placeholder="Last Name" />
                                            <span class="text-danger" *ngIf="control.last_name.dirty && control.last_name.hasError('required')">
                                                Last Name Required.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                               <!--  <div class="row">
                                    <div class="col-md-12 sm-input-c ">
                                        <div class="form-group">
                                            <input class="form-control" type="input" formControlName="name" placeholder="User Name" />
                                            <span class="text-danger" *ngIf="control.name.dirty && control.name.hasError('required')">
                                                Name Required.
                                            </span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group">
                                            <input class="form-control" type="input" formControlName="email" placeholder="Email" />
                                            <span class="text-danger" *ngIf="control.email.dirty && control.email.hasError('required')">
                                                Email Required.
                                            </span>
                                            <span class="text-danger" *ngIf="control.email.dirty && (control.email.hasError('email') || control.email.hasError('pattern'))">
                                                Enter valid email address
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group">
                                            <input class="form-control" type="password" formControlName="password" placeholder="Password" id="password" />
                                            <span class="text-danger" *ngIf="control.password.dirty && control.password.hasError('required')">
                                                Password Required.
                                            </span>
                                            <span class="text-danger" *ngIf="control.password.dirty && control.password.hasError('minlength')">
                                                Minimum 8 character required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                 <div class="row">
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group">
                                            <input class="form-control" type="password" formControlName="confirm_password" placeholder="Confirm Password" id="confirm_password" />
                                            <span class="text-danger" *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('required')">
                                               Confirm Password Required.
                                            </span>
                                            <span class="text-danger" *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('minlength')">
                                                Minimum 8 character required
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('confirmedValidator')">
                                                Password and Confirm Password must be match.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-left checkb0x-mng">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" value="1" formControlName="tearms">
                                            <label class="form-check-label">
                                            I  agree with terms and conditions
                                            </label>
                                        </div>
                                        <span class="text-danger" *ngIf="control.tearms.dirty && control.tearms.hasError('required')">
                                            Please accept terms and conditions.
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mt-4 sgn-lst-btn">
                                        <button type="button" class="btn pbtn" (click)="submit()" [disabled]="!registerForm.valid">
                                            Register <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                         <button type="button" class="btn sgn-up-nw" [routerLink]="['/login']"> Login</button>
                                         <ul>
                                            <li><a href="https://vttouch.com/terms-conditions/" target="_blank">Terms of Uses</a></li>
                                            <li class="dont-blue"> <a href="https://vttouch.com/privacy-policy/" target="_blank" >Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="wlcom-signp">
                        <h2>
                            It's important "WHO" you know than "WHAT" you know
                        </h2>
                        <p>Our membership gives you access to a complete suite of training courses, events and coaching programme's to accelerate your career transitions.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Thank You for RegisteredModal Modal -->
 
 <!-- Modal -->
 <div class="modal fade" id="RegisteredModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button  type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
             <img src="./assets/assets/images/close-circle.png" alt="close-icon" class="img-fluid">
            </button>
          <div class="row">
             <div class="col-lg-7  col-md-6 order-md-first order-last">
                <div class="modal-body">
                   <div class="text py-5">
                      <h2>Thank You for <br>Registering With VT-Touch</h2>
                      <p>Please activate your account by confirming
                         your email address first! We have sent
                         you an confirmation email</p>
                      <a href="#" class="btn btn-primary">Go To Your Mail Box</a>
                   </div>
                </div>
              </div>
             <div class="col-lg-5 col-md-6">
                <div class="modal-body py-5  ">
                   <img src="./assets/assets/images/registerd-img.png" alt="" class="img-fluid">
                </div>
              </div>
             
            </div>
        </div>
      </div>
    </div>
  </div>