import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmedValidator } from '../../confirmed.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  public loading: boolean;
  id: number;
  token: any;
  constructor(
        private formBuilder: FormBuilder, 
        private authService: AuthService,
        private router: Router, 
        private route: ActivatedRoute, 
        private toastr: ToastrService) { }

  ngOnInit(): void {

    this.token = this.route.snapshot.params['token'];

    if(this.token){
        /* this.authService.checkEmailVarification(this.id, this.token).subscribe((data: any)=>{
          if(data.status){
            this.toastr.success(data.message, "Success");
            this.router.navigateByUrl('login');
          }else{
            this.toastr.error(data.message, "Error");
            this.router.navigateByUrl('login');
          }
        }, err => {
        this.toastr.error("Oops! Something went wrong", "Error");
        this.router.navigateByUrl('login');
      }); */
    }else{
      this.toastr.error("Oops! Something went wrong", "Error");
      this.router.navigateByUrl('login');
    }

    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
      token: ['', ],
    },{ 
      validator: ConfirmedValidator('password', 'password_confirmation')
    });
  }

  get control() {
    return this.resetForm.controls;
  }

  submit() {
    if(this.resetForm.invalid) {
      return;
    }
    this.loading = true;

    this.authService.resetPassword(this.resetForm.value).subscribe((res:any)=>{
      if(res.message) {
        this.loading = false;
        this.toastr.success(res.message, "Success");
        this.router.navigateByUrl('login');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      if(err instanceof Array){
        this.toastr.error(err.join(), "Error");
      }else{
        this.toastr.error(err, "Error");
      }
      /* console.log(err);
      this.loading = false;
      this.toastr.error(err.error.message, "Error"); */
    })
  }

}
