<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">

        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <!-- <h2>Force Field Analysis
                    </h2> -->
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><a routerLink="/tools">Tools</a></li>
                        <li class="breadcrumb-item active">Force Field Analysis</li>
                    </ul>
                </div>
            </div>
        </div>

        <div id="htmlData">
            <div class="row">

            <div class="col-md-12">
                    <form [formGroup]="questionForm" enctype="multipart/form-data" (ngSubmit)="generateReport()">

                        <div id="myHeader">
                            <div class="row mb-3">

                            <div class="col-md-5 tool-extra-style">
                                <h5 class="tool-extra tool-head-space">Generate Force Field Analysis Report</h5>
                            </div>

                            <div class="col-md-7 RightButtons">
                                <div style="float: right;">
                                    <button class="SaveReport" type="submit" class="btn pbtn" *ngIf="isGenerateShow" [disabled]="disGenBtn">Generate <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> </button>
                                </div>
                                <div style="float: right;" class="m-r-5">
                                    <button class="SaveReport" type="button" class="btn pbtn" (click)="submit()"
                                        *ngIf="isSubmitShow">Save Report</button>
                                </div>
                                <div style="float: right;" class="m-r-5 Export">
                                    <button type="button" class="btn pbtn " (click)="openPDF()" *ngIf="isPdfShow">Export As
                                        PDF</button>
                                </div>

                            </div>
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="AnalysisText">
                                        <h5>Name of Analysis</h5>
                                        <input type="text" formControlName="analysis_name" placeholder="{{analysis_name_text}}" class=" Upgradebtn form-control" (keyup)="analysis_name($event.target.value)" maxlength="25" [ngClass]="{ 'is-invalid': submitted && f.analysis_name.errors }">
                                  
                                </div>
                            </div>
                            <div class="col-md-6" style="padding-right: 0px !important;">
                            <table id="example" class="table table-striped table-bordered table-responsive ReportTable"  formArrayName="quantities">
                                    <thead class="head-table text-center">
                                        <th colspan="3">
                                            Force "FOR" Change
                                            <!-- <i class="fa fa-plus-circle" aria-hidden="true" (click)="addQuantity()"></i> -->
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr class="TopHeading">
                                            <td class="Heading">
                                               Factors
                                            </td>  
                                            <td class="Heading">
                                                Rating
                                             </td> 
                                        </tr>
                                        <tr *ngFor="let quantity of quantities().controls; let i=index;let last = last" [formGroupName]="i">
                                            <td class="sorting_1">
                                                <input type="text" placeholder="Enter Your Text" formControlName="question_title" class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': submitted && quantities_formarray.controls[i].get('question_title').errors}">  
                                            </td>  
                                            <td>
                                                <select class="Numberbox" formControlName="answer" [ngClass]="{ 'is-invalid': submitted && quantities_formarray.controls[i].get('answer').errors }">
                                                    <option  hidden>0</option>
                                                    <option id="N1" value="1" class="poption">1</option>
                                                    <option id="N2" value="2" class="poption">2</option>
                                                    <option id="N3" value="3" class="poption">3</option>
                                                    <option id="N4" value="4" class="poption">4</option>
                                                    <option id="N5" value="5" class="poption">5</option>

                                                </select>
                                                <!-- <input type="number" formControlName="answer" min="1" max="5" maxlength="1" class="Numberbox" (keyup)="only_number($event,i)">   -->
                                            </td>
                                            
                                            <td *ngIf="i==0">
                                                <div *ngIf="quantities().controls.length > 1">
                                                    <a href="javascript:" class="btn" (click)="removeQuantity(i)" >
                                                        <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                                <div *ngIf="quantities().controls.length == 1">
                                                    <a href="javascript:" class="btn" (click)="addQuantity(i)" >
                                                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                                <!-- <a href="javascript:" class="btn" (click)="removeQuantity(i)" >
                                                    <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                                </a> -->
                                            </td>
                                            
                                            <td *ngIf="i>=1">
                                                <a href="javascript:" class="btn" (click)="removeQuantity(i)">
                                                    <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                                </a>  
                                                <a href="javascript:" class="btn" (click)="addQuantity(i)" *ngIf="last">
                                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                </a>  
                                            </td>
                                        </tr>  
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6" style="padding-left: 0px !important;">
                                <table id="example" class="table table-striped table-bordered table-responsive ReportTable" formArrayName="forceAgainst">
                                    <thead class="">
                                        <tr>
                                            <th colspan="3">
                                                Force "AGAINST" Change
                                                <!-- <i class="fa fa-plus-circle" aria-hidden="true" (click)="addAgainst()"></i> -->
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="TopHeading">
                                            <td class="Heading">
                                               Factors
                                            </td>  
                                            <td class="Heading">
                                                Rating
                                             </td> 
                                        </tr>
                                        <tr *ngFor="let quantity of forceAgainst().controls; let i=index;let last = last" [formGroupName]="i">
                                          <td class="sorting_1">
                                                <input type="text" placeholder="Enter Your Text" formControlName="question_title" class="form-control" maxlength="50" [ngClass]="{ 'is-invalid': submitted && against_formarray.controls[i].get('question_title').errors}">  
                                            </td>  
                                            <td>
                                                <select class="Numberbox" formControlName="answer" [ngClass]="{ 'is-invalid': submitted && against_formarray.controls[i].get('answer').errors}">
                                                    <option hidden>0</option>
                                                    <option id="N1" value="1" class="poption">1</option>
                                                    <option id="N2" value="2" class="poption">2</option>
                                                    <option id="N3" value="3" class="poption">3</option>
                                                    <option id="N4" value="4" class="poption">4</option>
                                                    <option id="N5" value="5" class="poption">5</option>

                                                </select>
                                                
                                                <!-- <input type="number" formControlName="answer" min="1" max="5" maxlength="1" class="Numberbox" (keyup)="only_number_against($event,i)">   -->
                                            </td>
                                            <td *ngIf="i==0">
                                               
                                                    <div *ngIf="forceAgainst().controls.length > 1">
                                                        <a href="javascript:" class="btn btn-sm" (click)="removeAgainst(i)">
                                                            <i class="fa fa-minus-circle"></i>
                                                        </a>  
                                                    </div>
                                                    <div *ngIf="forceAgainst().controls.length == 1">
                                                        <a href="javascript:" class="btn" (click)="addAgainst(i)" >
                                                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                    
                                            </td>
                                            <td *ngIf="i>=1">
                                                <a href="javascript:" class="btn btn-sm" (click)="removeAgainst(i)">
                                                    <i class="fa fa-minus-circle"></i>
                                                </a>  
                                                <a href="javascript:" class="btn" (click)="addAgainst(i)" *ngIf="last">
                                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                </a> 
                                            </td>
                                        </tr>  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

              <div class="card mt-3">
                <div class="header">
                   <h2> Force Field Analysis </h2>
                </div>
               <div class="upgrade-section-header">
                  <h4>Force "FOR" Change</h4>
                  <h4>Force "AGAINST" Change</h4>
                </div>

                <div class="body">
                  <div class="upgrade-section">
                    <div class="upgrade-section__container">
                      <div class="upgrade-section__inner-wrap">
                        <div class="upgrade-section__grid">
                          <div class="upgrade-section__grid-left">
                            <span class="upgrade-section__grid-col">5</span>
                            <span class="upgrade-section__grid-col">4</span>
                            <span class="upgrade-section__grid-col">3</span>
                            <span class="upgrade-section__grid-col">2</span>
                            <span class="upgrade-section__grid-col">1</span>
                          </div>
                          <div class="upgrade-section__spacer">
                            <span class="upgrade-section__spacer-item">{{analysis_name_text}}</span>
                          </div>
                          <div class="upgrade-section__grid-right">
                            <span class="upgrade-section__grid-col">1</span>
                            <span class="upgrade-section__grid-col">2</span>
                            <span class="upgrade-section__grid-col">3</span>
                            <span class="upgrade-section__grid-col">4</span>
                            <span class="upgrade-section__grid-col">5</span>
                          </div>
                        </div>
              
                        <div class="upgrade-section__items">
                          <div class="upgrade-section__items-left">
                             <span *ngFor="let force of queControl.quantities.value" class="upgrade-section__items-col arrow-{{force.answer}}" data-point="{{force.answer}}">{{force.question_title}}</span>
                          </div>
                          <div class="upgrade-section__spacer"></div>
                          <div class="upgrade-section__items-right">
													 <span  *ngFor="let forceAgainst of queControl.forceAgainst.value" class="upgrade-section__items-col arrow-{{forceAgainst.answer}}" data-point="{{forceAgainst.answer}}">{{forceAgainst.question_title}}</span>
                           
                            

                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="upgrade-section-footer">
                    <button class="button button-green">Total Score: {{totalforce}}</button>
                    <button class="button button-pink">Total Score: {{totalforceAgaint}}</button>
                  </div>
              </div>
              </div>

        </div>
        </div>
</div>