<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Learn</li>
                        <li class="breadcrumb-item">{{category_name}}</li>
                    </ul>
                </div>
            </div>
            <br />
            <div class="row">
               <div class="col-lg-12 col-md-12 col-sm-12" >
                    
                    <img src="./assets/assets/images/Generic-banner.jpg"
                        class="top-background-learing" *ngIf="cate_slug=='generic-skills'">
                    <img src="./assets/assets/images/Testing-banner.jpg" class="top-background-learing" 
                        *ngIf="cate_slug=='testing-skills'">
                    <img  src="./assets/assets/images/Soft-banner.jpg" class="top-background-learing" 
                        *ngIf="cate_slug=='soft-skills'">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="row clearfix top-xcode" *ngIf="learningList.length>0">
                    <div class="col-lg-4 col-md-6 col-sm-12 text-center" *ngFor="let value of learningList">
                        <div class="card-cd">
                            <div class="Defoult-Card">
                                <img src="./assets/assets/card-img/emplateGeneric-hover.png" class="roundright-cd border-round">
                                <img [src]="value.icon" class="sideleft-cd">
                                <!--<img src="./assets/assets/card-img/Icon.png" class="sideleft-cd">-->
                                <!--./sidebar-cd-->
                                <div class="round-cd bar-cd">
                                    <h5 class="learning-cd">VT Touch Learning-</h5>
                                    <h3 class="skill-cd">{{value.category_name}}"</h3>
                                </div>
                                <div class="sidebar-cd">
                                    <h2 class="h5-cd">{{value.title}}</h2>
                                </div>
                                <div *ngIf="value.attachment">
                                    <a [href]="value.attachment" target="_blank" rel="noopener noreferrer" [download]="value.title">
                                    <img src="./assets/assets/images/Download-hover.png" class="sideright-cd">
                                    </a>
                                </div>
                            </div>
                            <!--./Defoult card-->
                            <div class="overlay-cd">
                                <img src="./assets/assets/card-img/template.png" class="roundright-cd border-round">
                                <img [src]="value.hover_icon" class="sideleft-cd">
                                <!-- <img src="./assets/assets/card-img/group.png" class="side-cd"> -->
                                <!--./sidebar-cd-->
                                <div class="round-cd bar-cd">
                                    <h5 class="learning-cd">VT Touch Learning-</h5>
                                    <h3 class="skill-cd">{{value.category_name}}"</h3>
                                </div>
                                <div class="sidebar-cd">
                                    <h2 class="h5-cd">{{value.title}}</h2>
                                </div>
                                <div *ngIf="value.attachment">
                                    <a [href]="value.attachment" target="_blank" rel="noopener noreferrer" [download]="value.title">
                                    <img src="./assets/assets/images/Download.png" class="right-cd">
                                    </a>
                                </div>
                            </div>
                            <!--./overlay-->
                        </div>
                        <!--./card-->
                    </div>
                    <!--./col-lg-4 col-md-6 col-sm-12 text-center-->
                </div>
                <!--./div-->
            </div>
            <!--./col-md-12-->
        </div>
        <!--./row-->
        <div class="row clearfix text-center" *ngIf="learningList.length==0">
            <div class="col-md-12 text-center">
                No Data Found
            </div>
        </div>
    </div>
</div>