export class GlobalConstants {

    //public static apiURL: string = "http://toponreviews.com/api";

    //public static apiURL: string = "http://api.toponreviews.com/api";

    public static apiURL: string = "https://api.vttouch.com/api";
    //public static apiURL: string = "http://localhost/vttouch/public/api";

    public static siteTitle: string = "VT Touch";

    public static ImagePATH: string = "http://api.vttouch.com/";

    //public static user_profile_path: 
    
    private static ProjectPath: string = "http://api.vttouch.com/";
    
    public static user_profile_path: string = GlobalConstants.ProjectPath+'/uploads/users/photo/';
    

}