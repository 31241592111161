import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GrowService } from 'src/app/services/grow.service';

@Component({
  selector: 'app-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.css']
})
export class JobSearchComponent implements OnInit {

  jobs:any;
  SearchJobForm: FormGroup;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private growService: GrowService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    this.SearchJobForm = this.formBuilder.group({
      what: [''],
      where: ['']
    });
  }

  ngOnInit(): void {
    this.getAllJobs();
  }

  getAllJobs() {
    var formData = new FormData();
    formData.append('what', this.SearchJobForm.get('what').value);
    formData.append('where', this.SearchJobForm.get('where').value);
    this.growService.getAllJobs(formData).subscribe((response: any)=>{
      if (response.status) {
        this.jobs = response.data;
      } else {
        this.jobs = [];
        this.toastr.error(response.message, "Error");
      }
    }, error => {
      this.jobs = [];
      this.toastr.error(error, "Error");
    });
  }

  favouriteAddRemove(id) {
    var formData = new FormData();
    formData.append('job_id', id);
    this.growService.favouriteAddRemove(formData).subscribe((response: any)=>{
      if (response.status) {
        this.toastr.success(response.message, "Success");
        this.getAllJobs();
      } else {
        this.toastr.error(response.message, "Error");
      }
    });
  }

}
