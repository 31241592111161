import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as $ from "jquery";

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.css']
})
export class UserMessageComponent implements OnInit {
	@ViewChild('scrollMe') private myScrollContainer: ElementRef;
  session_id:any;
  messageList:any = [];
  isScrollStop = false;
  page = 1;
  isPaginationShow = true;
  userList:any = [];
  isUserFound = false;
  reciver_user_id:any;
  chatOpenData :any;
  sendMessage: FormGroup;
  
  constructor(private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private messageService: MessageService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    //this.sender_id = this.route.snapshot.params['id'];
    this.session_id = this.route.snapshot.params['id'];

    this.sendMessage = this.formBuilder.group({
      message_description: ['', [Validators.required]],
      reciver_user_id: ['', ],
    })

    this.messageService.get_connected_user_list().subscribe((res: any)=>{
      if(res.status){
        this.userList = res.data;
        
        if(this.userList.length>0){
          this.isUserFound = true;
          this.session_id = this.userList[0].m_session_id;
          this.reciver_user_id = this.userList[0].sender_id;
          this.GetChat(this.userList[0]);
        }
        // else{
        //   this.session_id = this.userList[0].m_session_id;
        //   this.reciver_user_id = this.userList[0].sender_id;
        //   this.GetChat(this.userList[0]);
        // }
        // if(this.session_id){
          
        //   this.session_id = this.userList[0].m_session_id;
        //   this.reciver_user_id = this.userList[0].sender_id;
        //   this.GetChat(this.userList[0]);
          
        // }else{
        //   if(this.userList.length>0){
            
        //     this.session_id = this.userList[0].m_session_id;
        //     this.reciver_user_id = this.userList[0].sender_id;
        //     this.getAllMessage(1);
        //     let messageData = [];
        //     messageData['reciver_user_id'] = this.reciver_user_id;
        //     this.sendMessage.patchValue(messageData);
        //   }
        // }
      }else{
        //this.isPaginationShow = false;
        //this.isScrollStop = true;
      }
    }, err=> {
      this.isPaginationShow = false;
      this.isUserFound = false;
      this.toastr.error(err, "Error");
    });
		const $this = this;
			$('#scrollWindow').scroll(function() {
				console.log('scrolling..............',$this.isPaginationShow)
			if($('#scrollWindow').scrollTop() == 10 && $this.isPaginationShow){
					$this.getAllMessage($this.page);
			}
		 
		});
    //this.firstComponentFunction();
  }
  
  get FormControl() {
    return this.sendMessage.controls;
  }

  SendMessageStore(){
    if(this.sendMessage.invalid) {
      return;
    }
    //console.log(this.sendMessage.value);
    //this.CControl.post_id.setValue(postId);
    
    this.messageService.AddMessage(this.sendMessage.value).subscribe((res:any)=>{
      if(res.status) {
        this.sendMessage.reset();
        if(res.data!== undefined){
          //this.messageList.unshift(res.data);
					// this.messageList = [res.data].concat(this.messageList)
					this.messageList.push(res.data)
					this.scrollToBottom();
        }
        let messageData = [];
        messageData['reciver_user_id'] = this.reciver_user_id;
        this.sendMessage.patchValue(messageData);
        //this.toastr.success('Send message successfully', "Success");
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

  // firstComponentFunction(){    
  //   this.messageService.onFirstComponentButtonClick();    
  // }

  getAllMessage(page=1){
    this.messageService.get_message_by_session_id(this.session_id, page).subscribe((res: any)=>{
      if(res.status){
				if(res.data != null){
        //this.messageList = res.data;
        if(this.messageList.length>0){
					// this.messageList = [res.data].concat(this.messageList)
          this.messageList = [...res.data, ...this.messageList];
					this.scrollToBottom((res.data.length*50));
        }else{
          this.messageList = res.data;
        }
        this.isPaginationShow = true;
				if(this.page == 1){
					this.scrollToBottom();
				}   
        this.page = page+1;
				}else{
					 this.isPaginationShow = false;
				}
		
      }else{
        this.isPaginationShow = false;
        this.isScrollStop = true;
		// this.scrollToBottom();   
      }
    }, err=> {
      this.isPaginationShow = false;
      this.isScrollStop = true;
	      
      //this.toastr.error(err, "Error");
    });
  }

  onScroll(e) {
    if(this.isScrollStop){
      return;
    }else{
      this.getAllMessage(this.page);
    }
  }

  GetChat(user){
	this.chatOpenData = user;
    this.session_id = user.m_session_id;
    this.reciver_user_id = user.sender_id;
    let messageData = [];
    messageData['reciver_user_id'] = this.reciver_user_id;
    this.sendMessage.patchValue(messageData);
    this.messageList = [];
		this.page = 1;
    this.getAllMessage(1);
	
  }
  scrollToBottom(height = 0): void {
	try {
		var $target = $('#scrollWindow'); 
		const scrollTop = height || ($target.height()*10)
		$target.animate({scrollTop: scrollTop}, 10);
		// this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
	} catch(err) { }                 
}

  

 

  

}
