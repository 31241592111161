import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GrowService } from 'src/app/services/grow.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {
    
  isFirstTime = false;
  closeResult: string;
  id:string;
  jobDetails:any;
  ApplyJobForm: FormGroup;
  changeCv:boolean = false;
  modalReference: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private growService: GrowService,
    private formBuilder: FormBuilder
  ) {
    this.ApplyJobForm = this.formBuilder.group({
      id: [],
      job_title: ['', [Validators.required]],
      company_name: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      cover_letter: ['', [Validators.required]],
      cv: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    const formData = new FormData();
    formData.append('job_id', this.id);
    this.growService.fetchJob(formData).subscribe((response: any) => {
      if (response.status) {
        this.jobDetails = response.data;
        this.control.id.patchValue(this.id);
      } else {
        this.toastr.error("Job not found", "Error");
      }
    }, error => {
      this.toastr.error(error, "Error");
    });
  }

  name = 'Angular 6';
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  favouriteAddRemove(id) {
    var formData = new FormData();
    formData.append('job_id', id);
    this.growService.favouriteAddRemove(formData).subscribe((response: any)=>{
      if (response.status) {
        this.jobDetails.is_fav = response.data.status;
        this.toastr.success(response.message, "Success");
      } else {
        this.toastr.error(response.message, "Error");
      }
    });
  }

  open(ApplyModal) {
    this.modalReference = this.modalService.open(ApplyModal, {size: 'lg', ariaLabelledBy: 'modal-basic-title'});
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get control() {
    return this.ApplyJobForm.controls;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileSizeInKB = Math.round(file.size / 1025);
      if (fileSizeInKB >= 2048) {
        this.toastr.error("Allow only 2 mb file size", "Error");
        return;
      }
      document.getElementById('noFile').innerHTML = file.name;
      this.changeCv = true;
      this.control.cv.patchValue(file);
    }
  }

  applyForJob() {
    if (this.ApplyJobForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('job_id', this.ApplyJobForm.get('id').value);
    formData.append('job_title', this.ApplyJobForm.get('job_title').value);
    formData.append('company_name', this.ApplyJobForm.get('company_name').value);
    formData.append('first_name', this.ApplyJobForm.get('first_name').value);
    formData.append('last_name', this.ApplyJobForm.get('last_name').value);
    formData.append('cover_letter', this.ApplyJobForm.get('cover_letter').value);
    formData.append('cv', this.ApplyJobForm.get('cv').value);
    
    this.growService.applyForJob(formData).subscribe((response:any) => {
      if (response.status) {
        this.toastr.success('Applied for job successfully', "Success");
        this.modalReference.close();
        this.jobDetails.is_apply = 1;
        this.ApplyJobForm.reset();
      } else {
        this.toastr.error(response.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

}