import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DealroomService } from 'src/app/services/dealroom.service';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-room-users',
  templateUrl: './room-users.component.html',
  styleUrls: ['./room-users.component.css']
})
export class RoomUsersComponent implements OnInit {

  users;
  userForm: FormGroup;
  loading:boolean = false;
  id;
  checkedList:any = [];

  constructor(private authService: AuthService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private toastr: ToastrService, 
    private _location: Location, 
    private formBuilder: FormBuilder,
    private roomService: DealroomService,
    ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.roomService.getUsers(this.id).subscribe((data: any)=>{
      if(data.status){
        this.users = data.result;
        this.users.forEach((item: any) => {
          if (item.is_checked) {
            const checkArray: FormArray = this.userForm.get('user_id') as FormArray;
            checkArray.push(new FormControl(item.id));
          }
        });
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });

    this.userForm = this.formBuilder.group({
        deal_room_id: ['', [Validators.required]],
        user_id: this.formBuilder.array([], [Validators.required])
    })
    this.userForm.patchValue({'deal_room_id':this.id})
  }

  get control() {
    return this.userForm.controls;
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.userForm.get('user_id') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submit() {
    if(this.userForm.invalid) {
      return;
    }
    /* console.log(this.userForm.value);
    return false; */
    this.roomService.addUser(this.userForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.toastr.success('User add/Edit successfully', "Success");
        this.router.navigateByUrl('admin/dealroom');
        this.userForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

  backClicked() {
    this._location.back();
  }

}
