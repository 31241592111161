import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  //session_id:any;
  notList:any = [];
  isScrollStop = false;
  page = 1;
  isPaginationShow = true;
  //userList:any = [];
  //isUserFound = false;
  receiver_id:any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.getAllNotificationOfUser();
  }

  getAllNotificationOfUser(page=1){
    this.messageService.getAllNotificationOfUser(page).subscribe((res: any)=>{
      if(res.status){
        //this.messageList = res.data;
        if(this.notList.length>0){
          this.notList = [ ...this.notList, ...res.data];
        }else{
          this.notList = res.data;
        }
        this.isPaginationShow = true;
        this.page = page+1;
      }else{
        this.isPaginationShow = false;
        this.isScrollStop = true;
      }
    }, err=> {
      this.isPaginationShow = false;
      this.isScrollStop = true;
      //this.toastr.error(err, "Error");
    });
  }

  onScroll(e) {
    if(this.isScrollStop){
      return;
    }else{
      this.getAllNotificationOfUser(this.page);
    }
  }

}
