
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdmindashboardService } from 'src/app/services/admindashboard.service';
import { DealroomService } from 'src/app/services/dealroom.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: AdmindashboardService,
    private roomService: DealroomService,
    private toastr: ToastrService
  ) { }

  dashboardCount: any;
  upcomingEvents: any;
  subscriptions: any;
  dealRoomRequest: any;

  ngOnInit(): void {
    this.getDashboardCount();
    this.getUpcomingEvents();
    this.getAllSubscriptions();
    this.getDealRoomRequest();
  }

  getDashboardCount() {
    this.dashboardService.getDashboardCount().subscribe((response: any)=>{
      if (response.status) {
        this.dashboardCount = response.data;
      } else {
        this.toastr.error(response.message, "Error");
      }
    });
  }

  getUpcomingEvents() {
    this.dashboardService.getUpcomingEvents().subscribe((response: any)=>{
      if (response.status) {
        this.upcomingEvents = response.data;
      } else {
        this.toastr.error(response.message, "Error");
      }
    });
  }

  getAllSubscriptions() {
    this.dashboardService.getAllSubscriptions().subscribe((response: any)=>{
      if (response.status) {
        this.subscriptions = response.data;
      } else {
        this.toastr.error(response.message, "Error");
      }
    });
  }

  getDealRoomRequest() {
    this.dashboardService.getDealRoomRequest().subscribe((response: any)=>{
      if (response.status) {
        this.dealRoomRequest = response.data;
      } else {
        this.toastr.error(response.message, "Error");
      }
    });
  }

  JoinUserToRoom(id, roomId) {
    Swal.fire({
      title: 'Are you sure want to approve request?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, add it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let data = { 'deal_room_id': roomId, 'user_id': id}
        this.roomService.addSingleUser(data).subscribe((data: any)=>{
            if (data.status) {
              this.toastr.success("Deal room join request approved successfully", "Success");
              this.getDealRoomRequest();
            } else {
              this.toastr.error(data.message, "Error");
            }
        });
      }
    })
  }

}
