import { Directive,Component, OnInit,ElementRef,HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
declare var $: any;

@Component({
  selector: 'app-tool3',
  templateUrl: './tool3.component.html',
  styleUrls: ['./tool3.component.css'],
  providers: [DatePipe]
})
export class Tool3Component implements OnInit {

  questionForm: FormGroup;
  id:any;
  questionList: any = [];
  submitted = false;
  loading = false;
  disGenBtn = false;

  userFileName:any;
  isGenerateShow = true;
  isSubmitShow = false;
  isPdfShow = false;
  date:any =new Date();
  toolData:any;
  question:any = [];
	totalforce = 0;
	totalforceAgaint = 0;
  chartOption:any;
  analysis_name_text:string = 'Add your report name';
  

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private elRef: ElementRef
  ) { }


  ngOnInit(): void {

    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#myHeader').addClass('sticky');
      } else {
        $('#myHeader').removeClass('sticky');
      }
    });

    this.id = this.route.snapshot.params['id'];
      var val = Math.floor(1000 + Math.random() * 9000);
      this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd HH:mm');
      this.userFileName = this.authService.user.name+'_'+this.date;

      this.authService.getAllTool().subscribe((res:any)=>{
        if(res.status) {
          this.toolData = res.tools;
          if(this.toolData && this.toolData[1]){
            if(this.toolData[1].user_tools && this.toolData[2].user_tools.length>=5){
              this.toastr.error("You already reached your maximum tool", "Error");
              this.router.navigateByUrl('/tools');
            }
          }
        }
      })

      this.authService.getAllQuestionsByType(4).subscribe((res:any)=>{
        if(res.status) {
          //this.question = res.question;
          this.queControl.tools_id.setValue(this.id);
          // this.queControl.question_1_qustion.setValue(this.question[0].id);
          // this.queControl.question_2_qustion.setValue(this.question[1].id);
          // this.queControl.question_3_qustion.setValue(this.question[2].id);
          // this.queControl.question_4_qustion.setValue(this.question[3].id);
          // this.queControl.question_5_qustion.setValue(this.question[4].id);
          // this.queControl.question_6_qustion.setValue(this.question[5].id);
          // this.queControl.question_7_qustion.setValue(this.question[6].id);
          // this.queControl.question_8_qustion.setValue(this.question[7].id);
          // this.queControl.question_9_qustion.setValue(this.question[8].id);
          // this.queControl.question_10_qustion.setValue(this.question[9].id);
          
        } else {
          this.toastr.error("Question list not found", "Error");
        }
      })

      this.questionForm = this.formBuilder.group({
        // question_1_qustion: [''],
        // question_2_qustion: [''],
        // question_3_qustion: [''],
        // question_4_qustion: [''],
        // question_5_qustion: [''],
        // question_6_qustion: [''],
        // question_7_qustion: [''],
        // question_8_qustion: [''],
        // question_9_qustion: [''],
        // question_10_qustion: [''],
        
        
        // question_1_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_2_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_3_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_4_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_5_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_6_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_7_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_8_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_9_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],
        // question_10_answer: ['', [Validators.required, Validators.pattern("^[0-5]*$")]],

        tools_id: [],
        total_1: [],
        total_2: [],
        quantities: this.formBuilder.array([]),
        forceAgainst: this.formBuilder.array([]),  
        analysis_name: ['',Validators.required],  
    })

    this.addQuantity();
    this.addAgainst();
  }

   // convenience getter for easy access to form fields
   get f() { 
     return this.questionForm.controls; 
    }

  get queControl() {
    return this.questionForm.controls;
  }
  get quantities_formarray(): FormArray {
    return this.questionForm.get('quantities') as FormArray;
  } 
  get against_formarray(): FormArray {
    return this.questionForm.get('forceAgainst') as FormArray;
  } 

  quantities() : FormArray {  
    return this.questionForm.get("quantities") as FormArray  
  }
  
  forceAgainst() : FormArray {  
    return this.questionForm.get("forceAgainst") as FormArray  
  }
     
  newQuantity(): FormGroup {  
    return this.formBuilder.group({ 
      question_title: ['', [Validators.required]],  
      answer: ['', [Validators.required, Validators.pattern("^[1-5]*$")]],  
    })  
  }
  
  newAgainst(): FormGroup {  
    return this.formBuilder.group({  
      question_title: ['', [Validators.required]],  
      answer: ['', [Validators.required, Validators.pattern("^[1-5]*$")]], 
    })  
  }
  
  i:number=0;
  addQuantity() {
    this.i = this.i+1;
    if(this.i<=10){
      this.quantities().push(this.newQuantity());
    }
  }
  
  j:number=0;
  addAgainst() {
    this.j = this.j+1;
    if(this.j<=10){
      this.forceAgainst().push(this.newAgainst());
    }
  }

  removeQuantity(i:number) {
    this.i = this.i-1;
    this.quantities().removeAt(i);
  }
  removeAgainst(j:number) {
    this.j = this.j-1;
    this.forceAgainst().removeAt(j);
  }

  generateReport(){
    this.submitted = true;
    this.loading = true;
    //console.log(this.questionForm.value);
    if(!this.questionForm.valid){
      // this.toastr.error("only number allowed and not greater than 5", "Error");
      // this.toastr.error("Please fill all the value to red border fields", "Error");
      this.loading = false;
      return;
    }else{
      this.loading = false;
      this.disGenBtn = true;
      console.log(this.queControl.forceAgainst.value);

      let forceAgainstVal = this.queControl.forceAgainst.value;
      let Total = forceAgainstVal.reduce((accumulator, current) => accumulator + Number(current.answer), 0);

      let forceVal = this.queControl.quantities.value;
      let Total2 = forceVal.reduce((accumulator, current) => accumulator + Number(current.answer), 0);
			this.totalforceAgaint = Total;
			this.totalforce = Total2;
      console.log(forceAgainstVal);
      //return;
      //forceAgainstVal.filter(x => x.answer);
      
      // let Total = 
      // Number(this.queControl.forceAgainst.value) +
      // Number(this.queControl.question_2_answer.value) +
      // Number(this.queControl.question_3_answer.value) +
      // Number(this.queControl.question_4_answer.value) +
      // Number(this.queControl.question_5_answer.value);

      // let Total2 = 
      // Number(this.queControl.question_6_answer.value) +
      // Number(this.queControl.question_7_answer.value) +
      // Number(this.queControl.question_8_answer.value) +
      // Number(this.queControl.question_9_answer.value) +
      // Number(this.queControl.question_10_answer.value);


      this.queControl.total_1.setValue(Total);
      this.queControl.total_2.setValue(Total2);

      let percentage = (Total+Total2);
      //this.queControl.total_pr.setValue(percentage);
      //this.queControl.opportunity_profibility_score.setValue(Total);
      //this.opp_pro_total = Total;
      //this.isGenerateShow = false
      this.isSubmitShow=true;

      // this.chartOption = {
      //   series: [{
      //     type: 'gauge',
      //     axisLine: {
      //         lineStyle: {
      //             width: 30,
      //             color: [
      //                 [0.2, '#ed9bd1'],
      //                 [0.4, '#e982c6'],
      //                 [0.6, '#e569bb'],
      //                 [0.8, '#e050af'],
      //                 [1, '#d81e99']
      //             ]
      //         }
      //     },
      //     pointer: {
      //         itemStyle: {
      //             color: 'auto'
      //         }
      //     },
      //     axisTick: {
      //         distance: -30,
      //         length: 8,
      //         lineStyle: {
      //             color: '#fff',
      //             width: 2
      //         }
      //     },
      //     splitLine: {
      //         distance: -30,
      //         length: 30,
      //         lineStyle: {
      //             color: '#fff',
      //             width: 4
      //         }
      //     },
      //     axisLabel: {
      //         color: 'auto',
      //         distance: 40,
      //         fontSize: 20
      //     },
      //     detail: {
      //         valueAnimation: true,
      //         formatter: '{value}',
      //         color: 'auto'
      //     },
      //     data: [{
      //         value: percentage
      //     }]
      // }]
      // };
    }
  }


 

  analysis_name(value){
      this.analysis_name_text=value
  }
  only_number(event : any,i:number){
    if(event.target.value > 5){
      event.target.value = '5';
      this.quantities()['controls'][i]['controls'].answer.setValue(5)
    }else if(event.target.value < 1){
      event.target.value = '1';
      this.quantities()['controls'][i]['controls'].answer.setValue(1)
    }
  }
  only_number_against(event : any,i:number){
    if(event.target.value > 5){
      event.target.value = '5';
      this.forceAgainst()['controls'][i]['controls'].answer.setValue(5)
    }else if(event.target.value < 1){
      event.target.value = '1';
      this.forceAgainst()['controls'][i]['controls'].answer.setValue(1)
    }
  }

  submit(){
    if(!this.questionForm.valid){
      this.toastr.error("only number allowed and not greater than 5", "Error");
      return;
    }else{
      this.authService.createUserRating3(this.questionForm.value).subscribe((res:any)=>{
        if(res.status) {
          this.toastr.success('User rating added successfully', "Success");
          this.isPdfShow=true;
          this.isSubmitShow=false;
          this.isGenerateShow=false;
          //this.router.navigateByUrl('/tools');
        } else {
          this.toastr.error("User rating add error", "Error");
          //this.JoinAndClose();
        }
        //this.router.navigateByUrl('tools');
      })
    }
  }

  public openPDF():void {
    let DATA = document.getElementById('htmlData');

   html2canvas(DATA).then(canvas => {
      // Few necessary setting options
      var margin = 3;
      var imgWidth = 208-margin*2;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      //pdf.text("Project Leader Characteristics", 20, 20);
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight)
      pdf.setFontSize(30);
      pdf.setFont("times");
      //pdf.setFontType("bold");
      pdf.setTextColor(76, 130, 212);
      //pdf.fillStyle("rgba(76, 130, 212,.2)");
      //pdf.text('VTTouch', 100, imgHeight/2);
      pdf = this.addWaterMark(pdf);
      pdf.save(this.userFileName+'.pdf');
      //pdf.save('test.pdf'); // Generated PDF
    });
  }

  addWaterMark(doc) {
    var totalPages = doc.internal.getNumberOfPages();
    doc.setTextColor(150);
    doc.text(120, doc.internal.pageSize.height/3, 'VT-Touch');
    return doc;
  }

}
