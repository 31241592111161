<div class="vertical-align-wrap">
    <div class="vertical-align-middle auth-main">
        <div class="auth-box container">
            <div class="row row align-items-center">
                <div class="col-md-6">
                    <div class="top">
                        <!-- <img src="./assets/assets/images/vttouch.png" alt="Lucid"> -->
                    </div>
                    <div class="card">
                        <div class="top-part text-center">
                            <div class="tp-img">
                                <img src="./assets/assets/images/vttouchlg1.png" alt="Lucid">
                            </div>
                            <!--<div class="reg-btn">
                                <button type="button" class="btn sgn-up-nw" [routerLink]="['/register']"> Register</button>
                                <button type="button" class="btn pbtn-lite"> Sign In</button>
                            </div>-->
                        </div>
                        <div class="header">
                            <p class="lead">Reset your password</p>
                        </div>
                        <div class="body">
                            <!-- <h2>Registration</h2> -->
                            <form [formGroup]="resetForm">
                                <div class="row">
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group">
                                            <!-- <label>Email <span>*</span> </label> -->
                                            <input class="form-control" type="input" formControlName="email" placeholder="Email"/>
                                            <span class="text-danger" *ngIf="control.email.dirty && control.email.hasError('required')">
                                                Email Required.
                                            </span>
                                            <span class="text-danger" *ngIf="control.email.dirty && control.email.hasError('email')">
                                                Enter valid email address
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group ">
                                            <!-- <label>New Password <span>*</span></label> -->
                                            <input class="form-control" type="password" formControlName="password" id="password" placeholder="New Password" />
                                            <span class="text-danger" *ngIf="control.password.dirty && control.password.hasError('required')">
                                                New password is requied.
                                            </span>
                                            <span class="text-danger" *ngIf="control.password.dirty && control.password.hasError('minLength')">
                                                Minimum 8 character required.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 sm-input-c">
                                        <div class="form-group">
                                            <!-- <label>Confirm Password <span>*</span></label> -->
                                            <input class="form-control" type="password" formControlName="password_confirmation" id="password_confirmation"
                                            placeholder="Confirm Password" />
                                            <span class="text-danger"
                                                *ngIf="control.password_confirmation.dirty && control.password_confirmation.hasError('required')">
                                                Confirm password is requied.
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="control.password_confirmation.dirty && control.password_confirmation.hasError('minLength')">
                                                Minimum 8 character required.
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="control.password_confirmation.dirty && control.password_confirmation.hasError('confirmedValidator')">
                                                Password and Confirm Password must be match.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" formControlName="token" id="token" [(ngModel)]="token" value="{{token}}">
                                <div class="row">
                                    <div class="col-md-12 mt-4 sgn-lst-btn">
                                        <button type="button" class="btn pbtn m-r-15" (click)="submit()"
                                            [disabled]="!resetForm.valid">Reset
                                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                        <!-- <button type="button" class="btn sgn-up-nw m-r-15" [routerLink]="['/register']">
                                            Register</button>
                                        <ul>
                                            <li>Terms Of Uses</li>
                                            <li class="dont-blue">Privacy Policy</li>
                                        </ul>-->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="wlcom-signp">
                        <h2>
                            It's important "WHO" you know than "WHAT" you know
                        </h2>
                        <p>Our membership gives you access to a complete suite of training courses, events and coaching programme's to accelerate your career transitions.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
