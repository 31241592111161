import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectBox'
})
export class SelectBoxPipe implements PipeTransform {

  transform(items: any, sel?: any): any {
        return sel ? items.filter(sal => sal.industry_id === sel) : items;
    }

}
