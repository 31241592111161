<app-header></app-header>
<app-sidebar></app-sidebar>
<style>
   .has-error {
      border-color: red !important;
   }
</style>
<div id="main-content">
   <div class="container-fluid">
      <div class="block-header top-head-vt">
         <div class="row">
            <div class="col-lg-5 col-md-8 col-sm-12">
              <!-- <h2>  Opportunity Analysis (Risk Vs Benefit)
               </h2>  -->
               <ul class="breadcrumb vt-a">
                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                  <li class="breadcrumb-item"><a [routerLink]="['/tools']">Tools</a></li>
                  <li class="breadcrumb-item"> Opportunity Analysis (Risk Vs Benefit)</li>
               </ul>
            </div>
         </div>
      </div>
      <section id="htmlData">
         <form [formGroup]="questionForm" enctype="multipart/form-data">
            <div class="proej-char-tbl">
               <div class="row">
                  <div id="myHeader" class=" col-md-12 mb-3 pro-head ">

                     <div class="row">
                        <div class="col-md-5 tool-extra-style">
                         <h3 class="tool-extra tool-head-space">
                             Benefit Analysis
                             <i class="fa fa-info-circle" aria-hidden="true"></i>
                             <!-- Modal -->
                        <div class="Instructions-Modal modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                           <div class="modal-dialog  modal-lg" role="document">
                              <div class="modal-content">
                                 <div class="modal-header">
                                    <h4 class="modal-title" id="myModalLabel">Instructions:</h4>
                                 </div>
                                 <div class="modal-body">
                                    
                                    <div class="im-list">
                                       <div class="Opportunity">
                                          <p>Use the Opportunity Analysis Template to assign a quantitative value to the key risks and benefits you have 
                                             identified for the opportunity. 
                                          </p>
                                          <p>- For each potential benefit and risk identified, determine whether the probability of occurrence and the impact 
                                             on the project is high, medium, or low.
                                          </p>
                                          <p>- Assign separate scores for the probability of occurrence and for the impact on the project as follows:</p>
                                          <div class="row text-center">
                                             <div class="col-md-4">
                                                <p> <span class="mr-2"><img src="./assets/assets/images/GreenPoint.png" alt="Lucid" class="img-fluid"></span> High = 10 points</p>
                                             </div>
                                             <div class="col-md-4">
                                                <p><span class="mr-2"> <img src="./assets/assets/images/YellowPoint.png" alt="Lucid" class="img-fluid"></span> Medium = 5 points</p>
                                             </div>
                                             <div class="col-md-4">
                                                <p><span class="mr-2"> <img src="./assets/assets/images/RedPoint.png" alt="Lucid" class="img-fluid"></span> = 2 points</p>
                                             </div>
                                          </div>
                                          <p>- Total the points for the probability of occurrence and for impact on the project for the benefits.</p>
                                          <p>- Add the score for probability of occurrence to the score for impact on the project to determine the total score 
                                             for benefits.
                                          </p>
                                          <p>- Total the points for the probability of occurrence and for impact on the project for the risks.</p>
                                          <p>- Add the score for probability of occurrence to the score for impact on the project to determine the total score
                                             for risks.
                                          </p>
                                        </div>
                                       <div class="Opportunity2">
                                          <p>Locate the risk and benefit scores on the Risk Versus Benefit Decision Scale. Use the decision scale to guide 
                                             your decision.
                                          </p>
                                        </div>
                                    </div>
                                 </div>
                                 <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                         </h3>
                        </div>
   
                        <div class="col-md-7 RightButtons">
                           <div style="float: right;" class="m-r-5 Export">
                              <button type="button" class="btn pbtn m-l-5 m-l-5" (click)="generateReport()" *ngIf="isGenerateShow" [disabled]="disGenBtn"> Generate</button>
                              <button type="button" class="btn pbtn m-l-5 mr-2" (click)="submit()" *ngIf="isSubmitShow"> Save</button>
                              <button type="button" class="btn pbtn mr-2" (click)="openPDF()" *ngIf="isPdfShow">Export PDF</button>
                           </div>
   
                        </div>
                     </div>
                     </div>
                  <!-- <div id="myHeader" class="pro-head col-md-12 mb-3 tool-extra-style"  >
                     <h3>
                        <span>Benefit Analysis</span>
                        <i class="fa fa-info-circle" aria-hidden="true"  id="mymodal" data-toggle="modal" data-target="#myModal"></i>
                  
                        <div class="Instructions-Modal modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                           <div class="modal-dialog  modal-lg" role="document">
                              <div class="modal-content">
                                 <div class="modal-header">
                                    <h4 class="modal-title" id="myModalLabel">Instructions:</h4>
                                 </div>
                                 <div class="modal-body">
                                    
                                    <div class="im-list">
                                       <div class="Opportunity">
                                          <p>Use the Opportunity Analysis Template to assign a quantitative value to the key risks and benefits you have 
                                             identified for the opportunity. 
                                          </p>
                                          <p>- For each potential benefit and risk identified, determine whether the probability of occurrence and the impact 
                                             on the project is high, medium, or low.
                                          </p>
                                          <p>- Assign separate scores for the probability of occurrence and for the impact on the project as follows:</p>
                                          <div class="row text-center">
                                             <div class="col-md-4">
                                                <p> <span class="mr-2"><img src="./assets/assets/images/GreenPoint.png" alt="Lucid" class="img-fluid"></span> High = 10 points</p>
                                             </div>
                                             <div class="col-md-4">
                                                <p><span class="mr-2"> <img src="./assets/assets/images/YellowPoint.png" alt="Lucid" class="img-fluid"></span> Medium = 5 points</p>
                                             </div>
                                             <div class="col-md-4">
                                                <p><span class="mr-2"> <img src="./assets/assets/images/RedPoint.png" alt="Lucid" class="img-fluid"></span> = 2 points</p>
                                             </div>
                                          </div>
                                          <p>- Total the points for the probability of occurrence and for impact on the project for the benefits.</p>
                                          <p>- Add the score for probability of occurrence to the score for impact on the project to determine the total score 
                                             for benefits.
                                          </p>
                                          <p>- Total the points for the probability of occurrence and for impact on the project for the risks.</p>
                                          <p>- Add the score for probability of occurrence to the score for impact on the project to determine the total score
                                             for risks.
                                          </p>
                                        </div>
                                       <div class="Opportunity2">
                                          <p>Locate the risk and benefit scores on the Risk Versus Benefit Decision Scale. Use the decision scale to guide 
                                             your decision.
                                          </p>
                                        </div>
                                    </div>
                                 </div>
                                 <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <button type="button" class="btn pbtn m-l-5 m-l-5" (click)="generateReport()" *ngIf="isGenerateShow"> Generate</button>
                        <button type="button" class="btn pbtn m-l-5 mr-2" (click)="submit()" *ngIf="isSubmitShow"> Save</button>
                        <button type="button" class="btn pbtn mr-2" (click)="openPDF()" *ngIf="isPdfShow">Export PDF</button>
                     </h3>
                  </div> -->
                  <div class="col-md-12 clearfix">
                     <div class="row">
                        <div class="col-md-12">
                           <div class="AnalysisText">
                              <h5>Name of Analysis </h5>
                              <!-- <button class="SaveReport" type="button" class="btn Upgradebtn">{{AnysisName}}
                              </button> -->
                              <input type="text" placeholder="Add your report name" class=" Upgradebtn form-control" formControlName="analysis_name" [ngClass]="{'has-error': submitted && (queControl.analysis_name.hasError('required'))}" >
                           </div>
                        </div>
                        </div>
                     <input type="hidden" formControlName="tools_id">
                     <table class="table table-striped table-bordered pro-tbl table-responsive  ">
                        <thead>
                           <tr>
                              <th>Benefit of Pursuing the Opportunity</th>
                              <th style="text-align:center">Probability of Occurrence</th>
                              <th style="text-align:center">Impact on Project</th>
                              <th style="text-align:center">Total Score</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td></td>
                              <td>
                                 <div class="mn-divpro">
                                    <span>HiGH</span>
                                    <span>MEDIUM</span>
                                    <span>LOW</span>
                                 </div>
                              </td>
                              <td>
                                 <div class="mn-divpro">
                                    <span>HiGH</span>
                                    <span>MEDIUM</span>
                                    <span>LOW</span>
                                 </div>
                              </td>
                              <td></td>
                           </tr>
                           <tr *ngFor="let number of question| slice:0:7;let i = index;">
                              <td>
                                 {{number.question_title}}
                                 <!-- <input type="hidden" formControlName="question_{{i+1}}_qustion" [value]="questionList[i]"> -->
                              </td>
                              <td>
                                 <div class="rd-pro">
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+1}}_profebility_score" value="10" (change)="radioChange($event,'opp')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+1}}_profebility_score" value="5" (change)="radioChange($event,'opp')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+1}}_profebility_score" value="2" (change)="radioChange($event,'opp')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="rd-pro">
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+1}}_impact_score" value="10" (change)="radio2Change($event,'opp')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+1}}_impact_score" value="5" (change)="radio2Change($event,'opp')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+1}}_impact_score" value="2" (change)="radio2Change($event,'opp')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <!-- <p class="pnk-p"> -->
                                 <input type="text" class="form-control" formControlName="question_{{i+1}}_total" [readonly]="true">
                                 <!-- </p> -->
                              </td>
                           </tr>
                           <tr>
                              <td>
                                 <b>Scores of prabability and impact</b>
                              </td>
                              <td>
                                 <p class="pnk-p">{{opp_pro_total}}</p>
                                 <input class="" type="hidden" formControlName="opportunity_profibility_score" value="0">
                              </td>
                              <td>
                                 <p class="pnk-p">{{opp_imp_total}}</p>
                                 <input class="" type="hidden" formControlName="opportunity_impact_score" value="0">
                              </td>
                              <td></td>
                           </tr>
                           <tr>
                              <td colspan="3" align="right">
                                 <b class="ttl-scre">Total Score Of Benefits</b>
                              </td>
                              <td>
                                 <div class="btn-green">
                                    {{opp_final_total}}
                                 </div>
                              </td>
                           </tr>
                           <!-- <tr>
                              <td>Customer prefers our organization</td>
                              <td>
                              <div class="rd-pro">
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                              </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Expands organization’s experience base</td>
                              <td>
                              <div class="rd-pro">
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                              </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Improves organization’s market share</td>
                              <td>
                              <div class="rd-pro">
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                              </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Uses organization’s underutilized resources</td>
                              <td>
                              <div class="rd-pro">
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                              </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Customer will serve as a reference site</td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Potential for additional business</td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>
                                  <b>Scores of prabability and impact</b>
                              </td>
                              <td>
                                  <p class="pnk-p">40</p>
                              </td>
                              <td>
                                  <p class="pnk-p">40</p>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td colspan="3" align="right">
                              <b class="ttl-scre">Total Score Of Benefits</b>
                              </td>
                              <td>
                              <div class="btn-green">
                                  80
                              </div>
                              </td>
                              </tr> -->
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div class="proej-char-tbl">
               <div class="row">
                  <div class="pro-head col-md-12">
                     <h3>
                        Risk Analysis
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                     </h3>
                  </div>
                  <div class="col-md-12 clearfix">
                     <table class="table table-striped table-bordered pro-tbl table-responsive top-border">
                        <thead>
                           <tr>
                              <th>Risk-Cost, Schedule, or Performance problems in these areas</th>
                              <th style="text-align:center">Probability of Occurrence</th>
                              <th style="text-align:center">Impact on Project</th>
                              <th style="text-align:center">Total Score</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td></td>
                              <td>
                                 <div class="mn-divpro">
                                    <span>HiGH</span>
                                    <span>MEDIUM</span>
                                    <span>LOW</span>
                                 </div>
                              </td>
                              <td>
                                 <div class="mn-divpro">
                                    <span>HiGH</span>
                                    <span>MEDIUM</span>
                                    <span>LOW</span>
                                 </div>
                              </td>
                              <td></td>
                           </tr>
                           <tr *ngFor="let number of question|slice:7:13;let i = index;">
                              <td>{{number.question_title}}
                                 <input type="hidden" formControlName="question_{{i+8}}_qustion" value="questionList[number-1]">
                              </td>
                              <td>
                                 <div class="rd-pro">
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+8}}_profebility_score" value="10"
                                          (change)="radioChange($event, 'risk')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+8}}_profebility_score" value="5"
                                          (change)="radioChange($event, 'risk')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+8}}_profebility_score" value="2"
                                          (change)="radioChange($event, 'risk')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                 </div>
                              </td>
                              <td>
                                 <div class="rd-pro">
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+8}}_impact_score" value="10"
                                          (change)="radio2Change($event, 'risk')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+8}}_impact_score" value="5"
                                          (change)="radio2Change($event, 'risk')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                    <div class="form-check">
                                       <input class="form-check-input" type="radio" formControlName="question_{{i+8}}_impact_score" value="2"
                                          (change)="radio2Change($event, 'risk')">
                                       <label class="form-check-label" for="flexRadioDefault1">
                                       </label>
                                    </div>
                                 </div>
                              </td>
                              <td><input type="text" class="form-control" formControlName="question_{{i+8}}_total" [readonly]="true"></td>
                           </tr>
                           <!-- <tr>
                              <td>Customer readiness to undertake this project</td>
                              <td>
                                <div class="rd-pro">
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                   <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                   <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                               </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                       <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                       <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                   </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Changing requirements</td>
                              <td>
                                <div class="rd-pro">
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                   <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                   <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                               </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                       <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                       <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                   </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Customer financial issues</td>
                              <td>
                                <div class="rd-pro">
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                   <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                   <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                               </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                       <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                       <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                   </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Availability of resources</td>
                              <td>
                                <div class="rd-pro">
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                   <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                                   <div class="form-check">
                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                      <label class="form-check-label" for="flexRadioDefault1">
                                      </label>
                                  </div>
                               </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                       <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                       <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                   </div>
                              </td>
                              <td></td>
                              </tr>
                              <tr>
                              <td>Resource skill level or certification status</td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td>
                                  <div class="rd-pro">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                          <label class="form-check-label" for="flexRadioDefault1">
                                          </label>
                                      </div>
                                  </div>
                              </td>
                              <td></td>
                              </tr> -->
                           <tr>
                              <td>
                                 <b>Scores of prabability and impact</b>
                              </td>
                              <td>
                                 <p class="pnk-p">{{risk_pro_total}}</p>
                              </td>
                              <td>
                                 <p class="pnk-p">{{risk_imp_total}}</p>
                              </td>
                              <td></td>
                           </tr>
                           <tr>
                              <td colspan="3" align="right">
                                 <b class="ttl-scre">Total Score Of Risk</b>
                              </td>
                              <td>
                                 <div class="btn-yellow">
                                    {{risk_final_total}}
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </form>
         <!-- *ngIf="isSubmitShow" -->
         <div class="risk-befits mb-5">
            <div class="row">
               <div class="col-md-12">
                  <div class="versis-bnf table-bordered top-border">
                     <div class="pro-head col-md-12">
                        <h3 class="mb-5">
                           Risk Versus Benefit Decision Scale
                        </h3>
                        <div class="Scale__inner-wrap">
                           <div class="Scale__grid">
                              <div class="Scale__grid-left">
                                 <p class="Benefit">Benefit Score</p>
                                 <span class="Scale__grid-col">
                                    <p class="count1">100%</p>
                                    <p class="count2">0%</p>
                                 </span>
                                 <span class="Scale__items-col arrow-1" [ngStyle]="{'top': 'calc(100% - '+opp_final_total_percent+'%)'  }" data-point="40"> <span class="opp_final_total"><p>{{opp_final_total_percent}}%</p></span> <img src="./assets/assets/images/benefit-arrow.png" alt="Lucid" class="img-fluid"></span>
                              </div>
                              <div class="Scale__spacer">
                                 <div class="Scale__spacer-item">
                                    <div class="box Green-section-name">
                                       <h6>High Benefit/Low Risk</h6>
                                       <p>Go for It!</p>
                                    </div>
                                    <div class="box Yellow-section-name">
                                       <h6>Medium Benefit/Medium Risk</h6>
                                       <p>Further Studies Required</p>
                                    </div>
                                    <div class="box Red-section-name">
                                       <h6>Low Benefit/High Risk</h6>
                                       <p>Avoid this Opportunity</p>
                                    </div>
                                 </div>
                              </div>
                              <div class="Scale__grid-right">
                                 <p class="Risk">Risk Score</p>
                                 <span class="Scale__grid-col">
                                    <p class="count1">0%</p>
                                    <p class="count2">100%</p>
                                 </span>
                                 <span class="Scale__items-col arrow-1"  [ngStyle]="{'top': 'calc('+risk_final_total_percent+'%)'  }" data-point="1">  <span class="risk_final_total"><p>{{risk_final_total_percent}}%</p></span> <img src="./assets/assets/images/risk-arrow.png" alt="Lucid" class="img-fluid"></span>
                              </div>
                           </div>
                           <!-- <div class="Scale__items">
                              <div class="Scale__items-left">
                                 <span class="Scale__items-col arrow-1" [ngStyle]="{'margin-top': 'calc(100% - '+opp_final_total+'%)'  }" data-point="40"> <span class="opp_final_total">{{opp_final_total}}</span> <img src="./assets/assets/images/benefit-arrow.png" alt="Lucid" class="img-fluid"></span>
                              </div>
                              <div class="Scale__spacer"></div>
                              <div class="Scale__items-right">
                                 <span class="Scale__items-col arrow-1"  [ngStyle]="{'margin-top': 'calc('+risk_final_total+'%)'  }" data-point="1">  <span class="risk_final_total">{{risk_final_total}}</span> <img src="./assets/assets/images/risk-arrow.png" alt="Lucid" class="img-fluid"></span>
                              </div>
                           </div> -->
                        </div>
                        <!-- <div class="row">
                           <div class="col-md-6">
                              <div echarts [options]="chartOption" class="demo-chart"></div>
                           </div>
                           <div class="col-md-6">
                              <div echarts [options]="chartOption2" class="demo-chart2"></div>
                           </div>
                        </div> -->
                        <!--<div class="medim-which text-center">
                           <span>Your Decision Score is</span>
                           <button class="btn">Medium which means Benefits/Medium risk</button>
                           </div>-->
                        <!-- <div class="further-part text-center mt-3">
                           <a href="">Further Studies Required</a>
                           </div> -->
                        <!-- <div>Opportunity Score : {{opp_final_total}}</div>
                        <div>Risk Score : {{risk_final_total}}</div> -->
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</div>