<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
   <div class="container-fluid">
      <div class="block-header top-head-vt">
         <div class="row">
            <div class="col-lg-5 col-md-8 col-sm-12">
               <!-- <h2>Force Field Analysis
                  </h2> -->
               <ul class="breadcrumb vt-a">
                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                  <li class="breadcrumb-item"><a routerLink="/dashboard">Upgrade/ Renew Subscriptions</a></li>
               </ul>
            </div>
         </div>
      </div>
      <!-- Warnning massage -->
      <div class="Warnning Iconwarning-box mb-3" *ngIf="CurrentPlanID!=0">
         <div class="row">
            <div class="col-md-9">
             <div class="info-Iconwarning ">
                <img src="./assets/assets/images/renew-icon.png" alt="renew-icon">
               </div>
                <div class="Iconwarning-box-content">
                  <p>Your {{CurrentPlan.plan.duration}}{{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Upgrade your subscription plan to keep enjoying the benefits of all features by VT-Touch.
                  </p>
             </div>
            </div>
            <div class="col-md-3">
               <div class="Iconwarning-box-content">
               <button class="text-Iconwarning btn" (click)="OpenPlanPopup(PlanModal)">Upgrade Your Subscription</button>
               </div>
            </div>
         <!-- /.info-box-content -->
      </div>
      </div>

                 
      <!-- Active subscriptions -->
      <div class="Upgradesubscriptions-card mb-4 " id="step1" >
         <div class="Billing-card-title">
            <h5 class="card-title-text">Active subscriptions</h5>
            <!-- <div class="pull-right EditIcon">
               <a href="javascript:" (click)="StepOpen('step1')" class="text-center btn" role="button" >
               <i class="fa fa-pencil" aria-hidden="true"></i>
               </a>
            </div> -->
         </div>
         <div class="subscriptions-card-content collapse" [ngClass]="isActivePlanShow ? 'show' : ''">
            <div class="subscriptions-body" *ngIf="(isActivePlanShow)">
               <div class="ACTIVE-title">
                  <h4>ACTIVE SUBSCRIPTIONS</h4>
               </div>
               <div class="PaymentCart" *ngIf="planDetail">
                  <div class="no-gutters row">
                     <div class="col-sm-6 col-md-4 col-xl-4">
                        <div class="billing-content">
                           <!-- <p>Next billing date - {{nextBillingDate}}</p> -->
                           <p>Next billing date - {{nextBillingDate | date :'d MMMM,  y'}}</p>
                           <!-- <p>Next billing date - {{nextBillingDate | date :'d MMMM,  y'}} 4 October, 2021</p> -->
                           <p>Price - £{{planDetail.price}}</p>
                        </div>
                     </div>
                     <div class="col-sm-6 col-md-4 col-xl-4">
                        <div class="Free-Trail">
                           <img src="./assets/assets/images/vttouchlg1.png" alt="PinkLogo">
                           <button class="text-payment btn current-plan" *ngIf="CurrentPlanID!=0">{{CurrentPlan.plan.plan_name}}</button>
                           <button class="text-payment btn ">{{planDetail.plan_name}}</button>
                        </div>
                     </div>
                     <form *ngIf="formReady" [formGroup]="subscriptionSettingsForm" enctype="multipart/form-data"  class="subscriptionSettingsForm" >
                     <div class="col-sm-12 col-md-4 col-xl-4">
                        <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Auto Renewal</p>
                           </div>
                           <div class="Total-content-right">
                              <label class="switch">
                              <input type="checkbox" formControlName="auto_renewal">
                              <span class="slider"></span>
                              </label> 
                           </div>
                        </div>
                        <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Send Reminders</p>
                           </div>
                           <div class="Total-content-right">
                              <label class="switch">
                              <input type="checkbox" formControlName="send_reminder">
                              <span class="slider"></span>
                              </label> 
                           </div>
                        </div>
                     </div>
                     <div class=" Membership-Plan">
                        <!-- <div class=" dropdown show">
                           <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn-shadow dropdown-toggle btn">
                           <span class="btn-icon-wrapper  opacity-7">
                           <i class="fa fa-business-time fa-w-20"></i>
                           </span>
                           Change Membership Plan 
                           </button>
                           <div role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                              <ul class="nav flex-column">
                                 <li class="nav-item">
                                    <a class="nav-link">
                                    <span> Dummy</span>
                                    </a>
                                 </li>
                                 <li class="nav-item">
                                    <a class="nav-link">
                                    <span> Dummy</span>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div> -->
                        <div class="MoreInformation">
                           <!-- <p > For More Information Please <a href="javascript:" (click)="OpenPlanPopup(PlanModal)" >our membership plan </a> </p> -->
                       <p>For More Information Please Visit  <a [routerLink]="['/OurPlan']" > our Membership Subscriptions </a> </p>
                        </div> 
                     </div>
                     <div class="col-md-12">
                        <div class="text-right d-block subscriptions-card-footer">
                           <!-- <button type="submit" class="mr-2 text-Save btn " (click)="isActivePlanShowBtn()">Continue</button> -->
                           <button class="btn btn-danger cansl-vtbt" type="button" data-toggle="collapse"
                                       data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                                       (click)="NextStep('step1')">
                                       Continue
                                 </button>
                        </div>
                     </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- Billing address -->
      <div class="Upgradesubscriptions-card mb-4" id="step2">
         <div class="Billing-card-title">
            <h5 class="card-title-text" >Billing address</h5>
            <!-- <div class="pull-right EditIcon">
               <a href="javascript:" (click)="StepOpen('step2')" class="text-center btn" role="button" >
               <i class="fa fa-pencil" aria-hidden="true"></i>
               </a>
            </div> -->
         </div>
         <div class="subscriptions-card-content collapse" [ngClass]="BillingAddressStep ? 'show' : ''">
            <div class="subscriptions-body" *ngIf="(BillingAddressStep)">

           
               <div class="AddBilling-title">
                  <h4>Add your billing address</h4>
                  <!-- <div class="pull-right CloseIcon">
                     <a href="javascript:" class="text-center btn" role="button" >
                     <i class="fa fa-close"></i>
                     </a>
                  </div> -->
               </div>
               <form  [formGroup]="billingAddressForm" enctype="multipart/form-data"  class="BillingAddress" >
                  <div class="row">
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="label" for="name">Name*</label>
                           <input type="text" class="form-control" id="name" formControlName="user_name" [ngClass]="{ 'is-invalid': submitted && f.user_name.errors }" (keypress)="keyPressCharacters($event)">
                           <div *ngIf="billingAddressForm.controls['user_name'].invalid && (billingAddressForm.controls['user_name'].dirty || billingAddressForm.controls['user_name'].touched)" class="alert">
                              <div *ngIf="billingAddressForm.controls['user_name'].errors.required" class="text-danger">
                                 Please enter name
                              </div>
                              <div *ngIf="billingAddressForm.controls['user_name'].errors.pattern" class="text-danger">
                                 Please enter only characters
                              </div>
                          </div>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="label" for="Company">House Number/Name*</label>
                           <input type="text" class="form-control" formControlName="company_name" id="Company" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="label" for="Address1">Address Line 1*</label>
                           <input type="text" class="form-control" formControlName="address_1" id="Address1" [ngClass]="{ 'is-invalid': submitted && f.address_1.errors }">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="label" for="Address2">Address Line 2*</label>
                           <input type="text" class="form-control" formControlName="address_2" id="Address2" [ngClass]="{ 'is-invalid': submitted && f.address_2.errors }">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="label" for="City">City*</label>
                           <input type="text" class="form-control" formControlName="city" id="City" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="label" for="Postal">Postal Code*</label>
                           <input type="text" class="form-control" formControlName="postal_code" id="Postal" [ngClass]="{ 'is-invalid': submitted && f.postal_code.errors }">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="label" for="State">State/County*</label>
                           <input type="text" class="form-control" formControlName="state" id="State" [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <label class="label" for="Country">Country*</label>
                           <input type="text" class="form-control" formControlName="country" id="Country"  [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                        </div>
                     </div>
                   <div class="col-md-12" *ngIf="planID > 1">
                        <div class="text-right d-block subscriptions-card-footer">
                           <button class="text-Next btn" (click)="BackButton('step2')">Back</button> &nbsp;
                           <button type="button" class="mr-2 text-Save btn " (click)="submitBillingAddress()">Save And Next</button>
                        </div>
                     </div>
                     <div class="col-md-12" *ngIf="planID == 1">
                        <div class="text-right d-block subscriptions-card-footer">
                           <button class="text-Next btn" (click)="BackButton('step2')">Back</button> &nbsp;
                           <button type="button" class="mr-2 text-Save btn " (click)="submitBillingAddressAndPurchase()">Save</button>
                        </div>
                     </div>
                  </div>
               </form>
               </div>
            </div>
      </div>
      <!-- payment Details -->
      <!-- <div class="Upgradesubscriptions-card mb-4" id="step3">
         <div class="Billing-card-title">
            <h5 class="card-title-text">Payment Details</h5>
            <div class="pull-right EditIcon">
               <a href="javascript:" (click)="StepOpen('step3')" class="text-center btn" role="button" >
               <i class="fa fa-pencil" aria-hidden="true"></i>
               </a>
            </div>
         </div>
         <div class="subscriptions-card-content collapse" [ngClass]="AddPaymentDetailStep ? 'show' : ''">
            <div class="card card-body" *ngIf="(AddPaymentDetailStep)">
               <div class="AddBilling-title">
                  <h4>Add/Select your card details</h4>
                  <div class="pull-right CloseIcon">
                     <a href="javascript:" class="text-center btn" role="button" >
                     <i class="fa fa-close"></i>
                     </a>
                  </div>
               </div>
               <div class="PaymentCart">
                  <img src="./assets/assets/images/PaymentCart.svg" alt="PaymentCart">
                  <div class="text-center payment-details">
                     <button class="text-payment btn " (click)="NextStep('step3')">Add payment  here</button>
                     <p>We accept all major credit/debit cards and PayPal</p>
                  </div>
               </div>
               <div class="col-md-12">
                  <div class="text-right d-block subscriptions-card-footer">
                     <button class="text-Next btn" (click)="BackButton('step3')">Back</button>&nbsp;
                     <button type="submit" class="mr-2 text-Save btn " (click)="NextStep('step3')">Next</button>
                  </div>
               </div>
            </div>
         </div>
      </div> -->
      <!-- Select your payment method -->
      <div class="Upgradesubscriptions-card mb-4" id="step4" *ngIf="planID > 1">
         <div class="Billing-card-title">
            <h5 class="card-title-text">Payment Details</h5>
            <!-- <div class="pull-right EditIcon">
               <a href="javascript:" (click)="StepOpen('step4')" class="text-center btn" role="button" >
               <i class="fa fa-pencil" aria-hidden="true"></i>
               </a>
            </div> -->
         </div>
         <!-- <div class="payment-card-content">
              <div class="AddBilling-title">
                  <h4>Select your payment method</h4>
               <div class="pull-right CloseIcon">
                  <a href="javascript:" class="text-center btn" role="button" >
                  <i class="fa fa-close"></i>
                  </a>
               </div>
            </div>
         </div> -->
            <div class="PaymentCart collapse" [ngClass]="AddCardDetailStep ? 'show' : ''">
               <div class="PaymentCart-body" *ngIf="(AddCardDetailStep)">
                  <div class="no-gutters row">
                     <div class="col-md-6 col-lg-6 col-xl-6 leftCard">
                        <!-- <div class="Total-content-wrapper">
                           <div class="Total-content-right">
                              <label class="switch">
                                 <input type="checkbox">
                                 <span class="slider"></span>
                                 </label> 
                              
                              <div class="Cart-Plan">
                                 <div class="dropdown show">
                                    <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn-shadow dropdown-toggle btn">
                                    <span class="btn-icon-wrapper  opacity-7">
                                    <i class="fa fa-business-time fa-w-20"></i>
                                    </span>
                                    Credit Card
                                    </button>
                                    <div role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                                       <ul class="nav flex-column">
                                          <li class="nav-item">
                                             <a class="nav-link">
                                             <span> Dummy</span>
                                             </a>
                                          </li>
                                          <li class="nav-item">
                                             <a class="nav-link">
                                             <span> Dummy</span>
                                             </a>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              
                              </div>
                           </div>
                           
                        </div> -->
                        <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Bill estimate date</p>
                           </div>
                           <div class="Total-content-right">
                           <!-- <p>{{todayDate | date :'d MMMM,  y'}} - {{nextBillingDate | date :'d MMMM,  y'}}</p> -->
                           <p>{{todayDate | date :'d MMMM,  y'}} - {{nextBillingDate | date :'d MMMM,  y'}}</p>
                           <!-- <p>4 May 2021 - 4 Jun 2023</p> -->
                           </div>
                        </div>
                        <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Order summary</p>
                           </div>
                           <div class="Total-content-right">        
                              <p>£{{planDetail.price}}</p>
                           </div>
                        </div>
                        <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Membership</p>
                            
                           </div>
                           <div class="Total-content-right">
                              <p>  <a href="javascript:;">({{planDetail.plan_name}})</a></p>
                           </div>
                        </div>
                        <hr>
                        <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Taxes</p>
                           </div>
                           <div class="Total-content-right">
                           <p>£0.00</p>
                           </div>
                        </div>
                        <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Total Amount (including tax)</p>
                           </div>
                           <div class="Total-content-right">
                           <p>£{{planDetail.price}}</p>
                           </div>
                        </div>
                        <!-- <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Auto Renewal</p>
                           </div>
                           <div class="Total-content-right">
                              <label class="switch">
                              <input type="checkbox">
                              <span class="slider"></span>
                              </label> 
                           </div>
                        </div>
                        <div class="Total-content-wrapper">
                           <div class="Total-content-left">
                              <p>Send Reminders</p>
                           </div>
                           <div class="Total-content-right">
                              <label class="switch">
                              <input type="checkbox">
                              <span class="slider"></span>
                              </label> 
                           </div>
                        </div> -->
                     </div>

                   
                     <div class="col-md-6 col-lg-6 col-xl-6 RightCard">
                        <div class="payment-info">
                           <ul class="nav nav-tabs">
                              <li class="nav-item"><a class="nav-link" [ngClass]="existCards ? 'show' : ''" [ngClass]="existCards ? 'active' : ''"  data-toggle="tab" href="#CurrentCard">Current card</a></li>
                              <li class="nav-item"><a class="nav-link" [ngClass]="!existCards ? 'show' : ''" [ngClass]="!existCards ? 'active' : ''" data-toggle="tab" href="#AddCard">Add new card</a></li>
                           </ul>
                           <div class="tab-content">
                              <div class="tab-pane " [ngClass]="existCards ? 'show' : ''" [ngClass]="existCards ? 'active' : ''" id="CurrentCard">
                                 <form [formGroup]="userExistCardForm" enctype="multipart/form-data" class="cc-form" (ngSubmit)="onSubmitUserExistingCard()" novalidate>
                                    <div class="row justify-content-center">
                                       <div class="col-md-6 pb-2" *ngFor="let cardlist of UserCardList; let i=index;">
                                    <label class="radio" >
                                       <input type="radio" formControlName="card_id" class="form-check-input" value="{{cardlist.card_id}}" *ngIf="cardlist.is_default===1" checked> 
                                       <input type="radio" formControlName="card_id" class="form-check-input" value="{{cardlist.card_id}}" *ngIf="cardlist.is_default===0"> 
                                       <span>
                                          Brand - {{cardlist.card_brand}}
                                       <br>
                                          Card No - {{cardlist.card_number}}
                                       </span> 
                                    </label>
                                    </div>
                                    </div>
                                    <div *ngIf="!existCards">
                                       <h6>You don't have any saved card </h6>
                                    </div>
                               
                                    <div class="text-center d-block card_footer">
                                       <button type="submit" class="mr-3 text-Make btn " *ngIf="existCards">Make Payment</button>
                                       <button class="text-Cancel btn " (click)="BackButton('step3')">Cancel</button>
                                    </div>
                                 </form>
                                 <!-- <div class="row justify-content-center">
			                           <div class="col-md-6 pb-2">
                                       <label class="radio"> <input type="radio" name="card" value="payment"><span>
                                          Brand - Visa<br>
                                          Card No - 4242 4242 4242 4242
                                       </span>
                                     </label>
                                       
                                       </div>
                                          <div class="col-md-6 pb-3">
                                             <label class="radio"> <input type="radio" name="card" value="payment" checked> 
                                                <span>
                                                   Brand - Visa<br>
                                                   Card No - 4242 4242 4242 4242
                                                </span>
                                                </label>
                                          </div>
                                    </div> -->
                                </div>
                              <div class="tab-pane" id="AddCard" [ngClass]="!existCards ? 'show' : ''" [ngClass]="!existCards ? 'active' : ''">
                                 <form [formGroup]="userCardForm" enctype="multipart/form-data" class="cc-form" (ngSubmit)="onSubmitUserCard()" novalidate>
                                    <div class="InputArea">
                                       <label class="credit-card-label">Name of card holder</label>
                                       <input type="text" class="form-control credit-inputs" formControlName="card_holder" placeholder="Name" [ngClass]="{ 'is-invalid': submitted && uc.card_holder.errors }">
                                       <div *ngIf="userCardForm.controls['card_holder'].invalid && (userCardForm.controls['card_holder'].dirty || userCardForm.controls['card_holder'].touched)" class="alert">
                                          <div *ngIf="userCardForm.controls['card_holder'].errors.required" class="text-danger">
                                             Please enter card holder name
                                          </div>
                                          <div *ngIf="userCardForm.controls['card_holder'].errors.pattern" class="text-danger">
                                             Please enter only characters
                                          </div>
                                      </div>
                                    </div>
                                    <div class="InputArea">
                                       <label class="credit-card-label">Credit card number</label>
                                       <input type="tel" formControlName="card_number" placeholder="0000 0000 0000 0000"
                                 autocomplete="cc-number" id="cc-number" class="form-control credit-inputs" type="tel" ccNumber [ngClass]="{ 'is-invalid': submitted && uc.card_number.errors }" maxlength="19" />
                                       <div *ngIf="userCardForm.controls['card_number'].invalid && (userCardForm.controls['card_number'].dirty || userCardForm.controls['card_number'].touched)" class="alert">
                                          <div *ngIf="userCardForm.controls['card_number'].errors" class="text-danger">
                                             Please enter valid card number
                                          </div>
                                          
                                       </div>
                                    </div>
                                    <div class="row InputArea">
                                       <div class="col-md-6">
                                          <label class="credit-card-label">Expiration</label>
                                          <input type="tel" class="form-control credit-inputs" formControlName="expire_date" placeholder="MM/YY" [ngClass]="{ 'is-invalid': submitted && uc.expire_date.errors }" autocomplete="cc-exp" ccExp>
                                          <!-- <input id="cc-exp-date" formControlName="expirationDate" type="tel" autocomplete="cc-exp" ccExp> -->
                                          <div *ngIf="userCardForm.controls['expire_date'].invalid && (userCardForm.controls['expire_date'].dirty || userCardForm.controls['expire_date'].touched)" class="alert">
                                             <div *ngIf="userCardForm.controls['expire_date'].errors" class="text-danger">
                                                Invalid Expiry Date
                                             </div>
                                             
                                       </div>
                                       </div>
                                       <div class="col-md-6">
                                          <label class="credit-card-label">CVV</label>
                                          <input type="password" class="form-control credit-inputs" formControlName="cvv" placeholder="***" [ngClass]="{ 'is-invalid': submitted && uc.cvv.errors }" minlength="3" maxlength="4" (keypress)="keyPressNumbers($event)">
                                       </div>
                                    </div>
                                    <div class="text-center d-block card_footer">
                                       <!-- <button class="text-Next btn" (click)="BackButton('step4')">Back</button> -->
                                       <button class="mr-3 text-Make btn ">Make Payment</button>
                                       <button class="text-Cancel btn "  (click)="BackButton('step4')">Cancel</button>
                                    </div>
                                    
                                 </form>
                              </div>
                           </div>
                     
                        </div>
                     </div>
               </div>
               </div>
         </div>
      </div>
      <ng-template #PlanModal let-modal>
         <div class="modal-header">
             <h4 class="modal-title" id="myModalLabel">Membership Subscription Plans</h4>
             <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
             <div class="Iconwarning-box text-center mb-4" *ngIf="CurrentPlan.length">
                <span class="info-Iconwarning ">  <img src="./assets/assets/images/renew-icon.png" alt="renew-icon"></span>
                <div class="Iconwarning-box-content" >
                   <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
                      enjoying the benefits of all features by VTTouch. 
                   </p>
                </div>
             </div>
             <div class="row justify-content-center">
                <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                   <div class="single-price-plan mb-100">
                      <div class="price-plan-title">
                         <h4>{{singlePlan.plan_name}}
                         </h4>
                      </div>
                      <div class="price-plan-value">
                         <h2>£{{singlePlan.price}}</h2>
                         <p>{{singlePlan.duration}} {{singlePlan.duration_type}}</p>
                      </div>
                        <a href="javascript:;" class="Buttons-pink c" *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Start Trial</a>
                        <a href="#" class="Buttons-pink " *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Continue</a>
                   </div>
                </div>
                
                
             </div>
          </div>
     </ng-template>
     <ng-template #SubscriptionSuccessModal let-modal>
      <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Subscription Success</h4>
       </div>
       <div class="modal-body">
          <div class="Iconwarning-box mb-4" *ngIf="planDetail">
             <span class="info-Iconwarning ">  <img src="./assets/assets/images/sucessfully.png" alt="PinkLogo"></span>
             <div class="Iconwarning-box-content" >
                <h6>You have successfully subscribed {{planDetail.duration}} {{planDetail.duration_type}} ({{planDetail.plan_name}}) membership.</h6>
                <button type="button" Class="btn pbtn" aria-label="pbtn" (click)="modal.dismiss('Cross click')">
                     CLOSE
                </button>
             </div>
          </div>
       </div>
  </ng-template>
   </div>
</div>