import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

   transform(items: any[], keyword: any, properties: string[]): any[] {
    if (!items) return [];
    if (!keyword) return items;
    
    //debugger;
    if(keyword.length>3){
      return items.filter(item => {
        var itemFound: Boolean;
        for (let i = 0; i < properties.length; i++) {
          if(item[properties[i]]!=null){
            if (item[properties[i]].toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
              itemFound = true;
              break;
            }
          }
        }
        return itemFound;
      });
    }else{
      return items;
    }

  }

}
