import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import{ GlobalConstants } from '../../common/global-constants';
declare var $: any;

@Component({
  selector: 'app-second-sidebar',
  templateUrl: './second-sidebar.component.html',
  styleUrls: ['./second-sidebar.component.css']
})
export class SecondSidebarComponent implements OnInit {

  userDisplayName = '';
  userProfile = '';
  userId = '';
  userRole:any = null;
  constructor(private authService: AuthService,  private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    if(this.authService.isLogedin) {
      if(this.authService.user){
        
        this.userDisplayName = this.authService.user.name;
        if(this.authService.user.profile_pic){
          //let path = GlobalConstants.ImagePATH+'/'+'/uploads/users/photo/';
          //this.imageURL = path+this.authService.profile_pic;
          let path =  GlobalConstants.user_profile_path;
          this.userProfile = path+this.authService.user.profile_pic;
        }else{
          this.userProfile = "./assets/assets/images/user.png";
        }
        this.userId = this.authService.user.id;
        this.userRole = this.authService.user.user_role;
      }
    }

    $(".btn-toggle-fullwidth").on("click", function () {
      $("body").hasClass("layout-fullwidth") ? $("body").removeClass("layout-fullwidth") : $("body").addClass("layout-fullwidth"), $(this).find(".fa").toggleClass("fa-arrow-left fa-arrow-right")
    })
  }

  logout() {
    this.authService.logout();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.toastr.success('Logout successfully', "Success");
    this.router.navigateByUrl('login');
  }

  updateUser(){
    this.router.navigate(['/edit-profile', this.userId]);
  }

  changePassword(){
    //this.router.navigateByUrl('change-password');
    this.router.navigateByUrl('account-settings');
  }

  profile(){
    this.router.navigateByUrl('profile');
  }

  goToPage(page_name){
      this.router.navigateByUrl(page_name);
  }

}
