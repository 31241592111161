import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-our-plan',
  templateUrl: './our-plan.component.html',
  styleUrls: ['./our-plan.component.css']
})
export class OurPlanComponent implements OnInit {
  @ViewChild("SubscribePlanModal") modalContent: TemplateRef<any>;
  
  planData:any;
  CurrentPlanID=0;
  closeResult: string;

  constructor( private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location, private modalService: NgbModal) { }
  ngOnInit(): void {
    this.authService.getAllPlanList({}).subscribe((res:any)=>{
      if(res.status) {
        this.planData = res.plans;
        console.log(this.planData)
      }
    })
    this.CurrentPlan()
  }
  CurrentPlan(){
    this.authService.getUserActivePlan().subscribe((res:any)=>{
      if(res.status) {
        console.log(res,'current plan ')
          this.CurrentPlan = res.current_active_plan
          this.CurrentPlanID=res.current_active_plan.plan_id
          // this.router.navigateByUrl('dashboard');
      } else {
        this.router.navigateByUrl('OurPlan');
        this.modalService.open(this.modalContent, { size: 'md' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
        //alert(res.message);
      }

    }, err=> {
      this.router.navigateByUrl('OurPlan');
      this.modalService.open(this.modalContent, { size: 'md' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    //this.commentedPostId = 0;
  }

}
