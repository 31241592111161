import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { LearningService } from 'src/app/services/learning.service';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import{ GlobalConstants } from '../../../../common/global-constants';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-learning-add',
  templateUrl: './learning-add.component.html',
  styleUrls: ['./learning-add.component.css']
})
export class LearningAddComponent implements OnInit {

  CreateForm: FormGroup;
  categories:any = [];
  isAddMode: boolean;
  id: string;
  changeIcon:boolean=false;
  changePDF:boolean=false;
  changeSamplePDF:boolean=false;
  changeHoverIcon:boolean=false;

  constructor(private formBuilder: FormBuilder, 
    private learnService: LearningService, 
    private router: Router, private route: ActivatedRoute, 
    private toastr: ToastrService,
    private _location: Location) { 
      this.CreateForm = this.formBuilder.group({
        id:[],
        title: ['', [Validators.required]],
        description: ['', [Validators.required]],
        category_id: ['', [Validators.required]],
        attachment: ['', [Validators.required]],
        sample_pdf: ['', [Validators.required]],
        icon: ['', [Validators.required]],
        hover_icon: ['', [Validators.required]],
      })
    }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    console.log( this.id,'id url -----------')
    this.isAddMode = !this.id;

    this.learnService.getAllCategory().subscribe((res: any)=>{
      if(res.status){
        this.categories = res.data;
      }else{
        this.toastr.error(res.message, "Error");
      }
    },err=>{
      this.toastr.error(err, "Error");
    });

    
    if (!this.isAddMode) {  
      this.learnService.getSingleLearning({'id':this.id}).subscribe((res:any)=>{
        if(res.status) {
          this.CreateForm = this.formBuilder.group({
            id:[],
            title: ['', [Validators.required]],
            description: ['', [Validators.required]],
            category_id: ['', [Validators.required]],
            attachment: [''],
            sample_pdf: [''],
            icon: [''],
            hover_icon: [''],
          })
          this.CreateForm.patchValue(res.data)
        } else {
          this.toastr.error("Blog Room not found", "Error");
        }
      })
    }
  }

  get control() {
    return this.CreateForm.controls;
  }

  submit() {
    if(this.CreateForm.invalid) {
      return;
    }
    if (this.isAddMode) {
      console.log('Create')
      this.createLearning();
    } else {
      console.log('update')
        this.updateLearning();
    }

   
  }

  createLearning(){
    const formData = new FormData();
    formData.append('title', this.CreateForm.get('title').value);
    formData.append('description', this.CreateForm.get('description').value);
    formData.append('category_id', this.CreateForm.get('category_id').value);
    formData.append('attachment', this.CreateForm.get('attachment').value);
    formData.append('sample_pdf', this.CreateForm.get('sample_pdf').value);
    formData.append('icon', this.CreateForm.get('icon').value);
    formData.append('hover_icon', this.CreateForm.get('hover_icon').value);
    
    this.learnService.store(formData).subscribe((res:any)=>{
      if(res.status) {
        this.toastr.success('Data add successfully', "Success");
        this.router.navigateByUrl('admin/learning');
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }
  updateLearning(){
    const formData = new FormData();
    formData.append('learning_id', this.CreateForm.get('id').value);
    formData.append('title', this.CreateForm.get('title').value);
    formData.append('description', this.CreateForm.get('description').value);
    formData.append('category_id', this.CreateForm.get('category_id').value);
    if(this.changePDF){
      formData.append('attachment', this.CreateForm.get('attachment').value);
    }
    if(this.changeSamplePDF){
      formData.append('sample_pdf', this.CreateForm.get('sample_pdf').value);
    }
    if(this.changeIcon){
      formData.append('icon', this.CreateForm.get('icon').value);
    }
    if(this.changeHoverIcon){
      formData.append('hover_icon', this.CreateForm.get('hover_icon').value);
    }
    
    this.learnService.update(formData).subscribe((res:any)=>{
      if(res.status) {
        this.toastr.success('Data update successfully', "Success");
        this.router.navigateByUrl('admin/learning');
        this.CreateForm.reset();
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

  isFileAdd = false;
  onFileChange(event, isIcon='') {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileSizeInKB = Math.round(file.size / 1025);
      if (fileSizeInKB >= 20080) {
          this.toastr.error("Allow only 20 mb file size", "Error");
          return;
      }
      if(isIcon=='icon'){
        this.changeIcon=true;
        this.control.icon.setValue(file);
      }else if(isIcon=='hover_icon'){
        this.changeHoverIcon=true;
        this.control.hover_icon.setValue(file);
      }else if(isIcon == 'sample_pdf'){
        this.changeSamplePDF=true;
        this.control.sample_pdf.setValue(file);
      }else{
        this.changePDF=true;
        this.control.attachment.setValue(file);
      }
    }
  }

  backClicked() {
    this._location.back();
  }

}
