<!-- No User role -->
<div *ngIf="userRole==null">
  <div id="left-sidebar" class="sidebar">
    <div class="sidebar-scroll">
      <div class="user-account">
        <div class="dropdown">
          <span>Welcome,</span>
          <a href="javascript:void(0);" class=" user-name"><strong>{{userDisplayName}}</strong></a>
          <!-- <ul class="dropdown-menu dropdown-menu-right account">
            <li><a href="javascript:" (click)="profile()"><i class="icon-user"></i>My Profile</a></li>
            <li><a href="javascript:void(0);" (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a>
            </li>
            <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
            <li class="divider"></li>
            <li><a href="javascript:void(0);" (click)="logout()"><i class="icon-power"></i>Logout</a></li>
          </ul> -->
        </div>
        <img [src]="userProfile" id="user_profile_sidebar" class="rounded-circle user-photo" alt="User Profile Picture"
          width="50" height="50">
        <div class="profile-update-info prof-updt">
          <div class="star-upadte">
            <span *ngFor="let star of stars">
              <i class="fa fa-star" [ngClass]="{'pnk-star': (star <= rate)}"></i>
            </span>
          </div>
          <h6>
            <a href="javascript:void(0);" (click)="updateUser()" class="btn">Update Profile</a>
          </h6>
        </div>
      </div>
      <nav id="left-sidebar-nav" class="sidebar-nav">
        <ul metis-menu class="sd-br-img">
          <!-- id="main-menu" class="metismenu" -->
          <li [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
            <a href="javascript:" (click)="goToPage('dashboard')">
              <!-- <i class="icon-home"></i> -->
              <img src="./assets/assets/images/hom-icn.png">
              <span>Dashboard</span></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

<!-- Coach -->
<div *ngIf="userRole==2">
  <div id="left-sidebar" class="sidebar">
    <div class="sidebar-scroll">
      <div class="user-account">
        <div class="dropdown">
          <span>Welcome,</span>
          <a href="javascript:void(0);" class=" user-name"><strong>{{userDisplayName}}</strong></a>
          <!-- <ul class="dropdown-menu dropdown-menu-right account">
              <li><a href="javascript:" (click)="profile()"><i class="icon-user"></i>My Profile</a></li>
              <li><a href="javascript:void(0);" (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a></li>
              <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
              <li class="divider"></li>
              <li><a href="javascript:void(0);" (click)="logout()"><i class="icon-power"></i>Logout</a></li>
            </ul> -->
        </div>
        <img [src]="userProfile" id="user_profile_sidebar" class="rounded-circle user-photo" alt="User Profile Picture"
          width="50" height="50">
        <div class="profile-update-info prof-updt">
           <div class="star-upadte">
            <span *ngFor="let star of stars">
              <i class="fa fa-star" [ngClass]="{'pnk-star': (star <= rate)}"></i>
            </span>
          </div>
          <h6>
            <a href="javascript:void(0);" (click)="updateUser()" class="btn">Update Profile</a>
          </h6>
        </div>
      </div>
      <nav id="left-sidebar-nav" class="sidebar-nav">
        <ul metis-menu  class="sd-br-img">
          <!-- id="main-menu" class="metismenu" -->
          <li [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
            <a href="javascript:" (click)="goToPage('dashboard')">
              <!-- <i class="icon-home"></i> -->
              <img src="./assets/assets/images/hom-icn.png">
              <span>Dashboard</span></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

<!-- User -->
<div *ngIf="userRole==3">
  <div id="left-sidebar" class="sidebar new-scroll-design" style="height: 100vh;overflow: auto;scrollbar-width: thin;">
    <div class="sidebar-scroll">
      <div class="user-account">
        <div class="dropdown">
          <span>Welcome,</span>
          <a href="javascript:void(0);" class=" user-name"><strong>{{userDisplayName}}</strong></a>
        </div>
        <img [src]="userProfile" id="user_profile_sidebar" class="rounded-circle user-photo" alt="User Profile Picture"
          width="50" height="50">
        <div class="profile-update-info prof-updt">
          <div class="star-upadte">
            <i class="fa fa-star" [ngClass]="{'pnk-star': 1 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 2 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 3 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 4 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 5 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 6 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 7 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 8 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 9 <= rate}"></i>
            <i class="fa fa-star" [ngClass]="{'pnk-star': 10 <= rate}"></i>
          </div>
          <h6>
              <a href="javascript:void(0);" (click)="updateUser()" class="btn">Update Profile</a>
          </h6>
        </div>
      </div>
      <nav id="left-sidebar-nav" class="sidebar-nav " >
        <ul metis-menu class="sd-br-img">
          <!-- id="main-menu" class="metismenu" -->
          <li [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
            <a href="javascript:" (click)="goToPage('dashboard')">
              <!-- <i class="icon-home"></i> -->
              <img src="./assets/assets/images/hom-icn.png">
              <span>Dashboard</span></a>
          </li>
          <!-- <li class="" [routerLink]="['/userslist']" [routerLinkActive]="'active'">
                  <a href="javascript:" (click)="goToPage('userslist')"><i class="icon-users"></i> <span>User List</span></a>
                </li> -->
          <li [routerLinkActive]="'active'">
            <a href="#" class="has-arrow hs-lern">
              <!-- <i class="icon-envelope"></i> -->
              <img src="./assets/assets/images/learn.png">
              <span>Learn</span></a>
            <ul [routerLinkActive]="['mm-show']">
              <li [routerLinkActive]="'active'">
                <a [routerLink]="['/learn/generic-skills']">
                  <span>Generic Skills</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'">
                <a [routerLink]="['/learn/testing-skills']">
                  <span>Testing Skills</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'">
                <a [routerLink]="['/learn/soft-skills']">
                  <span>Soft Skills</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'">
                <a [routerLink]="['/learn/training']">
                  <span>Training</span>
                </a>
              </li>
            </ul>
          </li>
          <li [routerLinkActive]="'active'">
            <a href="#" class="has-arrow ntwk-img">
              <!-- <i class="icon-bar-chart"></i> -->
              <img src="./assets/assets/images/network.png">
              <span>Network</span></a>
            <ul [routerLinkActive]="['mm-show']">
              <li [routerLink]="['/vt-members']" [routerLinkActive]="['active', 'mm-show']">
                <a href="javascript:" (click)="goToPage('vt-members')">
                  <i class="icon-users"></i><span>Members</span>
                </a>
              </li>
              <!--<li [routerLink]="['/vt-coaches']"  [routerLinkActive]="['active' , 'mm-show'] ">
                <a href="javascript:" (click)="goToPage('vt-coaches')">
                  <i class="icon-users"></i><span>Coaches</span>
                </a>
              </li>-->
              <li [routerLinkActive]="'active'">
                <a routerLink="/vt-events">
                  <i class="icon-calendar"> </i><span>Events</span> </a>
              </li>
            </ul>
          </li>
          <li [routerLinkActive]="'active'">
            <a href="#" class="has-arrow">   
              <!-- <i class="icon-user"></i> -->
              <img src="./assets/assets/images/grow.png" class="grow-cld">
              <span>Grow</span></a>
            <ul [routerLinkActive]="['mm-show']">
              <li [routerLinkActive]="'active'">
                <a href="javascript:" *ngIf="isFreeTrial" (click)="OpenPlanPopup(PlanModal)">
                  <span>Job Search</span>
                </a>
                <a *ngIf="!isFreeTrial" [routerLink]="['/grow/job-search']" >
                  <span>Job Search</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'">
                <a href="javascript:" *ngIf="isFreeTrial" (click)="OpenPlanPopup(PlanModal)">
                  <span>CV/LinkedIn</span>
                </a>
                <a *ngIf="!isFreeTrial" [routerLink]="['/grow/cv-linkdin']">
                  <span>CV/LinkedIn</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'">
                <!--<a href="javascript:" *ngIf="isFreeTrial" (click)="OpenPlanPopup(PlanModal)">
                  <span>Coaching</span>
                </a>-->
                <a href="javascript:" (click)="goToPage('vt-coaches')">
                  <span>Coaching</span>
                </a>
                <!--<a *ngIf="!isFreeTrial" [routerLink]="['/grow/coaching']">
                  <span>Coaching</span>
                </a>-->
              </li>
            </ul>
          </li>
          <li [routerLinkActive]="'active'">
            <a routerLink="/tools">
              <!-- <i class="icon-grid"></i> -->
              <img src="./assets/assets/images/tools.png">
              <span>Tools</span></a>
          </li>
          <li class="cht-bdr" [routerLinkActive]="'active'">
            <a routerLink="/deal-room">
              <!-- <i class="icon-users"></i> -->
              <img src="./assets/assets/images/chat-room.png">
              <span>Deal Room</span></a>
          </li>
          <li [routerLinkActive]="'active'">
            <a routerLink="/upgrade-plan" class="updrd-img-icn">
              <!-- <i class="icon-globe"></i> -->
              <img src="./assets/assets/images/upgrade.png">
              <span>Upgrade / Renew</span></a>
          </li>
          <li [routerLinkActive]="'active'">
            <a routerLink="/account-settings">
              <!-- <i class="icon-settings"></i> -->
              <img src="./assets/assets/images/account-setting.png">
              <span>Account Settings</span></a>
          </li>
          <li>
            <a href="javascript:" (click)="logout()">
              <!-- <i class="icon-globe"></i> -->
              <img src="./assets/assets/images/log-out.png">
              <span>Logout</span></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

<!-- Super admin -->
<div *ngIf="userRole==1">
  <div id="left-sidebar" class="sidebar new-scroll-design" style="height: 100vh;overflow: auto;scrollbar-width: thin;">
    <div class="sidebar-scroll">
      <div class="user-account">
        <div class="dropdown">
          <span>Welcome,</span>
          <a href="javascript:void(0);" class=" user-name"><strong>{{userDisplayName}}</strong></a>
          <!-- <ul class="dropdown-menu dropdown-menu-right account">
            <li><a href="javascript:" (click)="profile()"><i class="icon-user"></i>My Profile</a></li>
            <li><a href="javascript:void(0);" (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a>
            </li>
            <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
            <li class="divider"></li>
            <li><a href="javascript:void(0);" (click)="logout()"><i class="icon-power"></i>Logout</a></li>
          </ul> -->
        </div>
        <img [src]="userProfile" id="user_profile_sidebar" class="rounded-circle user-photo" alt="User Profile Picture"
          width="50" height="50">
        <div class="profile-update-info prof-updt">
           <div class="star-upadte">
            <span *ngFor="let star of stars">
              <i class="fa fa-star" [ngClass]="{'pnk-star': (star <= rate)}"></i>
            </span>
          </div>
          <h6>
            <span>Click Here</span>
            <a href="javascript:void(0);" (click)="updateUser()" class="btn">Update Profile</a>
          </h6>
        </div>
      </div>
      <nav id="left-sidebar-nav" class="sidebar-nav">
        <ul metis-menu class="sd-br-img">
          <!--  id="main-menu" class="metismenu" -->
          <li [routerLinkActive]="'active'">
            <a href="javascript:" [routerLink]="['/Admin-Dashboard']">
              <!-- <i class="icon-home"></i> -->
              <img src="./assets/assets/images/hom-icn.png">
              <span>Dashboard</span></a>
          </li>
          <!-- <li class="" [routerLink]="['/userslist']" [routerLinkActive]="'active'">
                  <a href="javascript:" (click)="goToPage('userslist')"><i class="icon-users"></i> <span>User List</span></a>
                </li> -->
          <li>
            <a [routerLink]="['/admin/learning']" class="hs-lern">
              <!-- <i class="icon-envelope"></i> -->
              <img src="./assets/assets/images/learn.png">
              <span>Learn</span>
            </a>
          </li>
          <li [routerLinkActive]="'active'">
            <a href="javascript:" class="has-arrow ntwk-img">
              <!-- <i class="icon-bar-chart"></i> -->
              <img src="./assets/assets/images/network.png">
              <span>Network</span></a>
            <ul [routerLinkActive]="['mm-show']">
              <li [routerLink]="['/vt-members']" [routerLinkActive]="['active', 'mm-show']">
                <a href="javascript:" (click)="goToPage('vt-members')">
                  <i class="icon-users"></i><span>Members</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'">
                <a routerLink="/event-list">
                  <i class="icon-calendar"></i>
                  <span>Events</span></a>
              </li>
            </ul>
          </li>
          <li [routerLinkActive]="'active'">
            <a href="javascript:" class="has-arrow">
              <img src="./assets/assets/images/grow.png" class="grow-cld">
              <span>Grow</span>
            </a>
            <ul [routerLinkActive]="['mm-show']">
              <li [routerLinkActive]="'active'">
                <a [routerLink]="['/admin/jobs']" >
                  <span>Job Search</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'">
                <a href="javascript:" >
                  <span>CV/LinkedIn</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'">
                <a href="javascript:" >
                  <span>Coaching</span>
                </a>
              </li>
            </ul>
          </li>
          <li [routerLinkActive]="'active'">
            <a routerLink="/tools">
              <!-- <i class="icon-grid"></i> -->
              <img src="./assets/assets/images/tools.png">
              <span>Tools</span></a>
          </li>
          <li class="cht-bdr" [routerLinkActive]="'active'">
            <a routerLink="/admin/dealroom">
              <!-- <i class="icon-users"></i> -->
              <img src="./assets/assets/images/chat-room.png">
              <span>Deal Room</span>
            </a>
          </li>
          <li><a href="javascript:void(0);" (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a>
          </li>
          <li [routerLinkActive]="'active'">
            <a routerLink="/upgrade-plan" class="updrd-img-icn">
              <!-- <i class="icon-globe"></i> -->
              <img src="./assets/assets/images/upgrade.png">
              <span>Upgrade / Renew</span></a>
          </li>
          <li [routerLinkActive]="'active'">
            <a routerLink="/account-settings">
              <!-- <i class="icon-settings"></i> -->
              <img src="./assets/assets/images/account-setting.png">
              <span>Account Settings</span></a>
          </li>
          <li>
            <a href="javascript:" (click)="logout()">
              <!-- <i class="icon-globe"></i> -->
              <img src="./assets/assets/images/log-out.png">
              <span>Logout</span></a>
          </li>
        </ul>
      </nav>
    </div>
    <!-- <div class="bottom-side" style="margin-top:30px;">
      <nav class="margin-top-side">
        <ul>
          <li>
            <a href="javascript:"><i class="icon-globe"></i> <span>Upgrade / Renew</span></a>
          </li>
          <li [routerLinkActive]="'active'">
            <a routerLink="/account-settings"><i class="icon-settings"></i> <span>Account Settings</span></a>
          </li>
        </ul>>
      </nav>
    </div> -->
  </div>
</div>
<ng-template #PlanModal let-modal>
  <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel">Membership Subscription Plans</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
         <span aria-hidden="true">&times;</span>
       </button>
   </div>
   <div class="modal-body">
      <div class="Iconwarning-box  text-center mb-4" *ngIf="CurrentPlanID > 0">
        <span class="info-Iconwarning ">  <img src="./assets/assets/images/renew-icon.png" alt="renew-icon"></span>
         <div class="Iconwarning-box-content">
            <h6>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
               enjoying the benefits of all features by VTTouch. 
            </h6>
         </div>
      </div>
      <div class="row justify-content-center">
         <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
            <div class="single-price-plan mb-100">
               <div class="price-plan-title">
                  <h4>{{singlePlan.plan_name}}
                  </h4>
               </div>
               <div class="price-plan-value">
                  <h2>£{{singlePlan.price}}</h2>
                  <p>{{singlePlan.duration}} {{singlePlan.duration_type}}</p>
               </div>
                 <a href="javascript:;" class="Buttons-pink " *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Start Trial</a>
                 <a href="#" class="Buttons-pink " *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Continue</a>
            </div>
         </div>
         
         
      </div>
   </div>
</ng-template>