import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    count = 0;

    constructor(private authService: AuthService, private spinner: NgxSpinnerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.includes('/dealroom/add_like') || req.url.includes('/dealroom/add_follow')
        || req.url.includes('/dealroom/get_post_by_room_id')
        || req.url.includes('/messages/get_message_by_session_id')
        || req.url.includes('/messages/AddMessages')
        || req.url.includes('dealroom/get_following_post_of_user')
        || req.url.includes('notifications/get_users_unread_notification')
        || req.url.includes('messages/get_all_users_unread_messages/')
        || req.url.includes('get_all_users_page/')
        ){
            return next.handle(req);
        }

        this.spinner.show()

        this.count++;

        return next.handle(req)
            .pipe ( tap (
                    //event => console.log(event),
                    //error => console.log( error )
                ), finalize(() => {
                    this.count--;
                    if ( this.count == 0 ) {
                       this.spinner.hide()
                    }
                })
            );
    }
}