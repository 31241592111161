<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <!-- infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollUpDistance]="2"
    [infiniteScrollThrottle]="300"
    [fromRoot]="true"
    (scrolled)="onScroll($event)" -->
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <!-- <h2> <i class="fa fa-chevron-left" aria-hidden="true"></i> Chat Room</h2> -->
                    <ul class="breadcrumb vt-a">
                    <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                    <li class="breadcrumb-item">Chat Room</li>
                   </ul>
                </div>
            </div>
        </div>

        <div id="ChatSection">
            <div id="sidepanel">

                <div id="contacts">
                    <div id="search" class="mb-4">
                        <input type="text" placeholder="Search people" />
                        <label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
                    </div>

                    <ul>
                        <li class="contact" *ngFor="let value of userList;let i = index" (click)="GetChat(value)" [ngClass]="{'active':value.m_session_id==session_id}">
                            <div class="wrap">
                                <span class="contact-status online"></span>
                                <img src="{{value.profile_pic}}" alt="" />
                                <div class="meta">
                                    <h5 class="name me-auto mb-0">{{value.sender_name}}
                                        <span class="text-muted extra-small ms-2">{{value.created_at || shortTime}}</span>
                                    </h5>
                                    <h6 class="preview line-clamp me-auto">
                                        {{value.message}}
                                        <p *ngIf="value.count" class="badge badge-circle  ms-5">
                                            <span>{{value.count}}</span>
                                        </p>
                                    </h6>
                                </div>
                            </div>
                        </li>
                        <li class="contact" *ngIf="!isUserFound">No user found</li>
                    </ul>
                </div>

            </div>
            <div class="content">
                <div class="contact-profile">
                    <img *ngIf="chatOpenData" src="{{chatOpenData?.profile_pic}}" alt="" />
                    <img *ngIf="!chatOpenData" src="./assets/assets/images/user.png" alt="" />
                    <p>{{chatOpenData?.sender_name}}</p>
                    <div class="Arrow-Icon">
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </div>
                </div>
                <div id="scrollWindow" class="messages">
                    <ul>
                        <li class="" *ngFor="let value of messageList;let index = index" [ngClass]=" value.is_login_message ? 'replies' : 'sent' ">
                            <img src="{{ value.sender?.profile_pic }}" alt="{{value.sender_name}}" />
                            <p [innerHTML]="value.message"></p>
                            <span class="time_date"> {{ value.created_at }}</span>
                        </li>

                    </ul>
                </div>

                <form class="chat-form" [formGroup]="sendMessage" enctype="multipart/form-data">
                    <div class="row align-items-center gx-0">

                        <div class="col">
                            <div class="input-group">
                                <textarea class="form-control px-0" placeholder="Enter your message here" rows="1" formControlName="message_description" data-autosize="true"></textarea>
                                <input type="hidden" class="form-control" formControlName="reciver_user_id" [value]="reciver_user_id">
                                <a href="#" class="input-group-text">
                                    <span class="icon icon-lg">
                                        <i class="fa fa-smile-o smile" aria-hidden="true"></i> 
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="col-auto">
                            <a href="#" class="btn btn-link attachment">
                                <i class="fa fa-paperclip attachment" aria-hidden="true"></i>
                            </a>
                        </div>

                        <div class="col-auto">
                            <button class="btn btn-send ms-5" (click)="SendMessageStore()">
                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                               </button>
                        </div>
                    </div>
                </form>
                <!-- <div class="message-input">
                    <form [formGroup]="sendMessage" enctype="multipart/form-data">
                        <div class="wrap">
                            <textarea class="form-control" placeholder="Write Your message" 
                                formControlName="message_description"></textarea>
                            <input type="hidden" class="form-control" formControlName="reciver_user_id" value="reciver_user_id">
                            <i class="fa fa-smile-o smile" aria-hidden="true"></i> 
                            <i class="fa fa-paperclip attachment" aria-hidden="true"></i>
                            <button class="submit" (click)="SendMessageStore()"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                        </div>
                    </form>
                </div> -->
            </div>
        </div>


    </div>
</div>