import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label,Color } from 'ng2-charts';
import { DatePipe } from '@angular/common';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-mastertools',
  templateUrl: './mastertools.component.html',
  styleUrls: ['./mastertools.component.css'],
  providers: [DatePipe]
})
export class MastertoolsComponent implements OnInit {

  @ViewChild('htmlData') htmlData:ElementRef;

  questionForm: FormGroup;
  submitted = false;
  question_1_priority = 1;
  data = [];
  userList = [];
  isSubmit = false;
  loading = false;
  disGenBtn = false;


  chartData = [];

  modalReference: NgbModalRef;
  isModalShow = false;

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        max: 5,
        min: 0,
        stepSize: 1
      }
    }
  };
  public radarChartLabels: Label[] = [];
  public radarChartData: ChartDataSets[] = [
    { data: [], label: 'Project Leader Compabilty indicator' }
  ];
  public radarChartType: ChartType = 'radar';

  public barChartColors: Color[] = [
    /* { backgroundColor: 'rgba(213, 211, 210, 0.5)' },
    { borderColor: '#bd007d' }, */
    { pointBackgroundColor: '#bd007d', },
  ]

  isChartShow=false;
  isSaveShow=false;
  isDownloadShow=false;
  isDownloadShowOnly=false;
  toolData:any=[];
  isDeleteShow=false;
  saveToolId=null;
  isReportShow=true;
  isCalShow=false;
  totalRate:any=0;
  userFileName=null;
  isSubmitted  =  false;
  starDisable = false
  isWeightWrong = false;

  constructor(private _location: Location,
            private formBuilder: FormBuilder,
            private toastr: ToastrService,
            private modalService: NgbModal,
            private authService: AuthService,
            private router: Router,
            private route: ActivatedRoute,
            private datePipe: DatePipe) { }

  ngOnInit(): void {

    this.authService.getAllTool().subscribe((res:any)=>{
        if(res.status) {
          this.toolData = res.tools;
          if(this.toolData && this.toolData[0]){
            if(this.toolData[0].user_tools && this.toolData[0].user_tools.length>=5){
              this.toastr.error("You already reached your maximum tool", "Error");
              this.router.navigateByUrl('tools');
            }
          }
        } else {

        }
      })

     this.questionForm = this.formBuilder.group({
          analysis_name: ['', Validators.required],
          //question_1_priority: ['', Validators.required],
          question_1_weight: ['', Validators.required],
          question_1_rating: ['', [Validators.required]],
          question_1_rating_value: ['', Validators.required],
          //question_2_priority: ['', Validators.required],
          question_2_weight: ['', Validators.required],
          question_2_rating: ['', [Validators.required]],
          question_2_rating_value: ['', Validators.required],
          //question_3_priority: ['', Validators.required],
          question_3_weight: ['', Validators.required],
          question_3_rating: ['', [Validators.required]],
          question_3_rating_value: ['', Validators.required],
          //question_4_priority: ['', Validators.required],
          question_4_weight: ['', Validators.required],
          question_4_rating: ['', [Validators.required]],
          question_4_rating_value: ['', Validators.required],
          //question_5_priority: ['', Validators.required],
          question_5_weight: ['', Validators.required],
          question_5_rating: ['', [Validators.required]],
          question_5_rating_value: ['', Validators.required],
          //question_6_priority: ['', Validators.required],
          question_6_weight: ['', Validators.required],
          question_6_rating: ['', [Validators.required]],
          question_6_rating_value: ['', Validators.required],
          user_rating_value: [],
          tools_id: [],
          weightTotal: ['', [Validators.required, Validators.min(100), Validators.max(100)]],
          //password: ['', [Validators.required, Validators.minLength(6)]]
      });
      //this.data['question_1_priority'] = 1;
      this.data['question_1_weight'] = 20.7;
      //this.data['question_1_rating']  = 1;
      //this.data['question_2_priority'] = 2;
      this.data['question_2_weight'] = 23.3;
      //this.data['question_2_rating']  = 3;
      //this.data['question_3_priority'] = 3;
      this.data['question_3_weight'] = 12.7;
      //this.data['question_3_rating']  = 3;
      //this.data['question_4_priority'] = 4;
      this.data['question_4_weight'] = 10.0;
      //this.data['question_4_rating']  = 1;
      //this.data['question_5_priority'] = 5;
      this.data['question_5_weight'] = 18.0;
      //this.data['question_5_rating']  = 5;
      //this.data['question_6_priority'] = 6;
      this.data['question_6_weight'] = 15.3;
      //this.data['question_6_rating']= 4;
      this.data['tools_id'] = this.route.snapshot.params['id'];

      this.data['weightTotal']= this.data['question_1_weight']+this.data['question_2_weight']+this.data['question_3_weight']+
      this.data['question_4_weight']+this.data['question_5_weight']+this.data['question_6_weight'];

      this.questionForm.patchValue(this.data);
      this.isSubmit = true;

      /* this.radarChartData = [
        { data: [1,2,3,4,5,6], label: 'Project Leader Compabilty indicator' }
      ]; */

    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#myHeader').addClass('sticky');
      } else {
        $('#myHeader').removeClass('sticky');
      }
    });

  }

  get queControl() {
    return this.questionForm.controls;
  }

  submit() {
    if(this.questionForm.invalid) {
      this.toastr.error("Please give all question rating", "Error");
      return;
    }
    this.isModalShow = true;
    this.open('mymodal');

  }

  closeResult: string;
  editUserForm: FormGroup;
  editUserRole = false;
  formdata:any = [];
  date:any =new Date();

  get EditControl() {
    return this.editUserForm.controls;
  }

  open(content) {
    if(this.questionForm.invalid) {
      this.toastr.error("Please give all question rating", "Error");
      return;
    }

    if(this.queControl.weightTotal.value!=100){
      this.toastr.error("Weight Total must be 100 only", "Error");
    }

    this.authService.getAllUserTypeUser().subscribe((res:any)=>{
      if(res.status) {
        this.userList = res.users;
      } else {
        this.toastr.error("User list not found", "Error");
      }
    })
    this.editUserRole = true;

    this.editUserForm = this.formBuilder.group({
        user_name: ['', Validators.required],
    });
    this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd HH:mm');
    this.formdata['user_name'] = this.authService.user.name+'_'+this.date;
    this.editUserForm.patchValue(this.formdata);

    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  updateUserName(){
    if(this.editUserForm.invalid) {
      this.toastr.error("Please select user or enter name", "Error");
      return;
    }else{
      let userName = this.EditControl.user_name.value;
      this.userFileName = userName;
      this.queControl.user_rating_value.setValue(userName);
      this.authService.createUserRating(this.questionForm.value).subscribe((res:any)=>{
        if(res.status) {
          this.toastr.success('User rating added successfully', "Success");
          this.JoinAndClose();
          this.isDownloadShow=true;
          this.isDownloadShowOnly=true;
          this.isSaveShow=false;
          this.isSubmit=false;
          this.isReportShow=false;
          this.isDeleteShow=true;
          this.saveToolId = res.user_rating.id;
          this.starDisable = true;
        } else {
          this.toastr.error("User rating add error", "Error");
          this.JoinAndClose();
        }
        //this.router.navigateByUrl('tools');

      })
    }
  }

  private getDismissReason(reason: any): string {
    //this.editUserRole = false;

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }

  }

  JoinAndClose() {
    this.modalReference.close();
  }

  onWeightChange(event: any){
    let Total = Number(this.queControl.question_1_weight.value) +
    Number(this.queControl.question_2_weight.value) +
    Number(this.queControl.question_3_weight.value) +
    Number(this.queControl.question_4_weight.value) +
    Number(this.queControl.question_5_weight.value) +
    Number(this.queControl.question_6_weight.value);
    //alert(Total)
    if(Total>100){
      this.isSubmit = false;
      this.toastr.error("Weight total not greater than 100", "Error");
      this.isWeightWrong = true;
    }
    if(Total<100){
      this.isSubmit = false;
      this.toastr.error("Weight total not less than 100", "Error");
      this.isWeightWrong = true;
    }
    if(Total==100){
      this.isSubmit = true;
      this.isWeightWrong = false;
    }
    this.queControl.weightTotal.setValue(Total);
  }

  backClicked() {
    this._location.back();
  }

  setMyRate(qustionValue, starValue, event){
    if(qustionValue==1){
      let fieldVal = this.calculation(this.queControl.question_1_weight.value, starValue);
      this.queControl.question_1_rating_value.setValue(fieldVal);
      this.queControl.question_1_rating.setValue(starValue);
    }
    if(qustionValue==2){
      let fieldVal = this.calculation(this.queControl.question_2_weight.value, starValue);
      this.queControl.question_2_rating_value.setValue(fieldVal);
      this.queControl.question_2_rating.setValue(starValue);
    }
    if(qustionValue==3){
      let fieldVal = this.calculation(this.queControl.question_3_weight.value, starValue);
      this.queControl.question_3_rating_value.setValue(fieldVal);
      this.queControl.question_3_rating.setValue(starValue);
    }
    if(qustionValue==4){
      let fieldVal = this.calculation(this.queControl.question_4_weight.value, starValue);
      this.queControl.question_4_rating_value.setValue(fieldVal);
      this.queControl.question_4_rating.setValue(starValue);
    }
    if(qustionValue==5){
      let fieldVal = this.calculation(this.queControl.question_5_weight.value, starValue);
      this.queControl.question_5_rating_value.setValue(fieldVal);
      this.queControl.question_5_rating.setValue(starValue);
    }

    if(qustionValue==6){
      let fieldVal = this.calculation(this.queControl.question_6_weight.value, starValue);
      this.queControl.question_6_rating_value.setValue(fieldVal);
      this.queControl.question_6_rating.setValue(starValue);
    }
    //let elements = event.srcElement.parentElement.childNodes;
    for(var i=0;i<=4;i++){
      if(starValue<i+1){
        event.srcElement.parentElement.childNodes[i].classList.remove('fa-star');
        event.srcElement.parentElement.childNodes[i].classList.add('fa-star-o');
      }else{
        event.srcElement.parentElement.childNodes[i].classList.remove('fa-star-o');
        event.srcElement.parentElement.childNodes[i].classList.add('fa-star');
        //event.srcElement.classList.add("fa-star");
      }
      /* event.srcElement.parentElement.childNodes[1].classList.remove('selected')
      event.srcElement.parentElement.childNodes[2].classList.remove('selected')
      event.srcElement.parentElement.childNodes[3].classList.remove('selected')
      event.srcElement.parentElement.childNodes[4].classList.remove('selected') */

    }
    //event.srcElement.parentElement.childNodes.classList.remove('selected')
    //event.srcElement.classList.add("fa-star");
  }

  generateReport(){
    this.isSubmitted=true;
    this.loading = true;
    if(this.questionForm.valid){
      this.loading = false;
      this.radarChartLabels = ['Customer Focus', 'Flexibility', 'Tolerance for Ambigulty', 'Communication', 'Commitment', 'Leader'];
      this.radarChartData = [
            { data: [this.queControl.question_1_rating.value,
                    this.queControl.question_2_rating.value,
                    this.queControl.question_3_rating.value,
                    this.queControl.question_4_rating.value,
                    this.queControl.question_5_rating.value,
                    this.queControl.question_6_rating.value], label: 'Project Leader Compabilty indicator' }

          ];
      this.disGenBtn = true;
      this.isChartShow=true;
      this.isSaveShow=true;
      this.isDownloadShowOnly=true;
      this.isCalShow=true;
      let totalRates =Number(this.queControl.question_1_rating_value.value)+
      Number(this.queControl.question_2_rating_value.value)+
      Number(this.queControl.question_3_rating_value.value)+
      Number(this.queControl.question_4_rating_value.value)+
      Number(this.queControl.question_5_rating_value.value)+
      Number(this.queControl.question_6_rating_value.value);
      this.totalRate = totalRates.toFixed();
    }else{
      this.toastr.error("Please fill all the value", "Error");
      this.loading = false;
    }
  }

  calculation(weight, rate){
    let total = rate*weight;
    let rateValue = total/100;
    return rateValue.toFixed(1);
  }

  DeleteReport(){
    if(this.saveToolId!=null){
      Swal.fire({
        title: 'Are you sure want to delete this report?',
        text: 'Once you delete it, you will not be able to recover this!',
        // icon: 'warning',
        iconHtml: ' <img src="./assets/assets/images/delete-icon.png" alt="delete-icon">',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, save it!'
      }).then((result) => {
          if (result.value) {
            this.authService.deleteUserTool(this.saveToolId).subscribe((res:any)=>{
              if(res.status) {
                this.toastr.success('User report deleted successfully', "Success");
              } else {
                this.toastr.error("User report delete error", "Error");
              }
              this.router.navigateByUrl('tools');
            })
          }
      })
    }else{
      this.toastr.error("User report not found", "Error");
    }
  }

  public openPDF():void {
    let DATA = document.getElementById('htmlData');

   html2canvas(DATA).then(canvas => {
      // Few necessary setting options
      var margin = 3;
      var imgWidth = 208-margin*2;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      //pdf.text("Project Leader Characteristics", 20, 20);
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight)
      pdf.setFontSize(30);
      pdf.setFont("times");
      //pdf.setFontType("bold");
      //pdf.setTextColor(76, 130, 212);
      //pdf.fillStyle("rgba(76, 130, 212,.2)");
      //pdf.text('VTTouch', 100, imgHeight/2);
      pdf = this.addWaterMark(pdf);
      pdf.save(this.userFileName+".pdf"); // Generated PDF
    });
  }

  addWaterMark(doc) {
    var totalPages = doc.internal.getNumberOfPages();
    doc.setTextColor(150);
    doc.text(143, doc.internal.pageSize.height/2, 'VT-Touch');
    return doc;
  }

  openTwo(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openThree(contents) {
    this.modalService.open(contents, { ariaLabelledBy: 'modal-basic-titles', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
