import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class AdmindashboardService {

  constructor(
    private http: HttpClient
  ) { }

  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getDashboardCount() {
    return this.http.get(`${this.url}/get_admin_dashboard_count`, this.httpOptions);
  }

  getUpcomingEvents() {
    return this.http.get(`${this.url}/events/get_upcoming_event_list`, this.httpOptions);
  }

  getAllSubscriptions() {
    return this.http.get(`${this.url}/get_all_subscriptions`, this.httpOptions);
  }

  getDealRoomRequest() {
    return this.http.get(`${this.url}/dealroom/get_all_join_request`, this.httpOptions);
  }

}
