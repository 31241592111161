import { AbstractControl, Validators, FormGroup, FormControl } from '@angular/forms';

export class ValidationService {


  static getValidatorErrorMessage(code: string, validatorValue?: any) {
    const config = {
      'required': `This field is required`,
      'invalidNumber': 'Only number allowed',
      'dirNameInvalid': 'Invalid Dir Name',
      'invalidMatchPassword': 'Password does not matched',
      'EitherOrReuired': 'Any One Email Required',
      'DateCheker': 'End date should be greater than start date',
      'invalidCreditCard': 'Is invalid credit card number',
      'email': 'Invalid email address',
      'SignatureValidation': 'Signature is required',
      'ExtraEmail': `The following emails are bad ${validatorValue.value}`,
      'invalidPassword': 'Password must be at least 6 to 10 characters long, and contain a numbers, uppercase, lowercase, special characters.',
      'minlength': `Minimum ${validatorValue.requiredLength} digit allowed`,
      'maxlength': `Maximum ${validatorValue.requiredLength} digit allowed`
    };
    return config[code];
  }
  static DateCheker(control: AbstractControl) {
    console.log(control.get('start_month').value, "control.get('start_month').value")
    if (control.get('is_current').value == 1) {
      return null;
    } else {
      if (control.get('start_month').value && control.get('start_year').value && control.get('end_month').value && control.get('end_year').value) {
        if ((control.get('end_year').value < control.get('start_year').value) || (control.get('end_year').value == control.get('start_year').value && control.get('start_month').value > control.get('end_month').value)) {
          console.log('not working')
          return { 'DateCheker': true };
        } else {
          console.log('yeah it is working')
          return null;
        }
      }
    }


  }
  static DateChekerCertificate(control: AbstractControl) {
    console.log(control.get('start_month').value, "control.get('start_month').value")
    if (control.get('is_ongoing').value == 1) {
      return null;
    } else {
      if (control.get('start_month').value && control.get('start_year').value && control.get('end_month').value && control.get('end_year').value) {
        if ((control.get('end_year').value < control.get('start_year').value) || (control.get('end_year').value == control.get('start_year').value && control.get('start_month').value > control.get('end_month').value)) {
          console.log('not working')
          return { 'DateCheker': true };
        } else {
          console.log('yeah it is working')
          return null;
        }
      }
    }


  }

  static DateChekerEducation(control: AbstractControl) {
    if (control.get('is_ongoing').value == 1) {
      return null;
    } else {
      if (control.get('start_month').value && control.get('start_year').value && control.get('completion_month').value && control.get('completion_year').value) {
        if ((control.get('completion_year').value < control.get('start_year').value) || (control.get('completion_year').value == control.get('start_year').value && control.get('start_month').value >= control.get('completion_month').value)) {
          return {
            'DateCheker': true
          };
        } else {
          return null;
        }
      }
    }
  }

}
