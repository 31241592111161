<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <!-- <div class="block-header">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <h2><a href="javascript:void(0);" class="btn btn-xs btn-link" (click)="backClicked()">
                            <i class="fa fa-arrow-left"></i>
                        </a> Learning List
                    </h2>
                </div>
            </div>
        </div> -->
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item"><a routerLink="/dashboard">Learning List</a></li>
                      </ul>
            
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>Learning List</h2>
                        <a [routerLink]="['/admin/learning-add']" class="btn pbtn color_cls"> Add </a>
                    </div>
                    <div class="body">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Category Name</th>
                                    <th scope="col">Icon</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="learningList?.length; else no_data_templ">
                                <tr *ngFor="let value of learningList; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>
                                        {{ value.title }}
                                    </td>
                                    <td>
                                        {{ value.category_name }}
                                    </td>
                                    <td>
                                        <span *ngIf="value.icon">
                                            <img [src]="value.icon" width="50" height="50">
                                        </span>
                                        <span *ngIf="!value.icon">
                                            -
                                        </span>
                                    </td>
                                    <td>
                                        <a href="javascript:;" class="btn pbtn" (click)="editLearning(value.id)">
                                            <i class="fa fa-edit"></i>
                                        </a>&nbsp;
                                        <a [href]="value.attachment" target="_blank" rel="noopener noreferrer"
                                            [download]="value.title" class="btn btn-primary  pbtn">
                                            <i class="fa fa-download"></i>
                                        </a>&nbsp;
                                        <a href="javascript:;" class="btn btn-danger pbtn" (click)="deleteLearning(value.id)">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                        <!-- <button class="btn pbtn">
                                            <i class="fa fa-download"></i>
                                        </button> -->
                                    </td>
                                </tr>
                                <tr *ngIf="learningList.length==0">
                                    <td colspan="3">No Data Found</td>
                                </tr>
                            </tbody>
                            <ng-template #no_data_templ>
                                <tr>
                                    <td colspan="4" class="text-center">No Data Found</td>
                                </tr>
                            </ng-template>
                        </table>
                    </div>

                </div>
            </div>

        </div>


    </div>
</div>
