import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

declare var $: any;

import { EChartsOption } from 'echarts';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-project-tool',
  templateUrl: './project-tool.component.html',
  styleUrls: ['./project-tool.component.css'],
  providers: [DatePipe]
})
export class ProjectToolComponent implements OnInit {

  questionForm: FormGroup;
  id:any;
  questionList: any = [
                'Meets organization’s strategic objectives',
                'Customer prefers our organization',
                'Expands organization’s experience base',
                'Improves organization’s market share',
                'Uses organization’s underutilized resources',
                'Customer will serve as a reference site',
                'Potential for additional business',
                'Customer relationship',
                'Customer readiness to undertake this project',
                'Changing requirements',
                'Customer financial issues',
                'Availability of resources',
                'Resource skill level or certification status',
              ];

  opp_pro_total:number = 0;
  opp_imp_total:number = 0;
  opp_final_total:number = 0;
  opp_final_total_percent:number = 0;

  risk_pro_total:number = 0;
  risk_imp_total:number = 0;
  risk_final_total:number = 0;
  risk_final_total_percent:number = 0;
  question:any = [];

  q_1_total:number = 0;
  q_2_total:number = 0;
  q_3_total:number = 0;
  q_4_total:number = 0;
  q_5_total:number = 0;
  q_6_total:number = 0;
  q_7_total:number = 0;
  q_8_total:number = 0;
  q_9_total:number = 0;
  q_10_total:number = 0;
  q_11_total:number = 0;
  q_12_total:number = 0;
  q_13_total:number = 0;
  userFileName:any;
  isGenerateShow = true;
  isSubmitShow = false;
  isPdfShow = false;
  disGenBtn = false;
  toolData:any;

  chartOption:any;
  chartOption2:any;
  date:any =new Date();
  submitted = false;

  constructor(
            private formBuilder: FormBuilder,
            private toastr: ToastrService,
            private authService: AuthService,
            private router: Router,
            private route: ActivatedRoute,
            private datePipe: DatePipe) { }

  ngOnInit(): void {
      this.id = this.route.snapshot.params['id'];
      var val = Math.floor(1000 + Math.random() * 9000);
      this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd HH:mm');
      this.userFileName = this.authService.user.name+'_'+this.date;

      this.authService.getAllTool().subscribe((res:any)=>{
        if(res.status) {
          this.toolData = res.tools;
          if(this.toolData && this.toolData[1]){
            if(this.toolData[1].user_tools && this.toolData[1].user_tools.length>=5){
              this.toastr.error("You already reached your maximum tool", "Error");
              this.router.navigateByUrl('/tools');
            }
          }
        }
      })

      this.authService.getAllQuestionsByType(2).subscribe((res:any)=>{
        if(res.status) {
          this.question = res.question;

          this.queControl.tools_id.setValue(this.id);
          this.queControl.question_1_qustion.setValue(this.question[0].id);
          this.queControl.question_2_qustion.setValue(this.question[1].id);
          this.queControl.question_3_qustion.setValue(this.question[2].id);
          this.queControl.question_4_qustion.setValue(this.question[3].id);
          this.queControl.question_5_qustion.setValue(this.question[4].id);
          this.queControl.question_6_qustion.setValue(this.question[5].id);
          this.queControl.question_7_qustion.setValue(this.question[6].id);
          this.queControl.question_8_qustion.setValue(this.question[7].id);
          this.queControl.question_9_qustion.setValue(this.question[8].id);
          this.queControl.question_10_qustion.setValue(this.question[9].id);
          this.queControl.question_11_qustion.setValue(this.question[10].id);
          this.queControl.question_12_qustion.setValue(this.question[11].id);
          this.queControl.question_13_qustion.setValue(this.question[12].id);
        } else {
          this.toastr.error("Question list not found", "Error");
        }
      })

      this.questionForm = this.formBuilder.group({
          analysis_name: ['', Validators.required],
          question_1_qustion: [''],
          question_2_qustion: [''],
          question_3_qustion: [''],
          question_4_qustion: [''],
          question_5_qustion: [''],
          question_6_qustion: [''],
          question_7_qustion: [''],
          question_8_qustion: [''],
          question_9_qustion: [''],
          question_10_qustion: [''],
          question_11_qustion: [''],
          question_12_qustion: [''],
          question_13_qustion: [''],

          question_1_total: [''],
          question_2_total: [''],
          question_3_total: [''],
          question_4_total: [''],
          question_5_total: [''],
          question_6_total: [''],
          question_7_total: [''],
          question_8_total: [''],
          question_9_total: [''],
          question_10_total: [''],
          question_11_total: [''],
          question_12_total: [''],
          question_13_total: [''],

          question_1_profebility_score: ['', Validators.required],
          question_1_impact_score: ['', [Validators.required]],
          question_2_profebility_score: ['', Validators.required],
          question_2_impact_score: ['', [Validators.required]],
          question_3_profebility_score: ['', Validators.required],
          question_3_impact_score: ['', [Validators.required]],
          question_4_profebility_score: ['', Validators.required],
          question_4_impact_score: ['', [Validators.required]],
          question_5_profebility_score: ['', Validators.required],
          question_5_impact_score: ['', [Validators.required]],
          question_6_profebility_score: ['', Validators.required],
          question_6_impact_score: ['', [Validators.required]],
          question_7_profebility_score: ['', Validators.required],
          question_7_impact_score: ['', [Validators.required]],

          question_8_profebility_score: ['', Validators.required],
          question_8_impact_score: ['', [Validators.required]],
          question_9_profebility_score: ['', Validators.required],
          question_9_impact_score: ['', [Validators.required]],
          question_10_profebility_score: ['', Validators.required],
          question_10_impact_score: ['', [Validators.required]],
          question_11_profebility_score: ['', Validators.required],
          question_11_impact_score: ['', [Validators.required]],
          question_12_profebility_score: ['', Validators.required],
          question_12_impact_score: ['', [Validators.required]],
          question_13_profebility_score: ['', Validators.required],
          question_13_impact_score: ['', [Validators.required]],

          opportunity_profibility_score: [],
          opportunity_impact_score: [],
          risk_profibility_score: [],
          risk_impact_score: [],
          tools_id: [],
      });

      setTimeout(() => {
        // this.queControl.tools_id.setValue(this.id);
        // this.queControl.question_1_qustion.setValue(this.question[0].id);
        // this.queControl.question_2_qustion.setValue(this.question[1].id);
        // this.queControl.question_3_qustion.setValue(this.question[2].id);
        // this.queControl.question_4_qustion.setValue(this.question[3].id);
        // this.queControl.question_5_qustion.setValue(this.question[4].id);
        // this.queControl.question_6_qustion.setValue(this.question[5].id);
        // this.queControl.question_7_qustion.setValue(this.question[6].id);
        // this.queControl.question_8_qustion.setValue(this.question[7].id);
        // this.queControl.question_9_qustion.setValue(this.question[8].id);
        // this.queControl.question_10_qustion.setValue(this.question[9].id);
        // this.queControl.question_11_qustion.setValue(this.question[10].id);
        // this.queControl.question_12_qustion.setValue(this.question[11].id);
        // this.queControl.question_13_qustion.setValue(this.question[12].id);
      }, 2000);

    $(window).scroll(function () {
      if ($(this).scrollTop() > 40) {
        $('#myHeader').addClass('sticky');
      } else {
        $('#myHeader').removeClass('sticky');
      }
    });

  }

  get queControl() {
    return this.questionForm.controls;
  }

  submit(){
    if(!this.questionForm.valid){
      this.toastr.error("Please select all options", "Error");
      return;
    }else{
      this.authService.createUserRating2(this.questionForm.value).subscribe((res:any)=>{
        if(res.status) {
          this.toastr.success('User rating added successfully', "Success");
          this.isPdfShow=true;
          this.isSubmitShow=false;
          this.isGenerateShow=false;
          //this.router.navigateByUrl('/tools');


        } else {
          this.toastr.error("User rating add error", "Error");
          //this.JoinAndClose();
        }
        //this.router.navigateByUrl('tools');
      })
    }

  }

  generateReport(){
    this.submitted = true;
    if(!this.questionForm.valid){
      this.toastr.error("Please select all options", "Error");
      return;
    }else{
      this.submitted = false;
      //this.isGenerateShow = false
      this.disGenBtn = true;
      this.isSubmitShow=true;
      this.chartOption = {
          series: [{
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 10,
            axisLine: {
                lineStyle: {
                    width: 6,
                    color: [
                        //[0.25, '#FF6E76'],
                        [0.33, '#00d52f'],
                        [0.66, '#ffbf00'],
                        [1, '#cc0000']
                    ]
                }
            },
            pointer: {
                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                length: '12%',
                width: 20,
                offsetCenter: [0, '-60%'],
                itemStyle: {
                    color: 'auto'
                }
            },
            axisTick: {
                length: 12,
                lineStyle: {
                    color: 'auto',
                    width: 2
                }
            },
            splitLine: {
                length: 20,
                lineStyle: {
                    color: 'auto',
                    width: 5
                }
            },
            axisLabel: {
                color: '#464646',
                fontSize: 14,
                distance: -60,
                formatter: function (value) {
                    if (value === 20) {
                        return 'low';
                    }
                    else if (value === 50) {
                        return 'Medium';
                    }
                    else if (value === 90) {
                        return 'High';
                    }
                    // else if (value === 100) {
                    //     return '差';
                    // }
                }
            },
            title: {
                offsetCenter: [0, '-20%'],
                fontSize: 20
            },
            detail: {
                fontSize: 30,
                offsetCenter: [0, '0%'],
                valueAnimation: true,
                formatter: function (value) {
                    return value;
                },
                color: 'auto'
            },
            data: [{
                value: this.opp_final_total_percent,
                name: 'Benefit Score'
            }]
        }]
        };

        this.chartOption2 = {
          series: [{
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 10,
            axisLine: {
                lineStyle: {
                    width: 6,
                    color: [
                        //[0.25, '#FF6E76'],
                        [0.33, '#00d52f'],
                        [0.66, '#ffbf00'],
                        [1, '#cc0000']
                    ]
                }
            },
            pointer: {
                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                length: '12%',
                width: 20,
                offsetCenter: [0, '-60%'],
                itemStyle: {
                    color: 'auto'
                }
            },
            axisTick: {
                length: 12,
                lineStyle: {
                    color: 'auto',
                    width: 2
                }
            },
            splitLine: {
                length: 20,
                lineStyle: {
                    color: 'auto',
                    width: 5
                }
            },
            axisLabel: {
                color: '#464646',
                fontSize: 14,
                distance: -60,
                formatter: function (value) {
                    if (value === 20) {
                        return 'low';
                    }
                    else if (value === 50) {
                        return 'Medium';
                    }
                    else if (value === 90) {
                        return 'High';
                    }
                    // else if (value === 100) {
                    //     return '差';
                    // }
                }
            },
            title: {
                offsetCenter: [0, '-20%'],
                fontSize: 20
            },
            detail: {
                fontSize: 30,
                offsetCenter: [0, '0%'],
                valueAnimation: true,
                formatter: function (value) {
                    return value;
                },
                color: 'auto'
            },
            data: [{
                value: this.risk_final_total_percent,
                name: 'Risk Score'
            }]
        }]
        };
    }
  }

  radioChange(event, type){
    if(type=='opp'){
      let Total = Number(this.queControl.question_1_profebility_score.value) +
      Number(this.queControl.question_2_profebility_score.value) +
      Number(this.queControl.question_3_profebility_score.value) +
      Number(this.queControl.question_4_profebility_score.value) +
      Number(this.queControl.question_5_profebility_score.value) +
      Number(this.queControl.question_6_profebility_score.value) +
      Number(this.queControl.question_7_profebility_score.value);
      this.queControl.opportunity_profibility_score.setValue(Total);
      this.opp_pro_total = Total;
      this.finalTotal();
      this.SubTotal()
    }
    if(type=='risk'){
      let Total = Number(this.queControl.question_8_profebility_score.value) +
      Number(this.queControl.question_9_profebility_score.value) +
      Number(this.queControl.question_10_profebility_score.value) +
      Number(this.queControl.question_11_profebility_score.value) +
      Number(this.queControl.question_12_profebility_score.value) +
      Number(this.queControl.question_13_profebility_score.value);
      this.queControl.risk_profibility_score.setValue(Total);
      this.risk_pro_total = Total;
      this.finalTotal();
      this.SubTotal()
    }
  }

  radio2Change(event, type){
    if(type=='opp'){
      let Total = Number(this.queControl.question_1_impact_score.value) +
      Number(this.queControl.question_2_impact_score.value) +
      Number(this.queControl.question_3_impact_score.value) +
      Number(this.queControl.question_4_impact_score.value) +
      Number(this.queControl.question_5_impact_score.value) +
      Number(this.queControl.question_6_impact_score.value) +
      Number(this.queControl.question_7_impact_score.value);
      this.queControl.opportunity_impact_score.setValue(Total);
      this.opp_imp_total = Total;
      this.finalTotal();
      this.SubTotal();
    }
    if(type=='risk'){
      let Total = Number(this.queControl.question_8_impact_score.value) +
      Number(this.queControl.question_9_impact_score.value) +
      Number(this.queControl.question_10_impact_score.value) +
      Number(this.queControl.question_11_impact_score.value) +
      Number(this.queControl.question_12_impact_score.value) +
      Number(this.queControl.question_13_impact_score.value);
      this.queControl.risk_impact_score.setValue(Total);
      this.risk_imp_total = Total;
      this.finalTotal();
      this.SubTotal();
    }
  }

  finalTotal(){
    this.opp_final_total = this.opp_pro_total+this.opp_imp_total;
    this.opp_final_total_percent = Math.round(this.opp_final_total * 100 / 140);
    this.risk_final_total = this.risk_pro_total+this.risk_imp_total;
    this.risk_final_total_percent = Math.round(this.risk_final_total * 100 / 120);
  }

  SubTotal(){
    this.queControl.question_1_total.setValue(Number(this.queControl.question_1_profebility_score.value) +
      Number(this.queControl.question_1_impact_score.value));
    this.queControl.question_2_total.setValue(Number(this.queControl.question_2_profebility_score.value) +
      Number(this.queControl.question_2_impact_score.value));
    this.queControl.question_3_total.setValue(Number(this.queControl.question_3_profebility_score.value) +
      Number(this.queControl.question_3_impact_score.value));
    this.queControl.question_4_total.setValue(Number(this.queControl.question_4_profebility_score.value) +
      Number(this.queControl.question_4_impact_score.value));
    this.queControl.question_5_total.setValue(Number(this.queControl.question_5_profebility_score.value) +
      Number(this.queControl.question_5_impact_score.value));
    this.queControl.question_6_total.setValue(Number(this.queControl.question_6_profebility_score.value) +
      Number(this.queControl.question_6_impact_score.value));
    this.queControl.question_7_total.setValue(Number(this.queControl.question_7_profebility_score.value) +
      Number(this.queControl.question_7_impact_score.value));
    this.queControl.question_8_total.setValue(Number(this.queControl.question_8_profebility_score.value) +
      Number(this.queControl.question_8_impact_score.value));
    this.queControl.question_9_total.setValue(Number(this.queControl.question_9_profebility_score.value) +
      Number(this.queControl.question_9_impact_score.value));
    this.queControl.question_10_total.setValue(Number(this.queControl.question_10_profebility_score.value) +
      Number(this.queControl.question_10_impact_score.value));
    this.queControl.question_11_total.setValue(Number(this.queControl.question_11_profebility_score.value) +
      Number(this.queControl.question_11_impact_score.value));
    this.queControl.question_12_total.setValue(Number(this.queControl.question_12_profebility_score.value) +
      Number(this.queControl.question_12_impact_score.value));
    this.queControl.question_13_total.setValue(Number(this.queControl.question_13_profebility_score.value) +
      Number(this.queControl.question_13_impact_score.value));
  }

  public openPDF():void {
    let DATA = document.getElementById('htmlData');

   html2canvas(DATA).then(canvas => {
      // Few necessary setting options
      var margin = 3;
      var imgWidth = 160-margin*2;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 10;
      //pdf.text("Project Leader Characteristics", 20, 20);
      pdf.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight)
      pdf.setFontSize(30);
      pdf.setFont("times");
      //pdf.setFontType("bold");
      pdf.setTextColor(76, 130, 212);
      //pdf.fillStyle("rgba(76, 130, 212,.2)");
      //pdf.text('VTTouch', 100, imgHeight/2);
      pdf = this.addWaterMark(pdf);
      pdf.save(this.userFileName+'.pdf');
      //pdf.save('test.pdf'); // Generated PDF
    });
  }

  addWaterMark(doc) {
    var totalPages = doc.internal.getNumberOfPages();
      doc.setTextColor(150);
      doc.text(120, doc.internal.pageSize.height/3, 'VT-Touch');
      return doc;
  }

}
