import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { AuthService } from 'src/app/services/auth.service';
import { LearningService } from 'src/app/services/learning.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-learning-list',
  templateUrl: './learning-list.component.html',
  styleUrls: ['./learning-list.component.css']
})
export class LearningListComponent implements OnInit {

  learningList;
  public loading: boolean;

  constructor(private learnService: LearningService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {
    this.getAllLearing();
  }

  getAllLearing(){
    this.learnService.getAll().subscribe((res: any)=>{
      if(res.status){
        this.learningList = res.data;
      }else{
        this.toastr.error(res.message, "Error");
      }
    },
     err=> {
      this.toastr.error(err, "Error");
    });
  }

  editLearning(id){
    this.router.navigate(['/admin/learning-edit/'+id]);
  }

  deleteLearning(id){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        const formData = new FormData();
        formData.append('learning_id', id);
        this.learnService.delete(formData).subscribe((res:any)=>{
          this.loading = false;
          if(res.status) {
            this.toastr.success('Data deleted successfully', "Success");
            this.getAllLearing();
          } else {
            this.toastr.error(res.message, "Error");
          }
        }, err=> {
          this.toastr.error(err, "Error");
        })
      }
    })
    
  }

  backClicked() {
    this._location.back();
  }

}
