<app-header></app-header>
<app-sidebar></app-sidebar>
<!--12 may work-->
<!--12 may work-->
<div id="main-content">
   <div class="container-fluid">
      <div class="block-header top-head-vt">
         <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12">
               <ul class="breadcrumb vt-a">
                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                  <li class="breadcrumb-item"><a [routerLink]="['/grow/job-search']">Job Search</a></li>
                  <li class="breadcrumb-item">Job Details</li>
               </ul>
            </div>
         </div>
      </div>
      <div class="row" *ngIf="jobDetails">
         <div class="col-lg-12 col-md-12 col-sm-12">
            <div id="myHeader">
               <div class="row mb-3 justify-content-right">
                  <div class="col-md-12 RightButtons">
                     <div style="float: right;" class="m-r-5 Export">
                        <button *ngIf="jobDetails.is_fav == 0" type="button" class="btn btn-Favourites" (click)="favouriteAddRemove(jobDetails.id)">Add this Favourites  <i class="icon-heart"></i> </button>
                        <button *ngIf="jobDetails.is_fav == 1" type="button" class="btn btn-Favourites active" (click)="favouriteAddRemove(jobDetails.id)">Add this Favourites  <i class="icon-heart"></i> </button>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card crd-brd">
               <div class="main-profl row">
                  <div class="col-md-7 Rightline">
                     <div class="img-prrt1 clearfix">
                        <div class="i-pr1">
                           <img *ngIf="jobDetails.company.logo" src="{{jobDetails.company.logo}}" alt="User Image" class="rounded-circle" />
                           <img *ngIf="!jobDetails.company.logo" src="./assets/assets/images/user.png" alt="User Image" class="rounded-circle" />
                        </div>
                        <div class="contehnt-pr2">
                           <div class="indus-content">
                              <h3 class="hd-con">
                                 {{ jobDetails.company.company_name }}
                              </h3>
                              <p>{{ jobDetails.job_name }}</p>
                              <p class="icn-indus">
                                 <i class="fa fa-envelope"></i>
                                 <span>{{ jobDetails.company.company_email }}</span>
                              </p>
                              <p class="icn-indus ">
                                 <i class="fa fa-map-marker" aria-hidden="true" style="vertical-align: baseline;"></i>
                                 <span class="">{{ jobDetails.company.state }}, {{ jobDetails.company.country }}</span>
                              </p>
                              <p class="ic-img-cn">
                                 <a href="{{ jobDetails.company.facebook_url }}" target="_blank"><img src="./assets/assets/images/fb-hvr.png"></a>
                                 <a href="{{ jobDetails.company.linkedin_url }}" target="_blank"><img src="./assets/assets/images/lnkin-hvr.png"></a>
                                 <a href="{{ jobDetails.company.twitter_url }}" target="_blank"><img src="./assets/assets/images/twit-hvr.png"></a>
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-5">
                     <div class="indus-content">
                        <ul>
                           <li>
                              <span>Job Id':</span>
                              <span class="rightspan">#{{ jobDetails.id }}</span>
                           </li>
                           <li>
                              <span>Job Location:</span>
                              <span class="rightspan">{{ jobDetails.job_location }}</span>
                           </li>
                           <li>
                              <span>Salary:</span>
                              <span class="rightspan">{{ jobDetails.salary }}</span>
                           </li>
                           <li>
                              <span>Industry:</span>
                              <span class="rightspan">{{ jobDetails.company.industry.title }}</span>
                           </li>
                           <li>
                              <span>Published Date:</span>
                              <span class="rightspan">{{ jobDetails.published_date | date:'longDate'}}</span>
                           </li>
                           <li>
                              <span>End Date:</span>
                              <span class="rightspan">{{ jobDetails.end_date | date:'longDate'}}</span>
                           </li>
                        </ul>
                        <div *ngIf="jobDetails.is_apply == 0"><button type="button" class="btn pbtn"  (click)="open(ApplyModal)">Apply Now</button></div>
                        <ng-template #ApplyModal let-modal>
                           <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Application Form</h4>
                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                              </button>
                           </div>
                           <div class="modal-body  save-box">
                              <form [formGroup]="ApplyJobForm" enctype="multipart/form-data">
                                 <div class="row">
                                    <div class="col-md-6 form-group">
                                       <label>Job Title:</label>
                                       <input class="form-control" type="text" formControlName="job_title" placeholder="Enter your job title"/>
                                       <span class="text-danger" *ngIf="control.job_title.dirty && control.job_title.hasError('required')"> Job Title field is requied.</span>
                                    </div>
                                    <div class="col-md-6 form-group">
                                       <label>Company:</label>
                                       <input class="form-control" type="text" formControlName="company_name" placeholder="Enter your company name"/>
                                       <span class="text-danger" *ngIf="control.company_name.dirty && control.company_name.hasError('required')"> Company Name field is requied.</span>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-6 form-group">
                                       <label>First Name:</label>
                                       <input class="form-control" type="text" formControlName="first_name" placeholder="Enter your First name"/>
                                       <span class="text-danger" *ngIf="control.first_name.dirty && control.first_name.hasError('required')"> First Name field is requied.</span>
                                    </div>
                                    <div class="col-md-6 form-group">
                                       <label>Last Name</label>
                                       <input class="form-control" type="text" formControlName="last_name" placeholder="Enter your last name" />
                                       <span class="text-danger" *ngIf="control.last_name.dirty && control.last_name.hasError('required')"> Last Name field is requied.</span>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-12 form-group">
                                       <label>Cover Letter</label>
                                       <angular-editor [placeholder]="'Enter text here...'" formControlName="cover_letter" [config]="config">
                                       </angular-editor>
                                       <span class="text-danger" *ngIf="control.cover_letter.dirty && control.cover_letter.hasError('required')"> Cover Letter field is requied.</span>
                                    </div>
                                 </div>
                                 <div class="row justify-content-center mb-3">
                                    <div class="col-md-6">
                                       <div class="file-upload">
                                          <h6 class="mb-3">Add a CV to your job application</h6>
                                          <div class="file-select">
                                             <div class="file-select-button" id="fileName">Choose File</div>
                                             <div class="file-select-name" id="noFile">No file chosen...</div>
                                             <input type="file" name="chooseFile" id="chooseFile" formControlName="cv" (change)="onFileChange($event)" accept=".pdf, .docx">
                                             <span class="text-danger" *ngIf="control.cv.dirty && control.cv.hasError('required')"> CV field Required.</span>
                                          </div>
                                          <p class="mt-2">(Files must be in pdf or doc format and not exceed 2Mb)</p>
                                       </div>
                                    </div>
                                 </div>
                                 <input class="form-control" type="hidden" formControlName="id" />
                                 <button class="btn btn-danger cansl-vtbt" type="button" (click)="modal.close('Save click')">
                                 Cancel
                                 </button>
                                 <button type="submit" class="btn btn-primary m-l-5 sve-vtbt" (click)="applyForJob()" [disabled]="!ApplyJobForm.valid">Save</button>
                              </form>
                           </div>
                        </ng-template>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="JobDetails-Tabs" *ngIf="jobDetails">
          <div class="jobTabs">
         <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active show" data-toggle="tab" href="#Company" >Description Of The Company</a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Role" >Summary Of The Role</a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Specifications" >Personal Specifications for Ideal Candidate</a></li>
         </ul>
        </div>
         <div class="tab-content">
            <div class="tab-pane active" id="Company">
               <div class="row clearfix">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                     <div class="card card-experiences-vt " >
                        <h5>Description Of The Company</h5>
                        <div class="card-body">
                           <div class="col-md-12 col-sm-12 Description">
                              <p [innerHTML]="jobDetails.company.company_description"></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!--./end Description-->
            <div class="tab-pane" id="Role">
               <div class="row clearfix">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                     <div class="card card-experiences-vt " >
                        <h5>Summary Of The Role</h5>
                        <div class="card-body">
                           <div class="col-md-12 col-sm-12 Description">
                              <p [innerHTML]="jobDetails.role_summary"></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!--./end Summary-->
            <div class="tab-pane" id="Specifications">
               <div class="row clearfix">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                     <div class="card card-experiences-vt " >
                        <h5>Personal Specifications for Ideal Candidate</h5>
                        <div class="card-body">
                           <div class="col-md-12 col-sm-12 Description">
                              <p [innerHTML]="jobDetails.candidate_personal_specifications"></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!--./end Specifications-->
         </div>
      </div>
   </div>
</div>