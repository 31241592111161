import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import{ GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class GrowService {

  constructor(
    private http: HttpClient
  ) { }

  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getAllIndustry() {
    return this.http.get(`${this.url}/get_all_industries`, this.httpOptions);
  }

  getCompanies() {
    return this.http.post(`${this.url}/companies/get_all`, this.httpOptions);
  }

  addCompany(req) {
    return this.http.post(`${this.url}/companies/store`, req);
  }

  createJob(req) {
    return this.http.post(`${this.url}/job/store`, req);
  }

  getAllJobs(req) {
    return this.http.post(`${this.url}/job/get_all`, req);
  }

  fetchJob(req) {
    return this.http.post(`${this.url}/job/get_job_by_id`, req);
  }

  updateJob(req) {
    return this.http.post(`${this.url}/job/update`, req);
  }

  deleteJob(req) {
    return this.http.post(`${this.url}/job/delete`, req);
  }

  favouriteAddRemove(req) {
    return this.http.post(`${this.url}/job/add_to_fav_job`, req);
  }

  applyForJob(req) {
    return this.http.post(`${this.url}/job/apply_job`, req);
  }

  getJobApplications(req) {
    return this.http.post(`${this.url}/job/get_apply_job`, req);
  }

}
