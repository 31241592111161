<app-header></app-header>
<app-sidebar></app-sidebar>


<div id="main-content" >
  <div class="container-fluid" infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollUpDistance]="2"
        [infiniteScrollThrottle]="300"
        [fromRoot]="true"
        (scrolled)="onScroll($event)">

    <div class="block-header top-head-vt">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-sm-12">
          <!-- <h2>{{roomDetail.room_name}}</h2> -->
          <ul class="breadcrumb vt-a">
            <li class="breadcrumb-item"><a><i class="icon-home"></i></a></li>
            <li class="breadcrumb-item active">{{roomDetail.room_name}}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="cht-commet"
        >
          <div class="row">
            <div class="col-md-12 m-b-5">
              <div class="cmt-parts clearfix">
                <div class="coment-hdg clearfix">
                  <img _ngcontent-pmd-c132="" [src]="userProfile" class="img-cmtlft">
                  <div class="content-cmt">
                    <form [formGroup]="addForm" enctype="multipart/form-data">
                      <div class="form-group" *ngIf="!isFreeTrial">
                        <!-- <input type="text" class="form-control" id=""
                          placeholder="Write Your message" formControlName="post_description"> -->
                        <textarea name="" class="form-control post-style" placeholder="What's on your mind?" formControlName="post_description">
                        </textarea>
                        
                        <span class="text-danger"
                          *ngIf="control.post_description.dirty && control.post_description.hasError('required')">
                          This field is requied.
                        </span>
                        <div class="dealroom_media">
                            <img class="dr-image media" [src]="postImgURL" height="200" *ngIf="postImgURL">
                            <video class="dr-video media" width="200" height="auto" controls *ngIf="postVideoURL">
                                <source [src]="postVideoURL">
                                Your browser does not support the video tag.
                            </video>
                            <img class="dr-doc media" [src]="postDocURL" height="200" *ngIf="postDocURL">
                        </div>
                        <input type="file" (change)="onFileChange($event)" #fileInput
                          style="display: none;" accept="image/*">
                        <input type="file" (change)="onVideoChange($event)" #fileVideo
                          style="display: none;" accept="video/*">
                        <input type="file" (change)="onDocChange($event)" #fileDocument style="display: none;"
                            accept="application/pdf,application/vnd.ms-excel" >
                        <div class="input-icnmng comment">
                          <img src="./assets/assets/images/send.png" (click)="submit()">
                        </div>
                        <div class="emoji-prt">
                            <emoji-picker *ngIf="showEmojiPicker" class="light emoji-container" (emoji-click)="addEmoji($event)"></emoji-picker>
                            <span>
                                <i class="fa fa-smile-o" (click) ="toggleEmojiPicker()" aria-hidden="true"></i>
                                <i class="fa fa-camera " [ngClass]="{'emo-color': isPostImageAdd}" aria-hidden="true" (click)="fileInput.click()"></i>
                                <i class="fa fa-video-camera" [ngClass]="{'emo-color': isPostVideoAdd}" aria-hidden="true" (click)="fileVideo.click()"></i>
                                <i class="fa fa-paperclip" [ngClass]="{'emo-color': isPostDocAdd}" aria-hidden="true" (click)="fileDocument.click()"></i>
                            </span>
                        </div>
                      </div>

                      <div class="form-group" *ngIf="isFreeTrial">
                        <!-- <input type="text" class="form-control" id=""
                          placeholder="Write Your message" formControlName="post_description"> -->
                          <button type="button" class="FreePlanButton" (click)="OpenPlanPopup(PlanModal)"> 
                               <textarea name="" class="form-control post-style" placeholder="What's on your mind?" disabled >Write You Comment
                        </textarea></button>
                      
                        
                        <span class="text-danger"
                          *ngIf="control.post_description.dirty && control.post_description.hasError('required')">
                          This field is requied.
                        </span>
                        <input type="file" style="display: none;" accept="image/*" disabled>
                        <input type="file" style="display: none;" accept="video/*" disabled>
                        <input type="file" style="display: none;" accept="application/pdf,application/vnd.ms-excel" disabled>
                        <div class="input-icnmng">
                          <img src="./assets/assets/images/send.png">
                        </div>
                        <div class="emoji-prt">
                            <span>
                                <button type="button" class="FreePlanButtonicon"(click)="OpenPlanPopup(PlanModal)"><i class="fa fa-smile-o" aria-hidden="true"></i>
                                <i class="fa fa-camera " [ngClass]="{'emo-color': isPostImageAdd}" aria-hidden="true" ></i>
                                <i class="fa fa-video-camera" [ngClass]="{'emo-color': isPostVideoAdd}" aria-hidden="true"></i>
                                <i class="fa fa-paperclip" [ngClass]="{'emo-color': isPostDocAdd}" aria-hidden="true"></i></button>
                            </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

        </div><!--./row-->
    </div><!--./cht-commet-->



    <section *ngFor="let post of posts;let index = index"

        class="search-results">
      <div class="cht-commet">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="main-chatprt">
                    <div class="hed-chat">
                        <img src="{{ post.user?.profile_pic }}">
                        <div class="dots-icon" *ngIf="post.is_user_post">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                            <ul class="edit-ul" >
                                <li (click)="EditPost(post, PostModal)">Edit</li>
                                <li (click)="DeletePost(post)">Delete</li>
                            </ul>
                            <!-- <a href="javascript:" class="pull-right" (click)="EditPost(post, PostModal)" *ngIf="post.id">
                                <i class="fa fa-pencil"></i>
                            </a>
                            <a href="javascript:" class="pull-right m-r-5" (click)="DeletePost(post)" *ngIf="post.id">
                                <i class="fa fa-trash"></i>
                            </a> -->
                        </div>
                        <div class="hd-cht234">
                            <h3>
                                {{ post.user?.name }}
                                <span class="network-i">
                                <i class="fa fa-circle" aria-hidden="true"></i>
                                {{ post.dealroom?.dealroom_name }}
                                </span>
                            </h3>
                            <!-- <p class="fv-psz">but also the leap into electronic typesetting,</p> -->
                            <p class="chat-time-glb">
                                <span>{{ post.created_at }}</span>
                                <i class="fa fa-globe" aria-hidden="true"></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-4">
                <div class="chat-content">
                    <p [innerHTML]="post.post_description"></p>
                </div>
            </div>
            <div class="col-md-12 mt-4 text-center" *ngIf="post.post_image">
                <div class="clearfix">
                    <img [src]="post.post_image" alt="..." class="img-thumbnail rounded  mx-auto d-block"
                width="400" height="400" onError="this.src='./assets/assets/images/user.png'">
                </div>
            </div>
            <div class="col-md-12 mt-4 text-center" *ngIf="post.post_video">
                <div class="video mx-auto d-block">
                    <video controls #videoPlayer width="420" height="340" >
                        <source [src]="post.post_video" type="video/mp4" />
                        Browser not supported
                    </video>
                </div>
            </div>
            <div class="col-md-12 mt-4">
                <div class="chat-content m-t-5" *ngIf="post.post_document">
                    <a [href]="post.post_document" target="_blank" rel="noopener noreferrer" [download]="post.document_name">{{ post.document_name }}</a>
                </div>
            </div>
            <div class="col-md-12 bdr-comment">
                <div class=" row">
                    <div class="col-md-4">
                        <div class="lik-fv" >
                            <i class="fa fa-thumbs-up cursor-p" [ngClass]="{'isLike':post.is_user_like}" aria-hidden="true"
                            (click)="UserLike(post.id, post.is_user_like, 'post')"></i>
                            Like <span> {{post.like_count}}</span>
                            <!-- <i aria-hidden="true" class="fa fa-circle"></i> -->
                            <!-- <span>{{post.views}} View</span> -->
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="cmt-msg cursor-p" (click)="showSubItem(index, post)" [ngClass]="{'isMsgOp':visibleIndex === index}"> <!-- cmt-msg -->
                            <!-- <img src="./assets/assets/images/Comment.png"> -->
                            <i aria-hidden="true" class="fa fa-comments"></i>
                            <span>Comments ({{post.comment_count}})</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="follow-star">
                            <span class="cursor-p" [ngClass]="{'fl-star-spn':post.is_user_follow_post}" (click)="UserFollow(post.id, post.is_user_follow_post)">
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </span>
                            <span> Follow This Post</span>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="visibleIndex === index">

                <div class="col-md-12">
                    <div class="cmt-parts clearfix">
                        <div class="coment-hdg clearfix">
                            <img [src]="userProfile" class="img-cmtlft">
                            <div class="content-cmt">
                                <form [formGroup]="commentForm" enctype="multipart/form-data" (keyup.enter)="SubmitComment(post.id)">
                                    <div class="form-group" *ngIf="!isFreeTrial">
                                        <input type="text" class="form-control"
                                        placeholder="Write a comment..." formControlName="description">
                                        <span class="text-danger"
                                          *ngIf="CControl.description.dirty && CControl.description.hasError('required')">
                                          This field is requied.
                                        </span>
                                        <input type="file" formControlName="post_image" (change)="onCommentImage($event)" #fileComment style="display: none;"
                                            accept="image/*">
                                        <input type="hidden" class="form-control" formControlName="post_id">
                                        <div class="input-icnmng">
                                            <img src="./assets/assets/images/send.png" (click)="SubmitComment(post.id)">
                                            <span>
                                                <emoji-picker *ngIf="showCommentEmojiPicker" class="light emoji-container" (emoji-click)="addCommentEmoji($event)"></emoji-picker>
                                                <i class="fa fa-smile-o" (click) ="toggleCommentEmojiPicker()" aria-hidden="true"></i>
                                                <i class="fa fa-camera" [ngClass]="{'emo-color': isCommentImageAdd}" aria-hidden="true" (click)="fileComment.click()"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group"  *ngIf="isFreeTrial">
                                        <button type="button" class="FreePlanButton" (click)="OpenPlanPopup(PlanModal)">  <input type="text" class="form-control"
                                        placeholder="What's on your mind?" disabled></button>
                                       
                                        <input type="hidden" class="form-control" formControlName="post_id">
                                        <div class="input-icnmng">
                                            <img src="./assets/assets/images/send.png" >
                                            <span>
                                                <button type="button" class="FreePlanButtonicon" (click)="OpenPlanPopup(PlanModal)"> 
                                                    <i class="fa fa-smile-o" aria-hidden="true"></i>
                                                    <i class="fa fa-camera" [ngClass]="{'emo-color': isCommentImageAdd}" aria-hidden="true" (click)="fileComment.click()"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--<div class="most-rvnt">
                        <a href="">Most relavent
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </a>
                    </div>-->
                </div>

                <!-- <div class="col-md-12 down-cmtt mt-4" *ngFor="let comment of post.comment">
                    <div class="cmt-parts clearfix">
                        <div class="coment-hdg clearfix">
                            <img [src]="comment.user_pic" class="img-cmtlft" onError="this.src='./assets/assets/images/user.png'">
                            <div class="content-cmt">

                                <p class="most-dwnhr">
                                    <span>{{comment.user_name}}</span>
                                    <i aria-hidden="true" class="fa fa-circle"></i>
                                    <span>{{comment.created_at}}</span>
                                </p>
                                <p class="lorem-cmnt">
                                    {{comment.description}}
                                </p>
                                <p class="lorem-cmnt" *ngIf="comment.post_image">
                                    <img [src]="comment.post_image" alt="..." class="img-thumbnail rounded mx-auto d-block" width="200" height="200"
                                        onError="this.src='./assets/assets/images/user.png'">
                                </p>
                                <div class="lik-fv like-mn">
                                    <i class="fa fa-thumbs-up" [ngClass]="{'isLike':comment.is_user_like}" aria-hidden="true"
                                    (click)="UserLike(comment.id, comment.is_user_like, 'comment', post.id)" style="cursor: pointer;"></i>
                                    Like <span> {{comment.like_count}}</span>
                                    <i aria-hidden="true" class="fa fa-circle"></i>
                                    <span>10 View</span>
                                </div>
                                <div class="dot-incm" *ngIf="comment.is_user_comment">
                                    <i aria-hidden="true" class="fa fa-ellipsis-v"></i>
                                    <ul class="ul0nnhver">
                                        <li  (click)="EditComment(comment, CommentModal)" *ngIf="comment.is_user_comment">Edit</li>
                                        <li (click)="DeleteComment(comment)" *ngIf="comment.is_user_comment">Delete</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="col-md-12 down-cmtt mb-3" >
                    <div class="cmt-parts clearfix"  *ngFor="let comment of comments">
                        <div class="coment-hdg clearfix">
                            <img [src]="comment.user_pic" class="img-cmtlft" onError="this.src='./assets/assets/images/user.png'">
                            <div class="content-cmt">

                                <p class="most-dwnhr">
                                    <span>{{comment.user_name}}</span>
                                    <i aria-hidden="true" class="fa fa-circle"></i>
                                    <span>{{comment.created_at}}</span>
                                </p>
                                <p class="lorem-cmnt" [innerHTML]="comment.description">
                                </p>
                                <p class="lorem-cmnt" *ngIf="comment.post_image">
                                    <img [src]="comment.post_image" alt="..." class="img-thumbnail rounded mx-auto d-block" width="400" height="400"
                                        onError="this.src='./assets/assets/images/user.png'">
                                </p>
                                <div class="lik-fv like-mn">
                                    <i class="fa fa-thumbs-up" [ngClass]="{'isLike':comment.is_user_like}" aria-hidden="true"
                                    (click)="UserLike(comment.id, comment.is_user_like, 'comment', post.id)" style="cursor: pointer;"></i>
                                    Like <span> {{comment.like_count}}</span>
                                    <!-- <i aria-hidden="true" class="fa fa-circle"></i>
                                    <span>10 View</span> -->
                                </div>
                                <div class="dot-incm" *ngIf="comment.is_user_comment">
                                    <i aria-hidden="true" class="fa fa-ellipsis-v"></i>
                                    <ul class="ul0nnhver">
                                        <li  (click)="EditComment(comment, CommentModal, post.id)" *ngIf="comment.is_user_comment">Edit</li>
                                        <li (click)="DeleteComment(comment)" *ngIf="comment.is_user_comment">Delete</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 load-more mt-4 text-center m-b-10" *ngIf="isCommentPageShow && post.comment_count>0">
                    <a class="cursor-p" (click)="getCommentByPost(post.id, commentPage, post.comment_count);">Load More Comments</a>
                </div>
            </ng-container>

        </div>
        <!--./row-->
      </div>
      <!--./cht-commet-->
    </section>

    <div class="col-md-12 load-more mt-4 text-center m-b-10" *ngIf="!isPaginationShow">
        No More Post found
        <!-- <a class="cursor-p" (click)="getAllPost(page);">Load More Views</a> -->
    </div>

    <ng-template #CommentModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit Comment</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body save-box content-cmt">
            <div *ngIf="isEdit">
                <form [formGroup]="commentEdit" enctype="multipart/form-data">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="What's on your mind?" formControlName="description">
                        <span class="text-danger"
                          *ngIf="CEditControl.description.dirty && CEditControl.description.hasError('required')">
                          This field is requied.
                        </span>
                        <input type="file" formControlName="post_image" (change)="onCommentImage($event, true)" #fileCommentEdit
                            style="display: none;" accept="image/*">
                        <input type="hidden" class="form-control" formControlName="comment_id">
                        <div class="input-icnmng">
                            <img src="./assets/assets/images/send.png" (click)="CommentUpdate(commentedPostId)">
                            <span>
                                <i class="fa fa-smile-o" aria-hidden="true"></i>
                                <i class="fa fa-camera" [ngClass]="{'emo-color': isCommentImageEdit}" aria-hidden="true" (click)="fileCommentEdit.click()"></i>
                                <!-- <i class="fa fa-paperclip" aria-hidden="true"></i> -->
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="" *ngIf="singleImage">
                            <img src="{{singleImage}}" width="400" height="400" class="img-thumbnail rounded mx-auto d-block">
                            <button class="btn btn-primary" type="button" (click)="DeleteImage(image, 'comment')"><i class="fa fa-trash"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>

    <ng-template #PostModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit Post</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body save-box">
            <div *ngIf="isPostEdit">
                <form [formGroup]="postEdit" enctype="multipart/form-data">
                    <div class="form-group">
                        <textarea name="" class="form-control  post-style" placeholder="What's on your mind?" formControlName="post_description">
                        </textarea>
                        <span class="text-danger"
                            *ngIf="PEditControl.post_description.dirty && PEditControl.post_description.hasError('required')">
                            This field is requied.
                        </span>
                        <input type="hidden" class="form-control" formControlName="post_id">
                        <input type="file" formControlName="post_image" (change)="onFileChange($event, true)" #PostImageTag
                            style="display: none;" accept="image/*">
                        <input type="file" formControlName="post_video" (change)="onVideoChange($event, true)" #PostVideoTag
                            style="display: none;" accept="video/*">
                        <input type="file" formControlName="post_document" (change)="onDocChange($event, true)" #PostDocTag
                            style="display: none;" accept="application/pdf,application/vnd.ms-excel">
                        <div class="input-icnmng">
                            <img src="./assets/assets/images/send.png" (click)="Update()">
                        </div>
                        <div class="emoji-prt">
                            <span>
                                <i class="fa fa-smile-o" aria-hidden="true" ></i>
                                <i class="fa fa-camera" [ngClass]="{'emo-color': isPostImageEdit}" aria-hidden="true" (click)="PostImageTag.click()"></i>
                                <i class="fa fa-video-camera" [ngClass]="{'emo-color': isPostVideoEdit}" aria-hidden="true" (click)="PostVideoTag.click()"></i>
                                <i class="fa fa-paperclip" [ngClass]="{'emo-color': isPostDocEdit}" aria-hidden="true" (click)="PostDocTag.click()"></i>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="" *ngIf="postImage">
                            <img src="{{postImage}}" width="400" height="400" class="img-thumbnail rounded mx-auto d-block">
                            <button class="btn btn-primary" type="button" (click)="DeleteImage('image', post)"><i class="fa fa-trash"></i></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="text-center" *ngIf="postVideo">
                            <div class="video mx-auto d-block">
                                <video controls #videoPlayer width="420" height="340">
                                    <source src="{{postVideo}}" type="video/mp4" />
                                    Browser not supported
                                </video>
                            </div>
                            <!-- <img src="{{postVideo}}" width="200" height="200" class="img-thumbnail rounded mx-auto d-block"> -->
                            <button class="btn btn-primary" type="button" (click)="DeleteImage('video', post)"><i class="fa fa-trash"></i></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="" *ngIf="postDocument">
                            <a [href]="postDocument" target="_blank" rel="noopener noreferrer" [download]="postDocumentName">{{
                                postDocumentName }}</a>
                            <button class="btn btn-primary m-l-5" type="button" (click)="DeleteImage('document', post)"><i
                                    class="fa fa-trash"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>

    <ng-template #PlanModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">You have to upgrade your plan</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
               <span aria-hidden="true">&times;</span>
             </button>
         </div>
         <div class="modal-body">
            <div class="Iconwarning-box mb-4">
               <span class="info-Iconwarning ">  <img src="./assets/assets/images/Iconwarning.svg" alt="PinkLogo"></span>
               <div class="Iconwarning-box-content">
                  <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
                     enjoying the benefits of all features by VTTouch. 
                  </p>
               </div>
            </div>
            <div class="row justify-content-center">
               <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                  <div class="single-price-plan mb-100">
                     <div class="price-plan-title">
                        <h4>{{singlePlan.plan_name}}
                        </h4>
                     </div>
                     <div class="price-plan-value">
                        <h2>£{{singlePlan.price}}</h2>
                        <p>{{singlePlan.duration}} {{singlePlan.duration_type}} DAYS</p>
                     </div>
                       <a href="javascript:;" class="Buttons-pink " *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Start Trial</a>
                       <a href="#" class="Buttons-pink " *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" (click)="modal.dismiss('Cross click')">Continue</a>
                  </div>
               </div>
               
               
            </div>
         </div>
    </ng-template>

    </div><!--./main-content-->
</div><!--./container-fluid-->




 <!-- Modal for plan upgrade -->
 <div class="Instructions-Modal modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog  modal-lg" role="document">
       <div class="modal-content">
          <div class="modal-header">
             <h4 class="modal-title" id="myModalLabel">You have to upgrade your plan</h4>
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
             <div class="Iconwarning-box mb-4">
                <span class="info-Iconwarning ">  <img src="./assets/assets/images/Iconwarning.svg" alt="PinkLogo"></span>
                <div class="Iconwarning-box-content">
                   <p>Your {{CurrentPlan.plan.duration}} {{CurrentPlan.plan.duration_type}} membership ({{CurrentPlan.plan.plan_name}}) is ending on {{CurrentPlan.expiry_date | date :'d MMMM,  y'}}. Add your payment details / renew to keep 
                      enjoying the benefits of all features by VTTouch. 
                   </p>
                </div>
             </div>
             <div class="row justify-content-center">
                <div class="col-12 col-md-4 col-lg-3" *ngFor="let singlePlan of planData">
                   <div class="single-price-plan mb-100">
                      <div class="price-plan-title">
                         <h4>{{singlePlan.plan_name}}
                         </h4>
                      </div>
                      <div class="price-plan-value">
                         <h2>£{{singlePlan.price}}</h2>
                         <p>{{singlePlan.duration}} {{singlePlan.duration_type}} DAYS</p>
                      </div>
                        <a href="#" class="Buttons-pink close" *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" data-dismiss="modal" aria-label="Close">Start Trial</a>
                        <a href="#" class="Buttons-pink close" *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]" data-dismiss="modal" aria-label="Close">Continue</a>
                   </div>
                </div>
                
                
             </div>
          </div>
       </div>
    </div>
 </div>