<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content">
  <div class="daskborad-left">
    <div class="container-fluid">
      <div class="block-header top-head-vt">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <ul class="breadcrumb vt-a">
              <li class="breadcrumb-item">
                <a [routerLink]="['/dashboard']">
                  <i class="icon-home"></i>
                </a>
              </li>
              <li class="breadcrumb-item">Dashboard</li>
            </ul>
          </div>
          <!--./col-lg-12 col-md-12 col-sm-12-->
        </div>
        <!--./row-->
        <div class="row">
          <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-vt MembersBox">
                  <div class="header">
                    <form class="AllMembers">
                      <select name="View All Members">
                        <option value="" selected="selected">View All Members </option>
                        <option value="">dummy</option>
                        <option value="">dummy</option>
                      </select>
                    </form>
                  </div>
                  <div class="body">
                    <div class="container">
                      <ul class="MembersList" *ngIf="dashboardCount">
                        <li>
                          <a href="javascript:">{{dashboardCount.total_members}}</a>
                          <p>Total <br>Members </p>
                        </li>
                        <li>
                          <a href="javascript:">{{dashboardCount.total_active_subscription}}</a>
                          <p>Active <br>Subscriptions </p>
                        </li>
                        <li>
                          <a href="javascript:">{{dashboardCount.total_memberships_rnewal}}</a>
                          <p>Memberships <br> Renewal </p>
                        </li>
                        <li>
                          <a href="javascript:">
                            <sup>€</sup>{{dashboardCount.total_revenue}}
                          </a>
                          <p>Total <br> Revenue </p>
                        </li>
                        <li>
                          <a href="javascript:">{{dashboardCount.request_to_join_rooms}}</a>
                          <p>Request to <br> Join Rooms </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="table-responsive roomTbale">
                  <table class="table align-middle mb-0">
                    <thead>
                      <tr>
                        <th>Deal Room Join Request</th>
                        <th class="text-center">Room Name</th>
                        <th class="text-center">
                          <a routerLink="/admin/dealroom"> Go to Rooms</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngIf="dealRoomRequest">
                      <tr *ngFor="let request of dealRoomRequest">
                        <td>
                          <div class="content-wrapper">
                            <div class="userImage-left mr-3">
                              <img width="40" class="rounded-circle" src="{{request.profile_pic}}" alt="">
                            </div>
                            <div class="content-left flex2">
                              <div class="Nameheading">{{request.name}}</div>
                              <div class="user-subheading">{{request.industry_title}}</div>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">{{request.room_name}}</td>
                        <td class="text-center">
                          <button class="btn  btnSame-w btnApprove" *ngIf="request.status == 0" (click)="JoinUserToRoom(request.user_id, request.deal_room_id)">Approve</button>
                          <button type="button" class="btn  btnSame-w btnApproved" *ngIf="request.status == 1">Approved</button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!dealRoomRequest">
                      <tr>
                        <td colspan="3">
                          No request found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!--./col-lg-6 col-md-12 col-sm-12-->
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 mb-1">
            <div class="card card-vt-right ">
              <div class="header">
                <h2>Events <a [routerLink]="['/event-list']">View All</a>
                </h2>
              </div>
              <div class="body" *ngIf="upcomingEvents">
                <ul class="Eventlist-unstyled">
                  <li class="Un-view-list" *ngFor="let event of upcomingEvents">
                    <div class="Event-list">
                      <div class="Event-user-left mr-3">
                        <p> p</p>
                      </div>
                      <div class="Event-content-left">
                        <div class="user-heading">{{event.title}}</div>
                        <div class="Event-subheading mt-1 opacity-10">
                          <small class="mr-2">
                            <i class="fa fa-calendar "></i> {{event.formatted_event_date}} </small>
                          <small>
                            <i class="fa fa-clock-o"></i> {{event.formatted_event_time}} </small>
                        </div>
                      </div>
                      <div class="Event-content-right">
                        <p> view </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="body" *ngIf="!upcomingEvents"><p class="text-center mt-4">No upcoming events.</p></div>
            </div>
          </div>
          <!--./col-lg-6 col-md-12 col-sm-12-->
          <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
            <div class="table-responsive MamberTable">
              <table class="table align-middle mb-0 ">
                <thead>
                  <tr>
                    <th>Member Name</th>
                    <th class="text-center">Date of Joining</th>
                    <th class="text-center">Membership Type</th>
                    <th class="text-center">Last Seen On</th>
                    <th class="text-center">View Profile <div class="Filter">
                        <img src="./assets/assets/images/Filter-icon.png" alt="filter-icon" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="subscriptions">
                  <tr *ngFor="let subscription of subscriptions">
                    <td class="text-center">
                      <div class="text-left">{{subscription.user.name}}</div>
                    </td>
                    <td class="text-center">{{subscription.created_at | date:'longDate'}}</td>
                    <td class="text-center">
                      <button type="button" class="btn btnMember btnFreeTrail" *ngIf="subscription.plan.id == 1">{{subscription.plan.plan_name}}</button>
                      <button type="button" class="btn btnMember btnGold" *ngIf="subscription.plan.id == 2">{{subscription.plan.plan_name}}</button>
                      <button type="button" class="btn btnMember btnDiamond" *ngIf="subscription.plan.id == 3">{{subscription.plan.plan_name}}</button>
                      <button type="button" class="btn btnMember btnPlatinum" *ngIf="subscription.plan.id == 4">{{subscription.plan.plan_name}}</button>
                    </td>
                    <td class="text-center" *ngIf="subscription.user.last_login != null">{{subscription.user.last_login | date:'longDate'}}</td>
                    <td class="text-center" *ngIf="subscription.user.last_login == null">-</td>
                    <td class="text-center">
                      <button type="button" class="btn btnApproved">Can See Profile (need to ask)</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--./row-->
      </div>
      <!--./block-header top-head-vt-->
    </div>
    <!--./container-fluid-->
  </div>
  <!--./daskborad-left-->
</div>
<!---./main-content-->