import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DealroomService } from 'src/app/services/dealroom.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-room-user-request',
  templateUrl: './room-user-request.component.html',
  styleUrls: ['./room-user-request.component.css']
})
export class RoomUserRequestComponent implements OnInit {
  users;
  id;

  constructor(private roomService: DealroomService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.roomService.getAllRequest(this.id).subscribe((data: any)=>{
      if(data.status){
        console.log(data);
        this.users = data.users;
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });
  }

  backClicked() {
    this._location.back();
  }

  JoinUserToRoom(id,roomId) {
    /* console.log(id, roomId);
    return false; */
    Swal.fire({
      title: 'Are you sure want to add this User?',
      //text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, add it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let data = { 'deal_room_id':roomId, 'user_id':id}
        this.roomService.addSingleUser(data).subscribe((data: any)=>{
            if(data.status){
              this.toastr.success("User added Successfully", "Success");
              this.users = this.users.filter(item => item.id !== id);
            }else{
              this.toastr.error(data.message, "Error");
            }
        });
      }
    })
  }

}
