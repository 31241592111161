import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import{ GlobalConstants } from '../common/global-constants';

import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  httpOptionsImage = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      //'Accept': 'application/json'
    })
  }

  // invokeFirstComponentFunction = new EventEmitter();    
  // subsVar: Subscription;  

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  // onFirstComponentButtonClick() {    
  //   this.invokeFirstComponentFunction.emit();    
  // } 

  AddMessage(req){
    return this.http.post(`${this.url}/messages/AddMessages`, req, this.httpOptions);
  }

  getAll(page){
    return this.http.get(`${this.url}/messages/get_all_users_unread_messages/`+page, this.httpOptions);
  }

  get_message_by_sender_id(sender_id, page){
    return this.http.get(`${this.url}/messages/get_message_by_sender_id/`+sender_id+'/'+page, this.httpOptions);
  }

  get_message_by_session_id(session_id, page){
    return this.http.get(`${this.url}/messages/get_message_by_session_id/`+session_id+'/'+page, this.httpOptions);
    //return this.http.get(`${this.url}/messages/get_message_by_session_id_desc/`+session_id+'/'+page, this.httpOptions);
  }

  get_connected_user_list(){
    return this.http.get(`${this.url}/messages/get_connected_user_list`, this.httpOptions);
  }

  /**** Notification Service ****/
  getUnreadNotification(page){
    return this.http.get(`${this.url}/notifications/get_users_unread_notification/`+page, this.httpOptions);
  }
  getAllNotificationOfUser(page){
    return this.http.get(`${this.url}/notifications/get_all_notification_of_user/`+page, this.httpOptions);
  }

}
