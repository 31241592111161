import { Component, OnInit } from '@angular/core';
import { DealroomService } from 'src/app/services/dealroom.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  dealrooms;
  follow_post_count:any=0;

  constructor(private roomService: DealroomService,private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.roomService.getAll().subscribe((data: any)=>{
      if(data.status){
        this.dealrooms = data.dealrooms;
        this.follow_post_count = data.follow_post_count;
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });
  }

  SendJoinRequest(roomId){
    let data = { roomId:roomId};
    this.roomService.sendRequest(data).subscribe((res:any)=>{
      if(res.status) {
        this.toastr.success('Join request send successfully', "Success");
        let itemIndex = this.dealrooms.findIndex(item => item.id == roomId);
        this.dealrooms[itemIndex] = res.dealroom;
      } else {
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

}
