import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GrowService } from 'src/app/services/grow.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.css']
})
export class CompanyAddComponent implements OnInit {

  AddCompanyForm: FormGroup;
  industries:any = [];
  changeLogo:boolean = false;
  isAddMode:boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private growService: GrowService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.AddCompanyForm = this.formBuilder.group({
      company_name: ['', [Validators.required]],
      logo: ['', [Validators.required]],
      company_email: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      company_address: ['', [Validators.required]],
      industry_id: ['', [Validators.required]],
      facebook_url: ['', [Validators.required, Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?facebook\.com.*$')]],
      linkedin_url: ['', [Validators.required, Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com.*$')]],
      twitter_url: ['', [Validators.required, Validators.pattern('(https:\/\/twitter.com.*$)')]],
      company_description: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    //Get list of industries
    this.growService.getAllIndustry().subscribe((response: any) => {
      this.industries = response.industries;
    }, error => {
      this.toastr.error(error, "Error");
    });
  }

  get control() {
    return this.AddCompanyForm.controls;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      let fileSizeInKB = Math.round(file.size / 1025);
      if (fileSizeInKB >= 20080) {
        this.toastr.error("Allow only 20 mb file size", "Error");
        return;
      }
      this.changeLogo = true;
      this.control.logo.patchValue(file);
    }
  }

  addCompany() {
    if (this.AddCompanyForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('company_name', this.AddCompanyForm.get('company_name').value);
    formData.append('logo', this.AddCompanyForm.get('logo').value);
    formData.append('company_email', this.AddCompanyForm.get('company_email').value);
    formData.append('country', this.AddCompanyForm.get('country').value);
    formData.append('state', this.AddCompanyForm.get('state').value);
    formData.append('city', this.AddCompanyForm.get('city').value);
    formData.append('company_address', this.AddCompanyForm.get('company_address').value);
    formData.append('industry_id', this.AddCompanyForm.get('industry_id').value);
    formData.append('facebook_url', this.AddCompanyForm.get('facebook_url').value);
    formData.append('linkedin_url', this.AddCompanyForm.get('linkedin_url').value);
    formData.append('twitter_url', this.AddCompanyForm.get('twitter_url').value);
    formData.append('company_description', this.AddCompanyForm.get('company_description').value);
    
    this.growService.addCompany(formData).subscribe((response:any) => {
      if (response.status) {
        this.toastr.success('Company added successfully', "Success");
        this.router.navigateByUrl('admin/jobs');
        this.AddCompanyForm.reset();
      } else {
        this.toastr.error(response.message, "Error");
      }
    }, err=> {
      this.toastr.error(err, "Error");
    })
  }

}
