import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { AuthService } from 'src/app/services/auth.service';
import { DealroomService } from 'src/app/services/dealroom.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-admin-dealroom',
  templateUrl: './admin-dealroom.component.html',
  styleUrls: ['./admin-dealroom.component.css']
})
export class AdminDealroomComponent implements OnInit {
  dealrooms;

  constructor(private roomService: DealroomService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {
    this.roomService.getAll().subscribe((data: any)=>{
      if(data.status){
        //console.log(data);
        this.dealrooms = data.dealrooms;
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });
  }

  delete(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        //this.loading = true;
        this.roomService.delete(id).subscribe((data: any)=>{
            //this.loading = false;
            if(data.status){
              this.toastr.success("Blog Room Deleted Successfully", "Success");
              this.dealrooms = this.dealrooms.filter(item => item.id !== id);
            }else{
              this.toastr.error(data.message, "Error");
            }
        });
      }
    })
  }

  backClicked() {
    this._location.back();
  }

}
