<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Network</li>
                        <li class="breadcrumb-item"><a routerLink="/dashboard">Event List</a></li>
                      </ul>
            
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>Event List</h2>
                        <a routerLink="/event-add" class="btn pbtn color_cls">Add </a>
                    </div>
                    <div class="body">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <!-- <th scope="col">Image/Video</th> -->
                                    <th scope="col">Time</th>
                                    <th scope="col">Event Name</th>
                                    <th scope="col">Meeting Link</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="events?.length; else no_data_templ">
                                <tr *ngFor="let value of events; index as i">
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>
                                        {{ value.event_time }}
                                      
                                    </td>
                                    <!-- <td>
                                        <span *ngIf="value.image_video && value.file_name">
                                            <span *ngIf="value.image_video==1">
                                                <img src="{{value.file_name}}" width="50" height="50">
                                            </span>
                                            <span *ngIf="value.image_video==2">
                                                <video width="50" height="50" controls>
                                                    <source src="{{value.file_name}}" type="video/mp4">
                                                    Your browser does not support the video tag.
                                                  </video> 
                                            </span>
                                        </span>
                                        <span *ngIf="!value.image_video && !value.file_name">-</span>
                                    </td> -->
                                    <td>
                                        {{ value.event_date |date:'dd-MM-yyyy' }}
                                    </td>
                                    <td>
                                        {{ value.title }}
                                    </td>
                                    <td>
                                        <div [innerHTML]="replaceURLWithHTMLLinks(value.meeting_link)"></div>
                                    </td>
                                    <td>
                                        <button class="btn  pbtn " type="button" (click)="deleteEvent(value.id)" aria-controls="collapseExample">
                                            <i class="fa fa-trash"></i>
                                            <!-- <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> -->
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="events.length==0"> 
                                    <td colspan="3">No Data Found</td>
                                </tr>
                            </tbody>
                            <ng-template #no_data_templ>
                                <tr>
                                    <td colspan="3" class="text-center">No Data Found</td>
                                </tr>
                            </ng-template>
                        </table>
                    </div>
                    
                </div>
            </div>

        </div>


    </div>
</div>