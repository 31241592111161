import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import{ GlobalConstants } from '../../common/global-constants';

import { AngularEditorConfig } from '@kolkov/angular-editor';
//import { count } from 'console';
declare var $: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Location} from '@angular/common';
import { DataService } from "src/app/services/data.service";
import { ValidationService } from 'src/app/services/validation.service';

import {NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  editForm: FormGroup;
  countryForm: FormGroup;
  headlineForm: FormGroup;
  summaryForm: FormGroup;
  fullExpForm: FormGroup;
  socialForm: FormGroup;
  public loading: boolean;
  id: number;
  user: any;
  imageURL:string;
  sameUser:boolean = false;
  industryList: any = [];
  countryList: any = [];
  stateList:any = [];
  SelectedIndustry_id:string;
  Selectedcountry_id:string;
  Selectedstate_id:string;
  summary_title:string;
  industry_name:string;
  country_name:string;
  state_name:string;
  industry_id:number;
  profile_percentage:number;
  rate:number;

  monthList:any = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12
  ];
  /*yearList:any = [
    2000, 2001, 2002, 2003,2004, 2005, 2006, 2007, 2008, 2009, 2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021
  ]; */
  yearList:any = [];
  currentYear: number = new Date().getFullYear();

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '7rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      customClasses: [
        {
          name: 'bgcolor',
          class: 'bgcolor',
        },
      ]
  };

  items: number[] = [1];
  stars: number[] = [1,2,3,4,5,6,7,8,9,10];

  isIndustryShow: boolean = false;
  isCountryShow:boolean = false;
  isHeadlineShow:boolean = false;
  isfullExpShow:boolean = false;
  isSocialShow:boolean = false;

  public isSummaryShow = false;
  summary_description='';

  imageForm: FormGroup;
  isimageChange = false;

  minNum = 2000;

  expYearList:any = [];
  Seltotal_experience:number = null;

  modalReference: NgbModalRef;
  closeResult: string;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private _location: Location, private modalService: NgbModal,private dateService: DataService, ) { }

  ngOnInit(): void {
    if(!this.authService.isLogedin) {
      this.router.navigateByUrl('login');
    }

    for (let i = this.currentYear; i >= 1940; i--) {
      this.yearList.push(i);
    }
    for (let i = 1; i <= 20; i++) {
      let dataYears:any = [];
      dataYears['id'] = i;
      dataYears['name'] = i+" Year";
      this.expYearList.push(dataYears);
    }

   /*  $(".cls-btn").on("click", function () {
      $(this).closest('.card-header').find('.btn').trigger('click');
    }) */

   /*  $(".crd_rsletiv").on("click", "button", function () {
      if($(this).hasClass('collapsed')){
        $(this).closest('.card-header').find('.pls-btn').addClass('d-none');
        $(this).closest('.card-header').find('.cls-btn').removeClass('d-none');
      }else{
        $(this).closest('.card-header').find('.pls-btn').removeClass('d-none');
        $(this).closest('.card-header').find('.cls-btn').addClass('d-none');
      }
    }) */

   /*  $(".pls-btn").on("click", function () {
      $(this).closest('.card-header').find('.btn').trigger('click');
    }) */

    this.id = this.route.snapshot.params['id'];
    if(this.id==this.authService.user.id){
      this.sameUser=true;
      //console.log(this.authService.user);
    }else{
      this.sameUser=false;
      this.router.navigateByUrl('dashboard');
    }

    this.authService.getUserById(this.id).subscribe((data: any)=>{
      if(data.status){
        this.user = data.user;
        if(this.user){
          this.SelectedIndustry_id = this.user.industry_id;
          this.summary_title = this.user.summary_title;
          if(this.user.industry_id && this.user.industry){
            this.industry_name = this.user.industry.title;
          }
          if(this.user.country_id && this.user.state_id && this.user.country && this.user.state){
            this.country_name = this.user.country.name;
            this.state_name = this.user.state.name;
          }
          this.profile_percentage = this.user.profile_percentage;
          this.rate = this.user.rate;
        }
        if(this.user.profile_picture){
          let path =  GlobalConstants.user_profile_path;
          //console.log(this.user.profile_picture);
          this.imageURL = this.user.profile_picture;
        }
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });

    if(this.sameUser){
      this.authService.getAllIndustry().subscribe((data: any)=>{
        if(data.status){
          this.industryList = data.industries;
          /* if(this.user.industry_id){
            //this.industry_name = this.industryList[this.user.industry_id].title;
            //console.log(this.industry_name);
            //this.industry_name = this.user.industry_id;
          } */
        }else{
          this.toastr.error(data.message, "Error");
        }
      });
    }

    this.editForm = this.formBuilder.group({
        industry_id: ['', Validators.required],
    });
    this.imageForm = this.formBuilder.group({
        profile_image: [],
        id: [''],
    });
    //this.editor = new Editor();
    this.isSummaryShow = true;
    this.summaryForm = this.formBuilder.group({
        summary_description: ['', [Validators.required, Validators.maxLength(600)]],
    });
  }

  ngOnDestroy(): void {
    //this.editor.destroy();
  }

  backClicked() {
    this._location.back();
  }

  get profilePercentage(){
    return `${this.profile_percentage}%`;
  }

  get control() {
    return this.editForm.controls;
  }

  get ccontrol() {
    return this.countryForm.controls;
  }

  get hcontrol() {
    return this.headlineForm.controls;
  }

  get scontrol() {
    return this.summaryForm.controls;
  }

  get FExpControl() {
    return this.fullExpForm.controls;
  }


  submit() {
    if(this.editForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.addOrUpdateIndustry(this.editForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.SelectedIndustry_id = res.user.industry_id;
        if(res.user){
          this.user = res.user;
        }
        if(res.user.industry){
          this.industry_name = res.user.industry.title;
        }
        this.toastr.success('Industry updated successfully', "Success");
        this.isIndustryShow = false;
        this.getLatestUserDetails(this.user.id);
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  clickEvent(){
      this.isIndustryShow = !this.isIndustryShow;
  }

  cancelEvent(){
      this.isIndustryShow = false;
  }

  showCountry(){
      this.isIndustryShow = false;
      this.isCountryShow = !this.isCountryShow;
      if(this.isCountryShow){
        this.Selectedcountry_id = this.user.country_id;

        this.countryForm = this.formBuilder.group({
            country_id: ['', Validators.required],
            state_id:['',Validators.required]
        });
        this.authService.getAllCountry().subscribe((data: any)=>{
          if(data.status){
            this.countryList = data.countries;
          }else{
            this.toastr.error(data.message, "Error");
          }
        });

        if(this.user.country_id!=null){
          this.authService.getStates(this.user.country_id).subscribe(
            (data: any) => {
              if(data.status){
                this.stateList = data.states;
                this.Selectedstate_id = this.user.state_id;
              }else{
                //this.stateList = null;
                this.toastr.error(data.message, "Error");
              }
            }
          );
        }
      }
  }

  onChangeCountry(countryId: number) {
    if (countryId) {
      this.authService.getStates(countryId).subscribe(
        (data: any) => {
          if(data.status){
            this.stateList = data.states;
          }else{
            this.stateList = null;
            this.toastr.error(data.message, "Error");
          }
        }
      );
    } else {
      this.stateList = null;
    }
  }

  saveCountry() {
    if(this.countryForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.saveCountry(this.countryForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.Selectedcountry_id = res.user.country_id;
        this.Selectedstate_id = res.user.state_id;
        this.toastr.success('Country updated successfully', "Success");
        this.isCountryShow = false;
        if(res.user){
          this.user = res.user;
          this.getLatestUserDetails(this.user.id);
        }
        if(res.user.country){
          this.country_name = res.user.country.name;
        }
        if(res.user.state){
          this.state_name = res.user.state.name;
        }
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  cancelCountry(){
      this.isCountryShow = false;
  }

  showHeadline(){
    this.isHeadlineShow = !this.isHeadlineShow;
    if(this.isHeadlineShow){
      this.headlineForm = this.formBuilder.group({
          summary_title: ['', Validators.required],
      });
      this.summary_title = this.user.summary_title;
    }
  }

  cancelHeadline(){
    this.isHeadlineShow = false;
  }

  saveHeadline() {
    if(this.headlineForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.saveHeadline(this.headlineForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.summary_title = res.user.summary_title;
        this.toastr.success('Summary updated successfully', "Success");
        this.isHeadlineShow = false;
        this.getLatestUserDetails(res.user.id)
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  showFullExp(){
    this.isfullExpShow = !this.isfullExpShow;
    if(this.isfullExpShow){
      this.fullExpForm = this.formBuilder.group({
          total_experience: [this.user.total_experience, [Validators.required, Validators.min(1), Validators.max(50)]],
      });
      /* this.fullExpForm.patchValue(this.user.total_experience)
      this.FExpControl.total_experience.setValue(this.user.total_experience, {
        onlySelf: true
      }) */
      this.Seltotal_experience = this.user.total_experience;
    }
  }

  cancelFullExp(){
    this.isfullExpShow = false;
  }

  saveFullExp() {
    if(this.fullExpForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.saveFullExp(this.fullExpForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        //this.total_experience = res.user.total_experience;
        this.toastr.success('Full Experience updated successfully', "Success");
        this.isfullExpShow = false;
        this.getLatestUserDetails(res.user.id);
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  get socialControl() {
    return this.socialForm.controls;
  }

  showSocial(){
    this.isSocialShow = !this.isSocialShow;
    if(this.isSocialShow){
      
      this.socialForm = this.formBuilder.group({
          linkedin_id: ['', [Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com.*$')]],
          fb_id: ['', [Validators.pattern('(https:\/\/twitter.com.*$)')]],
      }, { validator: this.atLeastOne('linkedin_id','fb_id') });
      this.socialForm.patchValue({
        linkedin_id: this.user.linkedin_id,
        fb_id: this.user.fb_id,
      });
    }
  }

  cancelSocial(){
    this.isSocialShow = false;
  }

  saveSocial() {
    if(this.socialForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.saveSocial(this.socialForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        //this.total_experience = res.user.total_experience;
        this.toastr.success('Social details updated successfully', "Success");
        this.isSocialShow = false;
        this.getLatestUserDetails(res.user.id);
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  isSummaryEdit:boolean = false;

  showSummary(){
    this.commonHideOther('summary');

    if(this.isSummaryShow){
      /* this.summaryForm = this.formBuilder.group({
          summary_description: ['', Validators.required],
      }); */
      this.summary_description = this.user.summary_description;
    }
  }

  addSummary(modalId){
    this.isSummaryEdit = true;
    //this.singleExp = this.experiences[j];
    //this.singleExp['experience_id'] = this.experiences[j].id;
    this.summaryForm = this.formBuilder.group({
        summary_description: ['', [Validators.required, Validators.maxLength(600)]],
    });
    if(this.user.summary_description!=null){
      this.summary_description = this.user.summary_description;
      /* this.summaryForm.patchValue({summary_description : this.user.summary_description}) */
    }

    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveSummary() {
    if(this.summaryForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.saveHeadline(this.summaryForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        //this.summary_description = res.user.summary_description;
        this.toastr.success('Summary Description updated successfully', "Success");
        //this.isSummaryShow = false;
        this.getLatestUserDetails(res.user.id);
        this.JoinAndClose();
        /* if(res.user){
          this.user = res.user;
        }
        if(res.user.summary_description){
          this.summary_description = res.user.summary_description;
        } */
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  deleteSummary() {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.summaryForm.patchValue({
          summary_description: " "
        });

        this.authService.saveHeadline(this.summaryForm.value).subscribe((res:any)=>{
          if(res.status) {
            this.loading = false;
            this.toastr.success('Summary Description delete successfully', "Success");
            //this.isSummaryShow = false;
            if(res.user){
              //this.user = res.user;
              this.getLatestUserDetails(res.user.id);
              this.JoinAndClose();
              this.summary_description = "";
            }
            /* if(res.user.summary_description){
              this.summary_description = res.user.summary_description;
            } */
          } else {
            this.loading = false;
            this.toastr.error(res.message, "Error");
          }
        }, err=> {
          this.loading = false;
          this.toastr.error(err, "Error");
        })
      }
    })
  }

  cancelCollapse(collapseName){
    if(collapseName=='summary'){
      this.isSummaryShow = false;
    }else if(collapseName=='experience'){
      this.isExperienceShow = false;
    }else if(collapseName=='education'){
      this.isEducationShow = false;
    }else if(collapseName=='certificate'){
      this.isCertificateShow =false;
    }else if(collapseName=='achievement'){
      this.isAchievmentShow =false;
    }else{

    }
  }

  /*** experience ****/

  ExperienceForm: FormGroup;
  ExperienceAddForm: FormGroup;
  ExpEditForm: FormGroup;
  isExperienceShow:boolean = false;
  experiences = [];
  singleExp = [];
  isFirstExp = false;
  isNewExpShow = false;
  isEditExp = false;
  get expcontrol() {
    return this.ExperienceForm.controls;
  }

  get expAddControl() {
    return this.ExperienceAddForm.controls;
  }

  
  get expEditcontrol() {
    return this.ExpEditForm.controls;
  }

  showExperience(){
    this.commonHideOther('experience');
    if(this.isExperienceShow){
      this.authService.getExperience().subscribe((data: any)=>{
          if(data.status){
            if(data.experiences && data.experiences.length>0){
              this.experiences = data.experiences;
              this.isNewExpShow = true;
            }else{
              this.isFirstExp = true;
              this.isNewExpShow = false;
              this.addExperience();
            }
          }else{
            this.toastr.error(data.message, "Error");
          }
      });

      this.ExperienceForm = this.formBuilder.group({
          experience_field: this.formBuilder.array([]) ,
      });
    }
  }

  experience_field() : FormArray {
      return this.ExperienceForm.get("experience_field") as FormArray
  }
  newExperience(): FormGroup {
    console.log('form orpenm')
    return this.formBuilder.group({
        job_title: ['', Validators.required],
        company: ['', Validators.required],
        location: ['', Validators.required],
        start_month: ['', Validators.required],
        start_year: ['', [Validators.required]],
        end_month: ['', Validators.required],
        end_year: ['', Validators.required],
        description: ['', Validators.required],
        is_current: [''],
    }, { validators: ValidationService.DateCheker})
  }

  addExperience() {
    this.isFirstExp = true;
    this.isNewExpShow = false;
    this.experience_field().push(this.newExperience());
  }

  removeExperience(i:number) {
    this.experience_field().removeAt(i);
  }

  saveExperienceOld() {
    if(this.ExperienceForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.saveExperience(this.ExperienceForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Experience save successfully', "Success");
        //this.isExperienceShow = false;
        this.isFirstExp = false;
        this.getLatestUserDetails(this.user.id);
        let frmArray = this.ExperienceForm.get("experience_field") as FormArray;
        frmArray.clear();
        this.experiences = res.experience;
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  deleteExperience(id){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.authService.deleteExperience(id).subscribe((data: any)=>{
            this.loading = false;
            if(data.status){
              this.toastr.success("Experience Deleted Successfully", "Success");
              this.experiences = this.experiences.filter(item => item.id !== id);
              this.getLatestUserDetails(this.user.id);
            }else{
              this.toastr.error(data.message, "Error");
            }
        });
      }
    })
  }

  editExperience(id, j){
    this.isEditExp = true;
    this.singleExp = this.experiences[j];

    //this.singleExp['experience_id'] = this.experiences[j].id;
   /*  this.ExpEditForm = this.formBuilder.group({
        job_title: ['', Validators.required],
        company: ['', Validators.required],
        location: ['', Validators.required],
        start_month: ['', Validators.required],
        start_year: ['', [Validators.required]],
        end_month: ['', Validators.required],
        end_year: ['', [Validators.required]],
        description: ['', Validators.required],
        is_current: [''],
        id: ['']
    }); */
    this.ExpEditForm.patchValue(this.singleExp)
  }

  updateExperience(){
    if(this.ExpEditForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.updateExperience(this.ExpEditForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Experience updated successfully', "Success");
        //this.isExperienceShow = false;
        this.isEditExp = false;
        let itemIndex = this.experiences.findIndex(item => item.id == this.expEditcontrol.id.value);
        this.experiences[itemIndex] = res.experience;
        this.JoinAndClose();
        this.showExperience();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  editExperienceModal(id, j, modalId) {
    this.isEditExp = true;
    this.singleExp = this.experiences[j];
    let isCurrent = this.experiences[j].is_current;
    let validatinAdd = [];
    let isDisable =  false;
    if(isCurrent){
      validatinAdd = [];
      isDisable = true;
    }else{
      validatinAdd = [Validators.required];
      isDisable = false;
    }
    //this.singleExp['experience_id'] = this.experiences[j].id;
    this.ExpEditForm = this.formBuilder.group({
        job_title: ['', Validators.required],
        company: ['', Validators.required],
        location: ['', Validators.required],
        start_month: ['', Validators.required],
        start_year: ['', [Validators.required]],
        end_month: [{value:'' , disabled: isDisable}, validatinAdd],
        end_year: [{value:'' , disabled: isDisable}, validatinAdd],
        description: ['', Validators.required],
        is_current: [''],
        id: ['']
    }, { validators: ValidationService.DateCheker});
    this.ExpEditForm.patchValue(this.singleExp)

    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  JoinAndClose() {
    this.modalReference.close();
  }

  checkstateAdd(evnet, i, formType){
    if(formType=='education'){
      let arrayControl  = this.EducationForm.get("quantities") as FormArray;
      if(evnet.target.checked){
        arrayControl.at(i).get('completion_month').clearValidators();
        arrayControl.at(i).get('completion_year').clearValidators();
      }else{
        arrayControl.at(i).get('completion_month').setValidators(Validators.required);
        arrayControl.at(i).get('completion_year').setValidators(Validators.required);
      }
      arrayControl.at(i).get('completion_month').updateValueAndValidity();
      arrayControl.at(i).get('completion_year').updateValueAndValidity();
    }else if(formType=='certificate'){
      let arrayControl  = this.CertificateForm.get("certificates_field") as FormArray;
      if(evnet.target.checked){
        arrayControl.at(i).get('end_month').clearValidators();
        arrayControl.at(i).get('end_year').clearValidators();
      }else{
        arrayControl.at(i).get('end_year').setValidators(Validators.required);
        arrayControl.at(i).get('end_month').setValidators(Validators.required);
      }
      arrayControl.at(i).get('end_year').updateValueAndValidity();
      arrayControl.at(i).get('end_month').updateValueAndValidity();
    }else if(formType=='experience'){
      let arrayControl  = this.ExperienceForm.get("experience_field") as FormArray;
      //alert(arrayControl.at(i).get('end_year').value)
      if(evnet.target.checked){
        arrayControl.at(i).get('end_month').clearValidators();
        arrayControl.at(i).get('end_year').clearValidators();
      }else{
        arrayControl.at(i).get('end_year').setValidators(Validators.required);
        arrayControl.at(i).get('end_month').setValidators(Validators.required);
      }
      arrayControl.at(i).get('end_year').updateValueAndValidity();
      arrayControl.at(i).get('end_month').updateValueAndValidity();
    }
  }

  checkstate(event, formType){
    if(formType=='education'){
      //let ValueCheck = this.EduEditForm.get('completion_month').value;
      if(event.target.checked){
        this.EduEditForm.get('completion_month').clearValidators();
        this.EduEditForm.get('completion_year').clearValidators();
        this.EduEditForm.get('completion_month').disable();
        this.EduEditForm.get('completion_year').disable();
        this.EduEditForm.get('completion_month').patchValue(null);
        this.EduEditForm.get('completion_year').patchValue(null);
      }else{
        this.EduEditForm.get('completion_month').setValidators(Validators.required);
        this.EduEditForm.get('completion_year').setValidators(Validators.required);
        this.EduEditForm.get('completion_month').enable();
        this.EduEditForm.get('completion_year').enable();
        this.EduEditForm.get('completion_month').patchValue(this.singleEdu['completion_month']);
        this.EduEditForm.get('completion_year').patchValue(this.singleEdu['completion_year']);
      }
      this.EduEditForm.get('completion_month').updateValueAndValidity();
      this.EduEditForm.get('completion_year').updateValueAndValidity();
    }else if(formType=='certificate'){
      //let ValueCheck = this.CertEditForm.get('is_ongoing').value;
      if(event.target.checked){
        this.CertEditForm.get('end_month').clearValidators();
        this.CertEditForm.get('end_year').clearValidators();
        this.CertEditForm.get('end_month').disable()
        this.CertEditForm.get('end_year').disable()
        this.CertEditForm.get('end_month').patchValue(null)
        this.CertEditForm.get('end_year').patchValue(null)
      }else{
        this.CertEditForm.get('end_year').setValidators(Validators.required);
        this.CertEditForm.get('end_month').setValidators(Validators.required);
        this.CertEditForm.get('end_month').enable()
        this.CertEditForm.get('end_year').enable()
        this.CertEditForm.get('end_month').patchValue(this.singleCert['end_month'])
        this.CertEditForm.get('end_year').patchValue(this.singleCert['end_year'])
      }
      this.CertEditForm.get('end_year').updateValueAndValidity();
      this.CertEditForm.get('end_month').updateValueAndValidity();
    }else if(formType=='experience'){
      //let ValueCheck = this.ExpEditForm.get('is_current').value;
      if(event.target.checked){
        this.ExpEditForm.get('end_month').clearValidators();
        this.ExpEditForm.get('end_year').clearValidators();

        this.ExpEditForm.get('end_month').disable()
        this.ExpEditForm.get('end_year').disable()
        this.ExpEditForm.get('end_month').patchValue(null)
        this.ExpEditForm.get('end_year').patchValue(null)
      }else{
        this.ExpEditForm.get('end_year').setValidators(Validators.required);
        this.ExpEditForm.get('end_month').setValidators(Validators.required);
        this.ExpEditForm.get('end_month').enable()
        this.ExpEditForm.get('end_year').enable()
        this.ExpEditForm.get('end_month').patchValue(this.singleExp['end_month'])
        this.ExpEditForm.get('end_year').patchValue(this.singleExp['end_year'])
      }
      this.ExpEditForm.get('end_year').updateValueAndValidity();
      this.ExpEditForm.get('end_month').updateValueAndValidity();
    }
 }
  //isFirstExp = false;
  addExperienceModal(modalId){
    this.isFirstExp = true;
    this.ExpEditForm = this.formBuilder.group({
        job_title: ['', Validators.required],
        company: ['', Validators.required],
        location: ['', Validators.required],
        start_month: ['', Validators.required],
        start_year: ['', [Validators.required]],
        end_month: ['', Validators.required],
        end_year: ['', [Validators.required]],
        description: ['', Validators.required],
        is_current: [''],
    }, { validators: ValidationService.DateCheker});

    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveExperience() {
    if(this.ExpEditForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.saveExperience(this.ExpEditForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Experience save successfully', "Success");
        //this.isExperienceShow = false;
        this.isFirstExp = false;
        this.getLatestUserDetails(this.user.id);
        //let frmArray = this.ExperienceForm.get("experience_field") as FormArray;
        //frmArray.clear();
        this.JoinAndClose();
        this.experiences.push(res.experience);
        this.showExperience();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  /*** end experience ****/


  /*** education ****/
  EducationForm: FormGroup;
  EduEditForm: FormGroup;
  isEducationShow:boolean = false;
  educations = [];
  singleEdu = [];
  isFirstTime = false;
  isNewShow = false;
  isEditTime = false;
  get educontrol() {
    return this.EducationForm.controls;
  }
  get eEditcontrol() {
    return this.EduEditForm.controls;
  }

  cancelForm(collapseName, formType){
    /* if(collapseName=='summary'){
      this.isSummaryShow = false;
    }else if(collapseName=='experience'){
      this.isExperienceShow = false;
    }else if(collapseName=='education'){
      this.isEducationShow = false;
    }else{
    } */

    this.isFirstTime = false;
    this.isEditTime = false;

    this.isFirstCert = false;
    this.isEditCert = false;

    this.isFirstExp = false;
    this.isEditExp = false;

    this.isFirstAchiv = false;
    this.isEditAchiv = false;
    if(formType=='editTime'){
      this.JoinAndClose();
    }
    if(formType=='firstTime'){
      this.JoinAndClose();
    }

    if (collapseName == 'experience' && formType == 'editTime') {
      this.singleExp = [];
    }

    if(formType=='firstTime'){
      this.isNewCertShow = true;
      this.isNewShow = true;
      this.isNewExpShow = true;
      this.isNewAchivShow = true;

      if(collapseName=='summary'){
        //this.isSummaryShow = false;
        this.JoinAndClose();
      }else if(collapseName=='experience'){
        let frmArray = this.ExperienceForm.get("experience_field") as FormArray;
        frmArray.clear();
      }else if(collapseName=='education'){
        let expFrmArray = this.EducationForm.get("quantities") as FormArray;
        expFrmArray.clear();
      }else if(collapseName=='certificate'){
        let certFrmArray = this.CertificateForm.get("certificates_field") as FormArray
        certFrmArray.clear();
      }
      else if(collapseName=='achievement'){
        let achvtFrmArray = this.AchievementForm.get("achievement_field") as FormArray
        achvtFrmArray.clear();
      }
      else{
      }
    }
  }

  commonHideOther(currentName){
    this.isSummaryShow = false;
    this.isEducationShow = false;
    this.isCertificateShow = false;
    this.isAchievmentShow = false;
    this.isExperienceShow = false;

    this.isSummaryEdit = false;
    this.isFirstExp = false;
    this.isFirstTime = false;
    this.isFirstCert = false;
    this.isFirstAchiv = false;

    if(currentName=='summary'){
      this.isSummaryShow = true;
    }else if(currentName=='education'){
      this.isEducationShow = true;
    }else if(currentName=='certificate'){
      this.isCertificateShow = true;
    }else if(currentName=='experience'){
      this.isExperienceShow = true;
    }else if(currentName=='achievement'){
      this.isAchievmentShow = true;
    }else{
      this.isSummaryShow = true;
    }
  }

  showEducation(){
    this.isEducationShow = !this.isEducationShow;
    this.commonHideOther('education');
    if(this.isEducationShow){
      this.authService.getEducation().subscribe((data: any)=>{
          if(data.status){
            if(data.educations && data.educations.length>0){
              this.educations = data.educations;
              this.isNewShow = true;
            }else{
              this.addQuantity();
              this.isFirstTime = true;
              this.isNewShow = false;
            }
          }else{
            this.toastr.error(data.message, "Error");
          }
      });

      this.EducationForm = this.formBuilder.group({
          quantities: this.formBuilder.array([]) ,
      });
    }
  }

  quantities() : FormArray {
      return this.EducationForm.get("quantities") as FormArray
  }
  newQuantity(): FormGroup {
    return this.formBuilder.group({
      institution: ['', Validators.required],
      qualification: ['', Validators.required],
      completion_month: ['', Validators.required],
      completion_year: ['', Validators.required],
      is_ongoing: [''],
      description: ['', Validators.required],
    })
  }

  addQuantity() {
    this.isFirstTime = true;
    this.isNewShow = false;
    this.quantities().push(this.newQuantity());
  }

  removeQuantity(i:number) {
    this.quantities().removeAt(i);
  }

  saveEducationOld() {
    if(this.EducationForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.saveEducation(this.EducationForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Education updated successfully', "Success");
        //this.isEducationShow = false;
        this.isFirstTime = false;
        this.getLatestUserDetails(this.user.id);
        this.educations = res.education;
        let eduFrmArray = this.EducationForm.get("quantities") as FormArray;
        eduFrmArray.clear();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  editEducation(id, j){
    /* this.isEditTime = true;
    this.singleEdu = this.educations[j];
    this.EduEditForm = this.formBuilder.group({
        institution: ['', Validators.required],
        qualification: ['', Validators.required],
        completion_month: ['', Validators.required],
        completion_year: ['', Validators.required],
        is_ongoing: [''],
        description: ['', Validators.required],
        id: [''],
    });
    this.EduEditForm.patchValue(this.singleEdu) */
  }

  updateEducation(){
    if(this.EduEditForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.updateEducation(this.EduEditForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Education updated successfully', "Success");
        //this.isEducationShow = false;
        this.isEditTime = false;
        let itemIndex = this.educations.findIndex(item => item.id == this.eEditcontrol.id.value);
        this.educations[itemIndex] = res.education;
        this.JoinAndClose();
        this.showEducation();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  deleteEducation(id){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;

        this.authService.deleteEducation(id).subscribe((data: any)=>{
          this.loading = false;
          if(data.status){
            this.toastr.success("Education Deleted Successfully", "Success");
            this.educations = this.educations.filter(item => item.id !== id);
            this.getLatestUserDetails(this.user.id);
          }else{
            this.toastr.error(data.message, "Error");
          }
        });
      }
    })
  }

  ediEducationModal(id, j, modalId) {
    this.isEditTime = true;
    this.singleEdu = this.educations[j];
    let isCurrent = this.educations[j].is_ongoing;
    let validatinAdd = [];
    let isDisable = false;
    if(isCurrent){
      validatinAdd = [];
      isDisable = true;
    }else{
      validatinAdd = [Validators.required];
      isDisable = false;
    }
    this.EduEditForm = this.formBuilder.group({
        institution: ['', Validators.required],
        qualification: ['', Validators.required],
        start_month: ['', Validators.required],
        start_year: ['', Validators.required],
        completion_month: [{value:'' , disabled: isDisable}, validatinAdd],
        completion_year: [{value:'' , disabled: isDisable}, validatinAdd],
        is_ongoing: [''],
        description: ['', Validators.required],
        id: [''],
    }, { validators: ValidationService.DateChekerEducation});
    this.EduEditForm.patchValue(this.singleEdu)

    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addEducationModal(modalId){
    this.isFirstTime = true;
    this.EduEditForm = this.formBuilder.group({
        institution: ['', Validators.required],
        qualification: ['', Validators.required],
        start_month: ['', Validators.required],
        start_year: ['', Validators.required],
        completion_month: ['', Validators.required],
        completion_year: ['', Validators.required],
        is_ongoing: [''],
        description: ['', Validators.required],
    }, { validators: ValidationService.DateChekerEducation});

    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveEducation() {
    if(this.EduEditForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.saveEducation(this.EduEditForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Education save successfully', "Success");
        //this.isEducationShow = false;
        this.isFirstTime = false;
        this.getLatestUserDetails(this.user.id);
        this.educations.push(res.education);
        this.JoinAndClose();
        //let eduFrmArray = this.EducationForm.get("quantities") as FormArray;
        //eduFrmArray.clear();
        this.showEducation();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }
  /*** end educatin ****/

  /*** certificate ****/
  CertificateForm: FormGroup;
  CertEditForm: FormGroup;
  isCertificateShow:boolean = false;
  certificates = [];
  singleCert = [];
  isFirstCert = false;
  isNewCertShow = false;
  isEditCert = false;
  urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  get cercontrol() {
    return this.CertificateForm.controls;
  }
  get cerEditcontrol() {
    return this.CertEditForm.controls;
  }

  showCertificate(){
    this.isCertificateShow = !this.isCertificateShow;
    this.commonHideOther('certificate');
    if(this.isCertificateShow){
      this.authService.getAllCertificate().subscribe((data: any)=>{
          if(data.status){
            if(data.certificates && data.certificates.length>0){
              this.certificates = data.certificates;
              this.isNewCertShow = true;
            }else{
              this.addCertificate();
              this.isFirstCert = true;
              this.isNewCertShow = false;
            }
          }else{
            this.toastr.error(data.message, "Error");
          }
      });

      this.CertificateForm = this.formBuilder.group({
          certificates_field: this.formBuilder.array([]) ,
      });

    }
  }

  certificates_field() : FormArray {
      return this.CertificateForm.get("certificates_field") as FormArray
  }
  newCertificate(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      authority: ['', Validators.required],
      licence_number: ['', [Validators.required, Validators.maxLength(20)]],
      start_month: ['', Validators.required],
      start_year: ['', Validators.required],
      end_month: ['', Validators.required],
      end_year: ['', Validators.required],
      is_ongoing: [''],
      url: ['', [Validators.required,Validators.pattern(this.urlReg)]],
    })
  }

  addCertificate() {
    this.isFirstCert = true;
    this.isNewCertShow = false;
    this.certificates_field().push(this.newCertificate());
  }

  removeCertificate(i:number) {
    this.certificates_field().removeAt(i);
  }

  saveCertificateOld() {
    if(this.CertificateForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.saveCertificate(this.CertificateForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Certificate added successfully', "Success");
        //this.isCertificateShow = false;
        this.isFirstCert = false;
        this.getLatestUserDetails(this.user.id);
        this.certificates = res.certificate;
        let certFrmArray = this.CertificateForm.get("certificates_field") as FormArray
        certFrmArray.clear();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  deleteCertificate(id){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;

        this.authService.deleteCertificate(id).subscribe((data: any)=>{
            this.loading = false;
            if(data.status){
              this.toastr.success("Certificate Deleted Successfully", "Success");
              this.certificates = this.certificates.filter(item => item.id !== id);
              this.getLatestUserDetails(this.user.id);
            }else{
              this.toastr.error(data.message, "Error");
            }
        });

      }
    })
  }

  editCertificate(id, j){
    /* this.isEditCert = true;
    this.singleCert = this.certificates[j];
    this.singleCert['certificate_id'] = this.certificates[j].id;
    this.CertEditForm = this.formBuilder.group({
        name: ['', Validators.required],
        authority: ['', Validators.required],
        licence_number: ['', Validators.required],
        start_month: ['', Validators.required],
        start_year: ['', Validators.required],
        end_month: ['', Validators.required],
        end_year: ['', Validators.required],
        is_ongoing: [''],
        url: ['', [Validators.required,Validators.pattern(this.urlReg)]],
        certificate_id: ['']
    });
    this.CertEditForm.patchValue(this.singleCert) */
  }

  updateCertificate(){
    if(this.CertEditForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.updateCertificate(this.CertEditForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Certificate updated successfully', "Success");
        //this.isCertificateShow = false;
        this.isEditCert = false;
        let itemIndex = this.certificates.findIndex(item => item.id == this.cerEditcontrol.certificate_id.value);
        this.certificates[itemIndex] = res.certificate;
        this.JoinAndClose();
        this.showCertificate();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      if(Array.isArray(err)){
        this.toastr.error(err.join(', '), "Error");
      }else{
        this.toastr.error(err, "Error");
      }
      this.loading = false;

    })
  }

  editCertificateModal(id, j, modalId) {
    this.isEditCert = true;
    this.singleCert = this.certificates[j];
    this.singleCert['certificate_id'] = this.certificates[j].id;
    let isCurrent = this.certificates[j].is_ongoing;
    let validatinAdd = [];
    let isDisable = false;
    if(isCurrent){
      validatinAdd = [];
      isDisable = true;
    }else{
      validatinAdd = [Validators.required];
      isDisable = false;
    }
    this.CertEditForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(40)]],
        authority: ['', [Validators.required, Validators.maxLength(40)]],
        licence_number: ['', [Validators.required, Validators.maxLength(20)]],
        start_month: ['', Validators.required],
        start_year: ['', Validators.required],
        end_month: [{value:'' , disabled: isDisable}, validatinAdd],
        end_year: [{value:'' , disabled: isDisable}, validatinAdd],
        is_ongoing: [''],
        url: ['', [Validators.required,Validators.pattern(this.urlReg)]],
        certificate_id: ['']
    }, { validators: ValidationService.DateChekerCertificate});
    this.CertEditForm.patchValue(this.singleCert)

    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addCertificateModal(modalId){
    this.isFirstCert = true;
    this.CertEditForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(40)]],
        authority: ['', [Validators.required, Validators.maxLength(40)]],
        licence_number: ['', [Validators.required, Validators.maxLength(20)]],
        start_month: ['', Validators.required],
        start_year: ['', Validators.required],
        end_month: ['', Validators.required],
        end_year: ['', Validators.required],
        is_ongoing: [''],
        url: ['', [Validators.required,Validators.pattern(this.urlReg)]],
    }, { validators: ValidationService.DateChekerCertificate});
    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  saveCertificate() {
    if(this.CertEditForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.saveCertificate(this.CertEditForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Certificate added successfully', "Success");
        //this.isCertificateShow = false;
        this.isFirstCert = false;
        this.getLatestUserDetails(this.user.id);
        this.certificates.push(res.certificate);
        this.JoinAndClose();
        this.showCertificate();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }
  /**** end certificate ****/

  /**** achievement *****/

  AchievementForm: FormGroup;
  AchivEditForm: FormGroup;
  isAchievmentShow:boolean = false;
  achievments = [];
  singleAchiv = [];
  isFirstAchiv = false;
  isNewAchivShow = false;
  isEditAchiv = false;
  get achivcontrol() {
    return this.AchievementForm.controls;
  }
  get achivEditcontrol() {
    return this.AchivEditForm.controls;
  }

  showAchievement(){
    this.commonHideOther('achievement');
    if(this.isAchievmentShow){
      this.authService.getAllAchievement().subscribe((data: any)=>{
          if(data.status){
            if(data.achievements && data.achievements.length>0){
              this.achievments = data.achievements;
              // this.achievments = []
              this.isNewAchivShow = true;
            }else{
              this.addAchievement();
              this.isFirstAchiv = true;
              this.isNewAchivShow = false;
            }
          }else{
            this.toastr.error(data.message, "Error");
          }
      });

      this.AchievementForm = this.formBuilder.group({
          achievement_field: this.formBuilder.array([]) ,
      });
    }
  }

  achievement_field() : FormArray {
      return this.AchievementForm.get("achievement_field") as FormArray
  }
  newAchievement(): FormGroup {
    return this.formBuilder.group({
        description: ['', Validators.required],
    })
  }

  addAchievement() {
    this.isFirstAchiv = true;
    this.isNewAchivShow = false;
    this.achievement_field().push(this.newAchievement());
  }

  removeAchievement(i:number) {
    this.achievement_field().removeAt(i);
  }

  saveAchievementOld() {
    if(this.AchievementForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.saveAchievement(this.AchievementForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Achievement added successfully', "Success");
        //this.isAchievmentShow = false;
        this.isFirstAchiv = false;
        this.getLatestUserDetails(this.user.id);
        this.achievments = res.achievement;
        let achvtFrmArray = this.AchievementForm.get("achievement_field") as FormArray
        achvtFrmArray.clear();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  deleteAchievement(id, name){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.loading = true;

        this.authService.deleteAchievement(id).subscribe((data: any)=>{
            this.loading = false;
            if(data.status){
              this.toastr.success("Achievement Deleted Successfully", "Success");
              this.achievments = this.achievments.filter(item => item.id !== id);
              this.getLatestUserDetails(this.user.id);
            }else{
              this.toastr.error(data.message, "Error");
            }
        });
      }
    })
  }

  editAchievement(id, j){
    this.isEditAchiv = true;
    this.singleAchiv = this.achievments[j];
    this.singleAchiv['achievement_id'] = this.achievments[j].id;
    this.AchivEditForm = this.formBuilder.group({
        description: ['', Validators.required],
        achievement_id: ['']
    });
    this.AchivEditForm.patchValue(this.singleAchiv)
  }

  updateAchievement(){
    if(this.AchivEditForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.updateAchievement(this.AchivEditForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Achievement updated successfully', "Success");
        /* this.isAchievmentShow = false; */
        this.isEditAchiv = false;
        let itemIndex = this.achievments.findIndex(item => item.id == this.achivEditcontrol.achievement_id.value);
        this.achievments[itemIndex] = res.achievement;
        this.JoinAndClose();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  editAchievementModal(id, j, modalId) {
    this.isEditAchiv = true;
    this.singleAchiv = this.achievments[j];
    this.singleAchiv['achievement_id'] = this.achievments[j].id;
    this.AchivEditForm = this.formBuilder.group({
        description: ['', Validators.required],
        achievement_id: ['']
    });
    this.AchivEditForm.patchValue(this.singleAchiv);

    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addAchievementModal(modalId){
    this.isFirstAchiv = true;
    this.AchivEditForm = this.formBuilder.group({
        description: ['', Validators.required],
    });

    this.modalReference = this.modalService.open(modalId, { size: 'lg',backdrop : 'static' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveAchievement() {
    if(this.AchivEditForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.saveAchievement(this.AchivEditForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Achievement added successfully', "Success");
        //this.isAchievmentShow = false;
        this.isFirstAchiv = false;
        this.getLatestUserDetails(this.user.id);
        this.achievments.push(res.achievement);
        this.JoinAndClose();
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  /**** end achievement*****/

  copy() {
     this.items.push(this.items.length + 1)
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.isimageChange = true;
      const file = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imageURL = event.target.result as string;
      }
      //console.log(file)
      this.imageForm.patchValue({
        profile_image: file
      });

      //this.submitImage();
      //$('#submitImage').trigger('click');
    }
  }

  selectImage(){
    $('#file').trigger('click');
  }

  cancelImage() {
    let path =  GlobalConstants.user_profile_path;
    //this.imageURL = path+this.user.profile_picture;
    this.imageURL = this.user.profile_picture;
    this.isimageChange = false;
    window.location.reload();
  }

  submitImage() {
    if(this.imageForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('file', this.imageForm.get('profile_image').value);
    //formData.append('email', this.imageForm.get('email').value);
    //formData.append('name', this.imageForm.get('name').value);
    formData.append('id', this.imageForm.get('id').value);

    this.loading = true;
    //this.authService.updateUser(this.imageForm.value).subscribe((res:any)=>{
      this.authService.updateUser(formData).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.authService.user = res.user;
        this.getLatestUserDetails(this.user.id);
        $('#user_profile_sidebar').attr('src', this.imageURL);
        this.toastr.success('Profile updated successfully', "Success");
        this.isimageChange = false;
        //this.router.navigateByUrl('dashboard');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  getLatestUserDetails(id){
    this.authService.getUserById(id).subscribe((data: any)=>{
      if(data.status){
        this.user = data.user;
        if(this.user){
          this.SelectedIndustry_id = this.user.industry_id;
          this.summary_title = this.user.summary_title;
          if(this.user.industry_id && this.user.industry){
            this.industry_name = this.user.industry.title;
          }
          if(this.user.country_id && this.user.state_id && this.user.country && this.user.state){
            this.country_name = this.user.country.name;
            this.state_name = this.user.state.name;
          }
          this.profile_percentage = this.user.profile_percentage;
          this.rate = this.user.rate;
          this.dateService.changeMessage(this.rate);
          this.Seltotal_experience = this.user.total_experience;
        }
        if(this.user.profile_picture){
          let path =  GlobalConstants.user_profile_path;
          //console.log(this.user.profile_picture);
          this.imageURL = this.user.profile_picture;
        }
      }else{
        this.toastr.error(data.message, "Error");
        this.router.navigateByUrl('dashboard');
      }
    });
  }

  getMonth(month){
     //1 = January
      var monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December' ];
      return monthNames[month - 1];
  }

  atLeastOne(...fields: string[]) {
    return (fg: FormGroup): ValidationErrors | null => {
      return fields.some(fieldName => {
        const field = fg.get(fieldName).value;
        if (typeof field === 'number') return field && field >= 0 ? true : false;
        if (typeof field === 'string') return field && field.length > 0 ? true : false;
      })
        ? null
        : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
    };
  }

}
