<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content">
   <div class="container-fluid">
      <div class="block-header top-head-vt">
         <div class="row">
             <div class="col-lg-5 col-md-8 col-sm-12">
                 <!-- <h2>Force Field Analysis
                 </h2> -->
                 <ul class="breadcrumb vt-a">
                     <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                     <li class="breadcrumb-item"><a routerLink="/dashboard">Our Membership </a></li>
                 </ul>
             </div>
         </div>
     </div>
      <div class="row justify-content-end">
        <div class="col-12 col-md-6 col-lg-2 mb-3 PlanCard" *ngFor="let singlePlan of planData">
           <div class="single-price-plan mb-100">
              <div class="price-plan-title">
                 <h4>{{singlePlan.plan_name}}
                </h4>
              </div>
              <div class="price-plan-value">
                <h2>£{{singlePlan.price}}</h2>
                 <p>{{singlePlan.duration}} {{singlePlan.duration_type}} SUBSCRIPTION</p>
              </div>
              <a href="#" class="Buttons-pink" *ngIf="singlePlan.price == 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]">Start Trial</a>
              <a href="#" class="Buttons-pink" *ngIf="singlePlan.price > 0 && CurrentPlanID < singlePlan.id" [routerLink]="['/PurchasePlan/'+singlePlan.id]">Continue</a>
             </div>
        </div>
      
     </div>
     <div class="row clearfix">
      <div class="col-md-12 mb-4">
         <div class="body">
            <table style="width:100%;">
               <tbody><tr>
                 <th style="line-height: 1; font-size:16px;color:#fff;background-color: #D4058E;    border-radius: 8px 8px 0 0;" colspan="12">LEARN</th>
               </tr>
               <tr>
                 <th style="width:20%">Generic Skills</th>
                 <td>Sample Data</td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
               <tr>
                 <th>Testing Skills</th>
                 <td style="width:20%;">Sample Data</td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
               <tr>
                 <th>Soft Skills</th>
                 <td>Sample Data</td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
               <tr>
                 <th>Training</th>
                 <td>View Only</td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
             </tbody>
            </table>
         </div>
         <div class="body">
            <table style="width:100%">
               <tbody><tr>
                 <th style="line-height: 1; font-size:16px;color:#fff;background-color: #D4058E;" colspan="12">NETWORK</th>
               </tr>
               <tr>
                 <th style="width:20%">Members Connect</th>
                 <td>View Only</td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
               <tr>
                 <th>P2P Connect</th>
                 <td>View Only </td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 </tr>
               
             </tbody></table>
         </div>
         <div class="body">
            <table style="width:100%">
               <tbody><tr>
                 <th style="line-height: 1; font-size:16px;color:#fff;background-color: #D4058E;" colspan="12">GROW</th>
               </tr>
               <tr>
                 <th style="width:20%">Job Search</th>
                 <td>View Only </td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="grey" class="icon-close"></i></td>
                  <td><i id="red" class="icon-check"></i></td>

               </tr>
               <tr>
                 <th>CV/LinkedIN</th>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
               <tr>
                 <th>Coaching</th>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="grey" class="icon-close"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
             </tbody></table>
         </div>
         <div class="body">
            <table style="width:100%">
               <tbody><tr>
                 <th style="line-height: 1; font-size:16px;color:#fff;background-color: #D4058E;" colspan="12">TOOLS</th>
               </tr>
               <tr>
                 <th style="width:20%">Tools</th>
                 <td>Sample Tool</td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
             </tbody></table>
         </div>

         <div class="body">
            <table style="width:100%">
               <tbody><tr>
                  <th style="line-height: 1; font-size:16px;color:#fff;background-color: #D4058E;" colspan="12">Deal Room/ Collaboration Platform</th>
                </tr>
                <tr>
                  <th style="width:20%">Blog/Chat</th>
                 <td>View Only </td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
                 <td><i id="red" class="icon-check"></i></td>
               </tr>
             </tbody></table>
         </div>

      </div>
   </div>
   <ng-template #SubscribePlanModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Your 10-days Free Trail is Started</h4>
     </div>
     <div class="modal-body">
        <div class="Iconwarning-box text-center mb-4 text-center">
           <span class="info-Iconwarning ">  <img src="./assets/assets/images/plan-icon.png" alt="plan-icon"></span>
           <div class="Iconwarning-box-content" >
              <h6> You Still Have To Subscribe To At Least One Membership Plan To Access Some Features.
                
              </h6>
              <button type="button" Class="btn pbtn" aria-label="pbtn" (click)="modal.dismiss('Cross click')">
                   CLOSE
              </button>
           </div>
        </div>
     </div>
    </ng-template>
   </div>
</div>