<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header top-head-vt">
            <div class="row">
                <div class="col-lg-5 col-md-8 col-sm-12">
                    <ul class="breadcrumb vt-a">
                        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                        <li class="breadcrumb-item">Network</li>
                        <li class="breadcrumb-item"><a routerLink="/dashboard">Event add</a></li>
                      </ul>
            
                </div>
            </div>
        </div>
      
        <div class="row clearfix">
            <div class="col-md-6">
                <div class="card">
                    <div class="header text-center">
                        <h2>Add Event</h2>
                    </div>
                    <div class="body">
                        <form [formGroup]="EventForm" enctype="multipart/form-data">
                            <div class="form-group">
                                <label>Title <span>*</span></label>
                                <input class="form-control" type="text" formControlName="title" />
                                <span class="text-danger"
                                    *ngIf="control.title.dirty && control.title.hasError('required')">
                                    Title field is requied.
                                </span>
                            </div>

                            <div class="form-group">
                                <label>Event Date <span>*</span></label>
                                <input type="date" class="form-control" formControlName="event_date" min="{{currentDate|date:'yyyy-MM-dd'}}">
                                <span class="text-danger" *ngIf="control.event_date.dirty && control.event_date.hasError('required')">
                                    Event date field is requied.
                                </span>
                            </div>

                            <div class="form-group">
                                <label>Meeting Link / Text</label>
                                <input class="form-control" type="text" formControlName="meeting_link" />
                            </div>

                            <div class="form-group">
                                <label>Event Time <span>*</span></label>
                                <input type="time" class="form-control" formControlName="event_time">
                                <span class="text-danger" *ngIf="control.event_time.dirty && control.event_time.hasError('required')">
                                    Event time field is requied.
                                </span>
                            </div>

                            <!-- <div class="form-group">
                                <label>Image/ShortVideo</label>
                                <input type="file" class="form-control" formControlName="file_name" (change)="onFileChange($event)" 
                                    accept=".jpg, .jpeg, .png, video/*">
                                <span class="text-danger" *ngIf="control.file_name.dirty && control.file_name.hasError('required')">
                                    File field Required.
                                </span>
                            </div> -->
        
                            <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="!EventForm.valid" > <!--  -->
                                Save
                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        
        </div>


    </div>
</div>