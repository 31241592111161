import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './helper/auth.guard';
import { DashboardComponent } from './secure/dashboard/dashboard.component';
import { EditProfileComponent } from './secure/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './secure/change-password/change-password.component';
import { ViewProfileComponent } from './secure/view-profile/view-profile.component';
import { EmailVarifyComponent } from './auth/email-varify/email-varify.component';
import { UsersComponent } from './secure/users/users.component';
import { CoachesComponent } from './secure/coaches/coaches.component'; //
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { NotFoundComponent } from './auth/not-found/not-found.component';
import { UserDetailsComponent } from './secure/user-details/user-details.component';
import { EventAddComponent } from './secure/event-add/event-add.component';
import { EventListComponent } from './secure/event-list/event-list.component';
import { UserEventListComponent } from './secure/user-event-list/user-event-list.component';
import { ToolsComponent } from './secure/tools/tools.component';
import { Tool3Component } from './secure/tools/tool3/tool3.component';
import { Tool4Component } from './secure/tools/tool4/tool4.component';

import { MastertoolsComponent } from './secure/mastertools/mastertools.component';
import { ViewtoolComponent } from './secure/viewtool/viewtool.component';
import { ChatComponent } from './secure/chat/chat.component';
import { MyFavouriteComponent } from './secure/my-favourite/my-favourite.component';

import { AdminDealroomComponent } from './secure/admin-dealroom/admin-dealroom.component';
import { AddDealroomComponent } from './secure/admin-dealroom/add-dealroom/add-dealroom.component';
import { RoomUsersComponent } from './secure/admin-dealroom/room-users/room-users.component';
import { RoomUserRequestComponent } from './secure/admin-dealroom/room-user-request/room-user-request.component';

import { ProjectToolComponent } from './secure/tools/project-tool/project-tool.component';
import { ProjectToolViewComponent } from './secure/tools/project-tool-view/project-tool-view.component';
import { UserMessageComponent } from './secure/user-message/user-message.component';

import { LearningAddComponent } from './secure/admin/learning/learning-add/learning-add.component';
import { LearningListComponent } from './secure/admin/learning/learning-list/learning-list.component';

import { CategoryListComponent } from './secure/user/learn/category-list/category-list.component';
import { NotificationListComponent } from './secure/user/notification/notification-list/notification-list.component';

import { PostViewComponent } from './secure/user/post/post-view/post-view.component';
import { Tool4viewComponent } from './secure/tools/tool4view/tool4view.component';
import { Tool3viewComponent } from './secure/tools/tool3view/tool3view.component';

import { FollowingPostComponent } from './secure/following-post/following-post.component';
import { UpgradeComponent } from './secure/upgrade-renew/upgrade/upgrade.component';
import { OurPlanComponent } from './secure/our-plan/our-plan.component'; 
import { PurchasePlanComponent } from './secure/purchase-plan/purchase-plan.component'; 
import { GenericSkillsComponent } from './secure/user/learn/generic-skills/generic-skills.component';
import { TestingSkillsComponent } from './secure/user/learn/testing-skills/testing-skills.component';
import { SoftSkillsComponent } from './secure/user/learn/soft-skills/soft-skills.component';
import { TrainingComponent } from './secure/user/learn/training/training.component';
import { CvLinkdinComponent } from './secure/grow/cv-linkdin/cv-linkdin.component';
import { CoachingComponent } from './secure/grow/coaching/coaching.component';
import { AdminDashboardComponent } from './secure/admin-dashboard/admin-dashboard.component';
import { JobDetailsComponent } from './secure/grow/job-details/job-details.component';
import { JobSearchComponent } from './secure/grow/job-search/job-search.component';

import { CompanyAddComponent } from './secure/admin/grow/company-add/company-add.component';
import { JobAddComponent } from './secure/admin/grow/job-add/job-add.component';
import { JobListComponent } from './secure/admin/grow/job-list/job-list.component';
import { JobApplicationsComponent } from './secure/admin/grow/job-applications/job-applications.component';

//1=super admin,2=coach,3=user
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'forgot-password', component: ForgotpasswordComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'edit-profile/:id', component: EditProfileComponent, canActivate: [AuthGuard]},
  //{path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard]},
  {path: 'account-settings', component: ChangePasswordComponent, canActivate: [AuthGuard]},
  {path: 'profile', component: ViewProfileComponent, canActivate: [AuthGuard]},
  {path: 'email-verify/:id/:token', component: EmailVarifyComponent},
  {path: 'vt-members', component: UsersComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},
  {path: 'vt-coaches', component: CoachesComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},
  {path: 'password/reset/:token', component: ResetPasswordComponent},
  {path: 'user-details/:id', component: UserDetailsComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},
  {path: 'event-add', component: EventAddComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'event-list', component: EventListComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'vt-events', component: UserEventListComponent, canActivate: [AuthGuard], data: { roles: [3] }},
  {path: 'tools', component: ToolsComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},

  {path: 'upgrade-plan', component: UpgradeComponent, canActivate: [AuthGuard]},

  {path: 'OurPlan', component: OurPlanComponent, canActivate: [AuthGuard]},

  {path: 'PurchasePlan/:id', component: PurchasePlanComponent, canActivate: [AuthGuard]},

  // {path: 'tools/tool3', component: Tool3Component, canActivate: [AuthGuard], data: { roles: [1,3] }},
  // {path: 'tools/tool4', component: Tool4Component, canActivate: [AuthGuard], data: { roles: [1,3] }},
  {path: 'tools/tool3/:id', component: Tool3Component, canActivate: [AuthGuard], data: { roles: [1,3] }},
  {path: 'tools/tool4/:id', component: Tool4Component, canActivate: [AuthGuard], data: { roles: [1,3] }},

  {path: 'tools/tool4view/:id', component: Tool4viewComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},
  {path: 'tools/tool3view/:id', component: Tool3viewComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},



  {path: 'mastertools/:id', component: MastertoolsComponent, canActivate: [AuthGuard], data: { roles: [3] }},
  {path: 'viewtool/:id', component: ViewtoolComponent, canActivate: [AuthGuard], data: { roles: [3] }},
  {path: 'deal-room', component: ChatComponent, canActivate: [AuthGuard], data: { roles: [3] }},
  {path: 'my-favourite', component: MyFavouriteComponent, canActivate: [AuthGuard], data: { roles: [3] }},

  {path: 'group/:id/:name', component: MyFavouriteComponent, canActivate: [AuthGuard], data: { roles: [3] }},

  {path: 'admin/dealroom', component: AdminDealroomComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/dealroom/add', component: AddDealroomComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/dealroom/edit/:id', component: AddDealroomComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/dealroom/users/:id', component: RoomUsersComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/dealroom/request-users/:id', component: RoomUserRequestComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'Admin-Dashboard', component: AdminDashboardComponent, canActivate: [AuthGuard]},

  {path: 'project-tool/:id', component: ProjectToolComponent, canActivate: [AuthGuard], data: { roles: [3] }},
  {path: 'project-tool-view/:id', component: ProjectToolViewComponent, canActivate: [AuthGuard], data: { roles: [3] }},
  {path: 'message/:id', component: UserMessageComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},
  {path: 'message', component: UserMessageComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},

  {path: 'admin/learning', component: LearningListComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/learning-add', component: LearningAddComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/learning-edit/:id', component: LearningAddComponent, canActivate: [AuthGuard], data: { roles: [1] }},

  // {path: 'learn/:slug', component: CategoryListComponent, canActivate: [AuthGuard], data: { roles: [3] }},
  
  {path: 'learn/generic-skills', component: GenericSkillsComponent, canActivate: [AuthGuard]},
  {path: 'learn/testing-skills', component: TestingSkillsComponent, canActivate: [AuthGuard]},
  {path: 'learn/soft-skills', component: SoftSkillsComponent, canActivate: [AuthGuard]},
  {path: 'learn/training', component: TrainingComponent, canActivate: [AuthGuard]},


  {path: 'notification', component: NotificationListComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},
  {path: 'post/view/:id', component: PostViewComponent, canActivate: [AuthGuard], data: { roles: [1,3] }},

  {path: 'following-post', component: FollowingPostComponent, canActivate: [AuthGuard], data: { roles: [3] }},
  
  {path: 'grow/job-search', component: JobSearchComponent, canActivate: [AuthGuard]},
  {path: 'grow/cv-linkdin', component: CvLinkdinComponent, canActivate: [AuthGuard]},
  {path: 'grow/coaching', component: CoachingComponent, canActivate: [AuthGuard]},
  {path: 'grow/job-details/:id', component: JobDetailsComponent, canActivate: [AuthGuard]},

  {path: 'admin/company-add', component: CompanyAddComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/jobs', component: JobListComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/job-add', component: JobAddComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/job-edit/:id', component: JobAddComponent, canActivate: [AuthGuard], data: { roles: [1] }},
  {path: 'admin/job-applications/:id', component: JobApplicationsComponent, canActivate: [AuthGuard], data: { roles: [1] }},

  //{ path: 'userslist', loadChildren: () => import(`./secure/users/users.module`).then(m => m.UsersModule) },

  /* {
      path:"chat",
      loadChildren: () => import(`./secure/chat/chat.module`).then(m => m.ChatModule)
  },

  {
      path:"my-favourite",
      loadChildren: () => import(`./secure/my-favourite/my-favourite.module`).then(m => m.MyFavouriteModule)
  }, */

  { path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
  
})
export class AppRoutingModule { }
