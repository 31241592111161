<app-header></app-header>
<app-sidebar></app-sidebar>

<div id="main-content">
	<div class="container-fluid">

		<div class="block-header top-head-vt">
			<div class="row">
				<div class="col-lg-5 col-md-8 col-sm-12">
					<!-- <h2> Post View</h2> -->
					<ul class="breadcrumb vt-a">
						<li class="breadcrumb-item"><a><i class="icon-home"></i></a></li>
						<li class="breadcrumb-item active">Post View</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="cht-commet">
			<div class="row">
				<div class="col-md-12 col-sm-12">
					<div class="main-chatprt">
						<div class="hed-chat">
							<img src="{{ post?.user?.profile_pic }}">
							<!--<div class="dots-icon" *ngIf="post?.is_user_post">
								<i class="fa fa-ellipsis-v" aria-hidden="true"></i>
								 <ul class="edit-ul" >
									<li (click)="EditPost(post, PostModal)">Edit</li>
									<li (click)="DeletePost(post)">Delete</li>
								</ul> 
							</div>-->
							<div class="hd-cht234">
								<h3>
									{{ post?.user?.name }}
									<span class="network-i">
									<i class="fa fa-circle" aria-hidden="true"></i>
									{{ post.dealroom?.dealroom_name }}
									</span>
								</h3>
								<p class="chat-time-glb">
									<span>{{ post?.created_at }}</span>
									<i class="fa fa-globe" aria-hidden="true"></i>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 mt-4 text-center" *ngIf="post.post_image">
					<div class="clearfix">
						<img [src]="post.post_image" alt="..." class="img-thumbnail rounded  mx-auto d-block"
						width="400" height="400" onError="this.src='./assets/assets/images/user.png'">
					</div>
				</div>
				<div class="col-md-12 mt-4 text-center" *ngIf="post.post_video">
					<div class="video mx-auto d-block">
						<video controls #videoPlayer width="420" height="340" >
							<source [src]="post.post_video" type="video/mp4" />
							Browser not supported
						</video>
					</div>
				</div>
				<div class="col-md-12 mt-4">
					<div class="chat-content">
					<p [innerHTML]="post.post_description"></p>
					</div>
					<div class="chat-content m-t-5" *ngIf="post.post_document">
						<a [href]="post.post_document" target="_blank" rel="noopener noreferrer" [download]="post.document_name">
							{{ post.document_name }}
						</a>
					</div>
				</div>
				<div class="col-md-12 bdr-comment">
					<div class=" row">
						<div class="col-md-4">
							<div class="lik-fv" >
								<i class="fa fa-thumbs-up cursor-p" [ngClass]="{'isLike':post.is_user_like}" aria-hidden="true"
								></i>
								<!-- (click)="UserLike(post.id, post.is_user_like, 'post')" -->
								Like <span> {{post.like_count}}</span>
								<i aria-hidden="true" class="fa fa-circle"></i>
								<span>{{post.views}} View</span>
							</div>
						</div>
						<!--<div class="col-md-4">
							<div class="cmt-msg cursor-p" >
								 [ngClass]="{'isMsgOp':visibleIndex === index}" (click)="showSubItem(index)"
								<i aria-hidden="true" class="fa fa-envelope"></i>
								<span>Message ({{post.comment_count}})</span>
							</div>
						</div> -->
						<!--<div class="col-md-4">
							<div class="follow-star">
								<span class="cursor-p" [ngClass]="{'fl-star-spn':post.is_user_follow_post}" >
									 (click)="UserFollow(post.id, post.is_user_follow_post)" 
									<i class="fa fa-star" aria-hidden="true"></i>
								</span>
								<span> Follow This Post</span>
							</div>
						</div>-->
					</div>
				</div> 
				<!-- *ngIf="visibleIndex === index" -->
				<ng-container> 
					<!-- <div class="col-md-12">
						<div class="cmt-parts clearfix">
							<div class="coment-hdg clearfix">
								<img [src]="userProfile" class="img-cmtlft">
								<div class="content-cmt">
									 <form [formGroup]="commentForm" enctype="multipart/form-data" (keyup.enter)="SubmitComment(post.id)">
										<div class="form-group">
											<input type="text" class="form-control"
											placeholder="Write Your comment" formControlName="description">
											<span class="text-danger"
											  *ngIf="CControl.description.dirty && CControl.description.hasError('required')">
											  This field is requied.
											</span>
											<input type="file" formControlName="post_image" (change)="onCommentImage($event)" #fileComment style="display: none;"
												accept="image/*">
											<input type="hidden" class="form-control" formControlName="post_id">
											<div class="input-icnmng">
												<img src="./assets/assets/images/send.png" (click)="SubmitComment(post.id)">
												<span>
													<i class="fa fa-smile-o" aria-hidden="true"></i>
													<i class="fa fa-camera" [ngClass]="{'emo-color': isCommentImageAdd}" aria-hidden="true" (click)="fileComment.click()"></i>
												</span>
											</div>
										</div>
									</form> 
								</div>
							</div>
						</div>
					</div> -->

					<div class="col-md-12 down-cmtt mb-3" >
						<div class="cmt-parts clearfix" *ngFor="let comment of post.comment">
							<div class="coment-hdg clearfix">
								<img [src]="comment.user_pic" class="img-cmtlft" onError="this.src='./assets/assets/images/user.png'">
								<div class="content-cmt">
	
									<p class="most-dwnhr">
										<span>{{comment.user_name}}</span>
										<i aria-hidden="true" class="fa fa-circle"></i>
										<span>{{comment.created_at}}</span>
									</p>
									<p class="lorem-cmnt" [innerHTML]="comment.description"></p>
									<p class="lorem-cmnt" *ngIf="comment.post_image">
										<img [src]="comment.post_image" alt="..." class="img-thumbnail rounded mx-auto d-block" width="200" height="200"
											onError="this.src='./assets/assets/images/user.png'">
									</p>
									<div class="lik-fv like-mn">
										<i class="fa fa-thumbs-up" [ngClass]="{'isLike':comment.is_user_like}" aria-hidden="true"
										(click)="UserLike(comment.id, comment.is_user_like, 'comment', post.id)" style="cursor: pointer;"></i>
										Like <span> {{comment.like_count}}</span>
										<i aria-hidden="true" class="fa fa-circle"></i>
										<span>10 View</span>
									</div>
									<!-- <div class="dot-incm" *ngIf="comment.is_user_comment">
										<i aria-hidden="true" class="fa fa-ellipsis-v"></i>
										<ul class="ul0nnhver">
											<li  (click)="EditComment(comment, CommentModal)" *ngIf="comment.is_user_comment">Edit</li>
											<li (click)="DeleteComment(comment)" *ngIf="comment.is_user_comment">Delete</li>
										</ul>
									</div> -->
								</div>
							</div>
						</div>
					</div>
	
					<!-- <div class="col-md-12 down-cmtt mt-4" *ngFor="let comment of comments">
						<div class="cmt-parts clearfix">
							<div class="coment-hdg clearfix">
								<img [src]="comment.user_pic" class="img-cmtlft" onError="this.src='./assets/assets/images/user.png'">
								<div class="content-cmt">
	
									<p class="most-dwnhr">
										<span>{{comment.user_name}}</span>
										<i aria-hidden="true" class="fa fa-circle"></i>
										<span>{{comment.created_at}}</span>
									</p>
									<p class="lorem-cmnt" [innerHTML]="comment.description">
									</p>
									<p class="lorem-cmnt" *ngIf="comment.post_image">
										<img [src]="comment.post_image" alt="..." class="img-thumbnail rounded mx-auto d-block" width="400" height="400"
											onError="this.src='./assets/assets/images/user.png'">
									</p>
									<div class="lik-fv like-mn">
										<i class="fa fa-thumbs-up" [ngClass]="{'isLike':comment.is_user_like}" aria-hidden="true"
										(click)="UserLike(comment.id, comment.is_user_like, 'comment', post.id)" style="cursor: pointer;"></i>
										Like <span> {{comment.like_count}}</span>
									</div>
									<div class="dot-incm" *ngIf="comment.is_user_comment">
										<i aria-hidden="true" class="fa fa-ellipsis-v"></i>
										<ul class="ul0nnhver">
											<li  (click)="EditComment(comment, CommentModal, post.id)" *ngIf="comment.is_user_comment">Edit</li>
											<li (click)="DeleteComment(comment)" *ngIf="comment.is_user_comment">Delete</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div> -->
	
					<!-- <div class="col-md-12 load-more mt-4 text-center m-b-10" *ngIf="isCommentPageShow && post.comment_count>0">
						<a class="cursor-p" (click)="getCommentByPost(post.id, commentPage, post.comment_count);">Load More Comments</a>
					</div> -->
				</ng-container>
	
			</div>
			<!--./row-->
		  </div>

    </div>
</div>
