import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GrowService } from 'src/app/services/grow.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {

  jobs:any;

  constructor(
    private growService: GrowService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getAllJobs();
  }

  getAllJobs() {
    var formData = new FormData();
    this.growService.getAllJobs(formData).subscribe((response: any)=>{
      if (response.status) {
        this.jobs = response.data;
      } else {
        this.jobs = [];
        this.toastr.error(response.message, "Error");
      }
    });
  }

  editJob(id) {
    this.router.navigate(['/admin/job-edit/' + id]);
  }

  deleteJob(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append('job_id', id);
        this.growService.deleteJob(formData).subscribe((response:any) => {
          if (response.status) {
            this.toastr.success('Job deleted successfully', "Success");
            this.getAllJobs();
          } else {
            this.toastr.error(response.message, "Error");
          }
        }, err => {
          this.toastr.error(err, "Error");
        })
      }
    })
    
  }

}
