import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  forgotForm: FormGroup;
  public loading: boolean;
  constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    if(this.authService.isLogedin) {
      this.router.navigateByUrl('dashboard');
    }

    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required,  Validators.email]],
    });
  }

  submit() {
    if(this.forgotForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.forgotpassword(this.forgotForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success(res.message, "Success");
        //alert(res.message);
        this.router.navigate(['/login']);
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
        //alert(res.message);
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err)
      //this.toastr.error(err.error.message, "Error");
    })
  }

  get control() {
    return this.forgotForm.controls;
  }

  login(){
    this.router.navigateByUrl('login');
  }

}
