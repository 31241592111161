import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
//import { AuthService } from 'src/app/services/auth.service';
import { DealroomService } from 'src/app/services/dealroom.service';
import { ToastrService } from 'ngx-toastr';
import { Location} from '@angular/common';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-dealroom',
  templateUrl: './add-dealroom.component.html',
  styleUrls: ['./add-dealroom.component.css']
})
export class AddDealroomComponent implements OnInit {

  roomForm: FormGroup;
  public loading: boolean;
  isAddMode: boolean;
  id: string;

  constructor(private formBuilder: FormBuilder, 
    private roomService: DealroomService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private toastr: ToastrService,
    private _location: Location) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.roomForm = this.formBuilder.group({
        room_name: ['', [Validators.required]],
        description: ['', [Validators.required]],
        id:[]
    })

    if (!this.isAddMode) {  
      this.roomService.getSingle(this.id).subscribe((res:any)=>{
        if(res.status) {
          this.roomForm.patchValue(res.dealroom)
        } else {
          this.toastr.error("Blog Room not found", "Error");
        }
      })
    }
  }

  get control() {
    return this.roomForm.controls;
  }

  submit() {
    if(this.roomForm.invalid) {
      return;
    }
    this.loading = true;
    if (this.isAddMode) {
        this.createUser();
    } else {
        this.updateUser();
    }
  }

  private createUser() {
    this.roomService.save(this.roomForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Blog Room create successfully', "Success");
        this.router.navigateByUrl('admin/dealroom');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  private updateUser() {
    this.roomService.update(this.roomForm.value).subscribe((res:any)=>{
      if(res.status) {
        this.loading = false;
        this.toastr.success('Blog Room updated successfully', "Success");
        this.router.navigateByUrl('admin/dealroom');
      } else {
        this.loading = false;
        this.toastr.error(res.message, "Error");
      }
    }, err=> {
      this.loading = false;
      this.toastr.error(err, "Error");
    })
  }

  backClicked() {
    this._location.back();
  }

}
