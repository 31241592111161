<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content">
   <div class="container-fluid">
      <div class="block-header top-head-vt">
         <div class="row">
            <div class="col-lg-5 col-md-8 col-sm-12">
               <!-- <h2>Force Field Analysis
                  </h2> -->
               <ul class="breadcrumb vt-a">
                  <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class="icon-home"></i></a></li>
                  <li class="breadcrumb-item"><a routerLink="/dashboard">Account Settings</a></li>
               </ul>
            </div>
         </div>
      </div>
      <div class="row clearfix">
         <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="card crd-brd">
               <div class="main-profl row">
                  <div class="col-md-7 Rightline">
                     <div class="img-prrt1 clearfix">
                        <div class="i-pr1">
                           <span class="img_cam_upload"  *ngIf="imageURL && imageURL !== ''">
                           <img [src]="imageURL" [alt]="user.name" alt="user image" class="rounded-circle">
                           </span>
                        </div>
                        <div class="contehnt-pr2">
                           <h3 class="hd-con">
                              {{user?.name}}
                           </h3>
                           <div>
                              <!-- <p class="dsg-ttl xls-edtimn m-b-0" *ngIf="user?.industry_id != null">
                                 {{user?.industry.title}}
                              </p> -->
                               <p class="xls-edtimn">
                                  {{user?.summary_title}}
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-5">
                     <div class="indus-content">
                        <ul>
                           <li>
                              <span>Current Industry :</span>
                              <span *ngIf="user?.industry_id != null"> {{user?.industry.title}}</span>
                           </li>
                           <li>
                              <span>Experience Year :</span>
                              <span class="xls-edtimn"> {{user?.total_experience}}
                              </span>
                           </li>
                           <li class="icn-indus">
                              <i class="icon-envelope"></i>
                              <span>{{user?.email}}</span>
                          </li>
                          <li class="icn-indus">
                           <i class="fa fa-map-marker" aria-hidden="true"></i>
                           <span></span>
                       </li>
                       <li>
                           <p class="ic-img-cn">
                               <a *ngIf="user?.linkedin_id!=null" href="{{ user?.linkedin_id }}" target="_blank"><img src="./assets/assets/images/lnkin-hvr.png"></a>
                               <a *ngIf="user?.fb_id!=null" href="{{ user?.fb_id }}" target="_blank"><img src="./assets/assets/images/twit-hvr.png"></a>
                               <a *ngIf="user?.linkedin_id==null" href="javascript:"><img src="./assets/assets/images/lnkin-hvr.png"></a>
                               <a *ngIf="user?.fb_id==null" href="javascript:"><img src="./assets/assets/images/twit-hvr.png"></a>
                           </p>
                       </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row clearfix">
         <div class="col-md-12">
            <!-- AccountDetails address -->
            <div class="AccountSettings-card mb-4">
               <div class="ASettings-header">
                  <h5 class="card-title-text" >Change Password</h5>
                  <!-- <div class="pull-right CloseIcon">
                     <a href="javascript:" class="text-center btn" role="button" >
                     <i class="fa fa-close"></i>
                     </a>
                  </div> -->
               </div>
               <div class="Settings-Details mt-2">
                  <form class="AccountDetailsAddress" [formGroup]="ChangePassForm" enctype="multipart/form-data">
                     <div class="row justify-content-start">
                       
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>Current Password <span>*</span></label>
                              <input class="form-control" type="password" formControlName="current_password" />
                              <span class="text-danger"
                                 *ngIf="control.current_password.dirty && control.current_password.hasError('required')">
                              Current password is requied.
                              </span>
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>New Password <span>*</span></label>
                              <input class="form-control" type="password" formControlName="new_password" id="new_password" />
                              <span class="text-danger" *ngIf="control.new_password.dirty && control.new_password.hasError('required')">
                              New password is requied.
                              </span>
                              <span class="text-danger" *ngIf="control.new_password.dirty && control.new_password.hasError('minLength')">
                              Minimum 8 character required.
                              </span>
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <label>Confirm Password <span>*</span></label>
                              <input class="form-control" type="password" formControlName="confirm_password" id="confirm_password" />
                              <span class="text-danger" *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('required')">
                              Confirm password is requied.
                              </span>
                              <span class="text-danger" *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('minLength')">
                              Minimum 8 character required.
                              </span>
                              <span class="text-danger" *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('confirmedValidator')">
                              Password and Confirm Password must be match.
                              </span>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="text-right d-block subscriptions-card-footer">
                              <button class="Buttons-pink btn " ype="submit" class="btn btn-primary" (click)="submit()"
                              [disabled]="!ChangePassForm.valid">Update      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i></button>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
          
         </div>
      </div>
      <!-- <div class="row clearfix">
         <div class="col-md-12">
      <div class="card">
         <div class="header">
            <h2>View Profile</h2>
         </div>
         <div class="body">
            <form [formGroup]="viewForm" enctype="multipart/form-data">
               <div class="form-group col-lg-6 col-md-12">
                  <label>Email</label>
                  <p>{{user?.email}}</p>
               </div>
               <div class="form-group col-lg-6 col-md-12">
                  <label>Name</label>
                  <p>{{user?.name}}</p>
               </div>
               <div class="form-group col-lg-6 col-md-12">
                  <label>Profile Image</label>
                  <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
                     <img [src]="imageURL" [alt]="user.name" width="100" height="100">
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div> -->
      <!-- <div class="row clearfix">
         <div class="col-md-12">
            <div class="card">
               <div class="header">
                  <h2>Change Password</h2>
               </div>
               <div class="body">
                  <form [formGroup]="ChangePassForm" enctype="multipart/form-data">
                     <div class="form-group">
                        <label>Current Password <span>*</span></label>
                        <input class="form-control" type="password" formControlName="current_password" />
                        <span class="text-danger"
                           *ngIf="control.current_password.dirty && control.current_password.hasError('required')">
                        Current password is requied.
                        </span>
                     </div>
                     <div class="form-group">
                        <label>New Password <span>*</span></label>
                        <input class="form-control" type="password" formControlName="new_password" id="new_password" />
                        <span class="text-danger" *ngIf="control.new_password.dirty && control.new_password.hasError('required')">
                        New password is requied.
                        </span>
                        <span class="text-danger" *ngIf="control.new_password.dirty && control.new_password.hasError('minLength')">
                        Minimum 8 character required.
                        </span>
                     </div>
                     <div class="form-group">
                        <label>Confirm Password <span>*</span></label>
                        <input class="form-control" type="password" formControlName="confirm_password" id="confirm_password" />
                        <span class="text-danger" *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('required')">
                        Confirm password is requied.
                        </span>
                        <span class="text-danger" *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('minLength')">
                        Minimum 8 character required.
                        </span>
                        <span class="text-danger" *ngIf="control.confirm_password.dirty && control.confirm_password.hasError('confirmedValidator')">
                        Password and Confirm Password must be match.
                        </span>
                     </div>
                     <button type="submit" class="btn btn-primary" (click)="submit()"
                     [disabled]="!ChangePassForm.valid">
                     Change Password
                     <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                     </button>
                  </form>
               </div>
            </div>
         </div>
      </div> -->
   </div>
</div>