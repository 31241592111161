import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import{ GlobalConstants } from '../common/global-constants';

import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class LearningService {

  url = GlobalConstants.apiURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  store(req){
    return this.http.post(`${this.url}/learning/store`, req);
  }

  getAll(){
    return this.http.get(`${this.url}/learning/get_all`, this.httpOptions);
  }
  
  getAllCategory(){
    return this.http.get(`${this.url}/learning/get_categories`, this.httpOptions);
  }

  getAllByCategorySlug(slug){
    return this.http.get(`${this.url}/learning/get_all/`+slug, this.httpOptions);
  }
  getSingleLearning(req){
    return this.http.post(`${this.url}/learning/get_learn_by_id`, req);
  }

  update(req){
    return this.http.post(`${this.url}/learning/update`, req);
  }
  delete(req){
    return this.http.post(`${this.url}/learning/delete`, req);
  }

}
